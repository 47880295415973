import './actionanalysis.css'
import React, { useEffect, useState } from "react";
//import { Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
//import { toBeEmpty } from '@testing-library/jest-dom/dist/matchers';
//import Chart from '../charts/chart'
//import AnimationDemo from './AnimationMain'
//import StortowerImage from '../animation/animationImages/Stortower.svg'
//import BuildingImage from '../animation/animationImages/Building.svg'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import Actiontopbar from './Actiontopbar';
//import Card from '../pagecomponents/Card';
//import { Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Copyright from '../pagecomponents/copyright';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DataGrid } from "@mui/x-data-grid";
import {
    LineChart,
    //Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    //ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';
import { color } from '@mui/system';
import { SettingsBackupRestoreOutlined } from '@mui/icons-material';
//import { Block } from '@mui/icons-material';


const cookies = new Cookies();

// get token generated on login
const token = cookies.get("TOKEN");

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}


export default function Actionanalysis() {
    // set an initial state for the message we will receive after the API call
    //const [datadisplay, setMessage] = useState("");
    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [data, setData] = useState("");
    const [data2, setData2] = useState("");
    //const [output_data, setOutput] = useState("");
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const [tableset, setTable] = useState(false);
    const [open, setOpen] = useState(false);
    const [pvGraph, setPvgraph] = useState(true);
    const [gridGraph, setGridgraph] = useState(true);
    const [consumptionGraph, setConsumptiongraph] = useState(true);
    const [evtotalGraph, setEvtotalgraph] = useState(true);
    const [evphase1Graph, setEvphase1graph] = useState(false);
    const [evphase2Graph, setEvphase2graph] = useState(false);
    const [evphase3Graph, setEvphase3graph] = useState(false);
    const [stortowervoltageGraph, setStortowervoltagegraph] = useState(false);
    const [stortoweramperageGraph, setStortoweramperagegraph] = useState(false);
    const [triaconsavings, setTriaconsavings] = useState(false);
    ///////
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    /////


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    const handleTable = () => {
        setTable(!tableset);
    };

    console.log(state[0]["startDate"]);
    console.log(state[0]["endDate"]);

    function createData(name, Value_in_kW) {
        return { name, Value_in_kW };
    }


    const handleSwitches = (name) => {
        switch (name) {
            case 1:
                setPvgraph(prevCheck => !prevCheck);
                break;
            case 2:
                setGridgraph(prevCheck => !prevCheck)

                break;
            case 3:
                setConsumptiongraph(prevCheck => !prevCheck)

                break;
            case 4:
                setEvtotalgraph(prevCheck => !prevCheck)

                break;
            case 5:
                setEvphase1graph(prevCheck => !prevCheck)

                break;
            case 6:
                setEvphase2graph(prevCheck => !prevCheck)

                break;
            case 7:
                setEvphase3graph(prevCheck => !prevCheck)

                break;
            case 8:
                setStortowervoltagegraph(prevCheck => !prevCheck)

                break;
            case 9:
                setStortoweramperagegraph(prevCheck => !prevCheck)

                break;
            case 10:
                setTriaconsavings(prevCheck => !prevCheck)

        }
    };






    // useEffect automatically executes once the page is fully loaded
    useEffect(() => {
        // set configurations for the API call here
        stortower_data()


    }, [state]);

    const stortower_data = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/action_data_giv_time",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]

            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                for (var i = 0; i < output_data.length; i++) {
                    output_data[i]['Total AC power (kW)'] = ((-1 * output_data[i]['Grid Current(A)'] * output_data[i]['Battery rating voltage(A)']) / 1000).toFixed(2)
                    output_data[i]['Stortower current (A)'] = (-1 * output_data[i]['Grid power R(W)'] / output_data[i]['Grid rating voltage(V)']).toFixed(2)
                    output_data[i]['Stortower Power (kW)'] = (-1 * output_data[i]['Grid power R(W)'] / 1000).toFixed(2)
                }
                setDisplay(output_data[0]["Timestamp"])
                setData(output_data)
            })
            .catch((error) => {
                error = new Error();
            });
    }



    const day = new Date(datedisplay);
    const displayday = day.toLocaleDateString()
    //const convertedtime = new Date(data["date/time"]).toLocaleTimeString('it-IT')
    const maxvals = 0;
    for (let i = 0; i < data.length; i++) {
        const t = new Date(data[i]["Timestamp"]).toLocaleTimeString('it-IT')
        const day = new Date(data[i]["Timestamp"]).toLocaleDateString('en-US')
        data[i]["time"] = day + " " + t;
    }

    var Difference_In_Time = state[0]["endDate"].getTime() - state[0]["startDate"].getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Date', headerName: 'Date', width: 300 },
        { field: 'Time_interval', headerName: 'Time Interval of operation', width: 330 },
        { field: 'Type', headerName: 'Operation Type', width: 330 },
        { field: 'amount', headerName: 'Amount(kWh)', width: 330 },

    ];

    //const reverseddata = data.reverse();
    console.log(data)

    const viewportHeight = window.innerHeight;

    return (
        <div>
            <div className="DataPage">
                <Actiontopbar />
                <div className="TopWrapper">
                    <div className='topaccordn'>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    General settings
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>Click here to select Graphs and Dates to Display</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="DatePicker">
                                        <div className='datebutton'>
                                            <FormControlLabel control={<Switch checked={pvGraph} onChange={() => handleSwitches(1)} />} label="Grid power (kW)" />
                                            <FormControlLabel control={<Switch checked={gridGraph} onChange={() => handleSwitches(2)} />} label="Battery Voltage(V)" />
                                            <FormControlLabel control={<Switch checked={consumptionGraph} onChange={() => handleSwitches(3)} />} label="Battery Current(A)" />
                                            <FormControlLabel control={<Switch checked={evphase1Graph} onChange={() => handleSwitches(5)} />} label="Load (kW)" />
                                            <FormControlLabel control={<Switch checked={evphase2Graph} onChange={() => handleSwitches(6)} />} label="Stortower Power (kW)" />
                                            <FormControlLabel control={<Switch checked={evphase3Graph} onChange={() => handleSwitches(7)} />} label="PV (kW)" />
                                            <Button variant="contained" onClick={handleOpen}>Click to Select Date</Button>
                                        </div>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='chart'>
                        <ResponsiveContainer width="100%" aspect={3 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="Timestamp" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis yAxisId="left" domain={[-100, 100]} label={{ value: 'Battery Current(A), Battery Voltage(V)', angle: -90, position: 'insideLeft', dy: 65, dx: 10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis yAxisId="right" domain={[-30, 30]} orientation="right" label={{ value: 'Phase 1,2,3,Total & Total AC power (kW)', angle: 90, position: 'insideRight', dy: 90, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                {gridGraph ? <Area yAxisId="left" name='Battery Voltage(V)' type="monotone" dataKey="Battery rating voltage(A)" fillOpacity={0.1} stroke="#09f9f1" fill="#09f9f1" /> : null}
                                {consumptionGraph ? <Area yAxisId="left" name='Battery Current(A)' type="monotone" dataKey="Stortower current (A)" stroke="#197F1D" fillOpacity={0.1} fill="#197F1D" /> : null}
                                {pvGraph ? <Area yAxisId="left" name='Grid power (kW)' type="monotone" dataKey="Grid_power_kW" stroke="#960016" fillOpacity={0.1} fill="#960016" /> : null}
                                {evphase1Graph ? <Area yAxisId="right" name='Load Power (kW)' type="monotone" dataKey="Load_power_kW" stroke="#fb000c" fillOpacity={0.1} fill="#fb000c" /> : null}
                                {evphase2Graph ? <Area yAxisId="right" name='Stortower Power(kW)' type="monotone" dataKey="Stortower Power (kW)" stroke="#4ce717" fillOpacity={0.1} fill="#4ce717" /> : null}
                                {evphase3Graph ? <Area yAxisId="right" name='PV Power (kW)' type="monotone" dataKey="PV_power_kW" stroke="#5763FF" fillOpacity={0.1} fill="#5763FF" /> : null}
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <Modal
                        hideBackdrop
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{ ...style, width: 950 }}>
                            <div className='Daterange'>
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className='InsideButton'>
                                <Button onClick={handleClose}>OK</Button>
                            </div>
                        </Box>
                    </Modal>
                </div>

            </div >
            {/*<div className='datahold'>
                <h3 className='headholder'><span><b>Stortower & SolarEdge Data</b></span></h3>
                <div className='inside'>
                    <Box sx={{ height: viewportHeight * 0.7, width: '100%' }}>

                    </Box>
                </div>
            </div>
            <div className='datahold'>
                <h3 className='headholder'><span><b>Charge and discharge Data</b></span></h3>
                <div className='inside'>
                    <Box sx={{ height: viewportHeight * 0.7, width: '100%' }}>

                    </Box>
                </div>
    </div>*/}


        </div>
    );
}




