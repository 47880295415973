import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Action_topappbar from "./Actiontopbar";
import Xarrow, { Xwrapper } from "react-xarrows";
import Grid from "../animation/animationImages/new_icons/Grid.svg";
import Stortower from "../animation/animationImages/new_icons/Battery_System.svg";
import Solar from "../animation/animationImages/new_icons/Solar.svg"
import { addDays } from 'date-fns';
import Building from "../animation/animationImages/new_icons/building_pfc.svg";
import { DateRangePicker } from 'react-date-range';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSVLink } from "react-csv";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import flowbattery from "../animation/animationImages/flowbattery.png"
import BoltIcon from '@mui/icons-material/Bolt';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BusinessIcon from '@mui/icons-material/Business';
import CircleIcon from '@mui/icons-material/Circle';
import Carousel from 'react-material-ui-carousel'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    AreaChart,
    Area,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';
import './actionmain.css'


const cookies = new Cookies();


const token = cookies.get("TOKEN");

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Actionmain() {
    const [data2, setData] = useState([]);
    const [showClaender, setShowClaender] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [enddisplay, setEnddisplay] = useState("Select Days from the Calender for display");
    const [girdstatus, setGridstatus] = useState('');
    const [loadstatus, setLoadstatus] = useState('');
    const [pvstatus, setPvstatus] = useState('');
    const [connectingfrom, setConnectingfrom] = useState('');
    const [connectingto, setConnectingto] = useState('');
    const [gridpower, setGridpower] = useState('');
    const [solarpower, setSolarpower] = useState('');
    const [loadpower, setLoadpower] = useState('');
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [batteryvoltage, setBatteryvoltage] = useState('');
    const [batterycapacity, setBatterycapacity] = useState('');
    const [batteryupdate, setBatteryupdate] = useState('');
    const [batterycurrent, setBatterycurrent] = useState('');
    const [batteryfrequency, setBatteryfrequency] = useState('');
    const [batterytemp, setBatterytemp] = useState('');
    const [batterymode, setBatterymode] = useState('');
    const [batteryinputv, setBatteryinputv] = useState('');

    const [efrtime, setEfrtime] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [batterytoout, setBatterytoout] = useState({ start: 'stortower', end: 'building', startAnchor: 'left', endAnchor: 'right', animation: 0, showHead: false, color: '#E0E0E0' });
    const [batterytoout2, setBatterytoout2] = useState({ start: 'gridpower', end: 'building', startAnchor: 'right', endAnchor: 'left', animation: 0, showHead: false, color: '#E0E0E0' });
    const [batterytoout3, setBatterytoout3] = useState({ start: 'solar', end: 'building', startAnchor: 'bottom', endAnchor: 'top', animation: 0, showHead: false, color: '#E0E0E0' });
    const [batterypower, setBatterypower] = useState('');
    const [batterychargeday, setBatterychargeday] = useState('');
    const [batterydischargeday, setBatterydischargeday] = useState('');
    const [solardt, setSolardt] = useState('');
    const [griddata, setGriddata] = useState('');
    const [strotowerstatus, setStortowerstatus] = useState('');

    //inputvoltage
    const [SettingsInputS, setSettingsInputS] = useState('');
    const [SettingsInputR, setSettingsInputR] = useState('');
    const [SettingsInputT, setSettingsInputT] = useState('');
    const [SettingsInputcurrnetT, setSettingsInputcurrentT] = useState('');
    const [SettingsInputcurrnetR, setSettingsInputcurrentR] = useState('');
    const [SettingsInputcurrnetS, setSettingsInputcurrentS] = useState('');
    const [SettingsInputactivepR, setSettingsInputactivepR] = useState('');
    const [SettingsInputactivepS, setSettingsInputactivepS] = useState('');
    const [SettingsInputactivepT, setSettingsInputactivepT] = useState('');
    const [SettingsInputactiveptotal, setSettingsInputactiveptotal] = useState('');


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleCalender = () => {
        setShowClaender(!showClaender);
    }

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);

    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        actionalldata()
        actiontimegivendata()
        actionchargedis()
    }, [isSending]);

    const actionchargedis = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/latest_action_charge_dis",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                const chdisdata = response.data
                console.log("charge", chdisdata[0]["charged"])
                setBatterychargeday(chdisdata[0]["charged"])
                setBatterydischargeday(chdisdata[0]["discharged"])



            })
            .catch((error) => {
                error = new Error();
            });
    }

    const actiontimegivendata = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + "action_data_giv_time",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                console.log(output_data)
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))
                for (let i = 0; i < output_data.length; i++) {
                    output_data[i]['Total AC power (kW)'] = ((output_data[i]['Grid Current(A)'] * output_data[i]['Battery rating voltage(A)']) / 1000).toFixed(2)
                    output_data[i]['Stortower power (kW)'] = (-1 * ((output_data[i]['Grid power R(W)'] / 1000)))
                }
                console.log("apicalled")
                console.log(output_data)
                setData(output_data)

                //const nextpred_date = new Date(data2[1]['Timestamp']).toLocaleDateString('it-IT');

                //console.log(nextpred_date)
                //console.log(data)

                console.log('data', typeof (obj));

            })
            .catch((error) => {
                error = new Error();
            });
    }

    const actionalldata = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/latest_action_data",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                const stat = response.data[0].status;
                console.log(response.data);
                const out = response.data;
                console.log('new data', out)
                setGridstatus(out[0]['Gird_status'])
                setLoadstatus(out[0]['Load_status'])
                setPvstatus(out[0]['PV_status'])
                setConnectingfrom(out[0]['connecting_from'])
                setConnectingto(out[0]['connecting_to'])
                setGridpower(out[0]['Grid_power_kW'])
                setSolarpower(out[0]['PV_power_kW'])
                setLoadpower(out[0]['Load_power_kW'])
                setEfrtime(out[0]['Timestamp'])
                setSettingsInputS(out[0]['AC Input Voltage S(V)'])
                setSettingsInputR(out[0]['AC input voltage R(V)'])
                setSettingsInputT(out[0]['AC Input Voltage T(V)'])
                setSettingsInputcurrentT(out[0]['AC Input Current T(A)'])
                setSettingsInputcurrentS(out[0]['AC Input Current S(A)'])
                setSettingsInputcurrentR(out[0]['AC input current R(A)'])
                setSettingsInputactivepR(out[0]['AC input active power R(kW)'])
                setSettingsInputactivepS(out[0]['AC input active power S(kW)'])
                setSettingsInputactivepT(out[0]['AC input active powerT(kW)'])
                setSettingsInputactiveptotal(out[0]['AC input total active power(kW)'])
                setBatteryvoltage(out[0]['Battery rating voltage(A)'])
                setBatterycapacity(out[0]['Battery Capacity(%)'])
                setBatterycurrent((-1 * out[0]['Grid power R(W)'] / out[0]['Grid rating voltage(V)']).toFixed(2))
                setBatteryfrequency(out[0]['AC output frequency(Hz)'])
                setBatterytemp(out[0]['Temperature(°C)'])
                setBatterymode(out[0]['Battery Status'])
                setBatteryinputv(out[0]['Total Energy(kWh)'])
                setBatterypower(((-1 * out[0]['Grid power R(W)']) / 1000).toFixed(2))
                setBatteryupdate(new Date(out[0]['Timestamp']).toLocaleDateString('en-GB') + " " + new Date(out[0]['Timestamp']).toLocaleTimeString('en-GB'))
                if (out[0]['Battery Current(A)'] > 9) {
                    setBatterytoout({ start: 'building', end: 'stortower', startAnchor: 'right', endAnchor: 'left', animation: 1, showHead: true, color: '#0CA904' })
                    setStortowerstatus('Charging')
                }
                else if (out[0]['Battery Current(A)'] < 0) {
                    setBatterytoout({ start: 'stortower', end: 'building', startAnchor: 'left', endAnchor: 'right', animation: 1, showHead: true, color: '#0CA904' })
                    setStortowerstatus('Discharge')
                }
                else {
                    setBatterytoout({ start: 'stortower', end: 'building', startAnchor: 'left', endAnchor: 'right', animation: 0, showHead: false, color: '#E0E0E0' })
                    setStortowerstatus('Idle')
                }
                if (out[0]['PV_status'] == 'Active') {
                    setBatterytoout3({ start: 'solar', end: 'building', startAnchor: 'bottom', endAnchor: 'top', animation: 1, showHead: true, color: '#0CA904' })
                    setSolardt("Site PV power: " + String(Math.abs((out[0]['PV_power_kW'])).toFixed(2)))
                }
                else {
                    setBatterytoout3({ start: 'solar', end: 'building', startAnchor: 'bottom', endAnchor: 'top', animation: 0, showHead: true, color: '#E0E0E0' })
                    setSolardt("Site PV power: 0")
                }
                if (out[0]['connecting_from'] == 'GRID' && out[0]['connecting_to'] == 'Load') {
                    setBatterytoout2({ start: 'gridpower', end: 'building', startAnchor: 'right', endAnchor: 'left', animation: 1, showHead: true, color: '#0CA904' })
                    setGriddata("Site Power : " + String(Math.abs((out[0]['Load_power_kW'])).toFixed(2)))
                }
                else if (out[0]['connecting_from'] == 'LOAD' && out[0]['connecting_to'] == 'Grid') {
                    setBatterytoout2({ start: 'building', end: 'gridpower', startAnchor: 'left', endAnchor: 'right', animation: 1, showHead: true, color: '#0CA904' })
                    setGriddata("Site Power : " + String(Math.abs((out[0]['Load_power_kW'])).toFixed(2)))
                }
                else {
                    setBatterytoout2({ start: 'gridpower', end: 'building', startAnchor: 'right', endAnchor: 'left', animation: 0, showHead: true, color: '#E0E0E0' })
                    setGriddata("Site Power : " + String(Math.abs((out[0]['Load_power_kW'])).toFixed(2)))
                }
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const csvData = data2




    return (
        <div>
            <Action_topappbar />
            <Xwrapper>

                <div className="animeaction">
                    <div className="actiontopani">

                        <div className="animationcontainer22">
                            <div className="imageanima" id={'gridpower'}>
                                <img className="animationbuilding" src={Grid} width="50px" height="70px" />
                                <span><b>Grid</b></span>
                                <span><b>{gridpower}kW</b></span>
                            </div>
                            <div className="building_solar">
                                <div className="solarimg" id={'solar'}>
                                    <div className="solarholder">
                                        <img className="animationbuilding" src={Solar} width="50px" height="70px" />
                                        <span><b>{solardt}kW</b></span>
                                    </div>
                                </div>
                                <div className="imageanima" id={'building'}>
                                    <img className="animationbuilding" src={Building} width="50px" height="70px" />
                                    <span><b>Load</b></span>
                                    <span><b>{griddata}kW</b></span>
                                </div>
                            </div>
                            <div className="imageanima" id={'stortower'}>
                                <img className="animationbuilding" src={Stortower} width="50px" height="70px" />
                                <span><b>StorTower</b></span>
                                <span><b>Status :{batterymode}</b></span>
                                <span><b>{batterypower}kW</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                <Xarrow start={batterytoout2.start} end={batterytoout2.end} path={"grid"} startAnchor={batterytoout2.startAnchor} endAnchor={batterytoout2.endAnchor} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout2.animation }} showHead={batterytoout2.showHead} color={batterytoout2.color} />
                <Xarrow start={batterytoout.start} end={batterytoout.end} path={"grid"} startAnchor={batterytoout.startAnchor} endAnchor={batterytoout.endAnchor} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout.animation }} showHead={batterytoout.showHead} color={batterytoout.color} />
                <Xarrow start={batterytoout3.start} end={batterytoout3.end} path={"grid"} startAnchor={batterytoout3.startAnchor} endAnchor={batterytoout3.endAnchor} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout3.animation }} showHead={batterytoout3.showHead} color={batterytoout3.color} />
            </ Xwrapper>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="Tabs data" centered>
                        <Tab label="Stortower & SolarEdge Data" {...a11yProps(0)} />
                        <Tab label="Performance Data" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Carousel
                        navButtonsAlwaysVisible={true}
                        animation={"slide"}
                        autoPlay={false}
                        NextIcon={<ChevronRightIcon />}
                        PrevIcon={<ChevronLeftIcon />}
                    // OR
                    >

                        <div className="timeholder">
                            <h5 className="heading"><span><b>Last Update Time: {batteryupdate}</b></span></h5>
                            <div className="beccards">
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Solaredge Data
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="Solar" />
                                                <Typography>
                                                    {":  "} <b>{solarpower}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-AC-ACTIVE" primary="Grid" />
                                                <Typography>
                                                    {":  "}<b>{gridpower}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Load" />
                                                <Typography>
                                                    {":  "} <b>{loadpower}kW</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>
                                    </CardActions>
                                </Card>
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Solaredge Status
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="Solar" />
                                                <Typography>
                                                    {":  "} <b>{pvstatus}</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-AC-ACTIVE" primary="Grid" />
                                                <Typography>
                                                    {":  "}<b>{girdstatus}</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Load" />
                                                <Typography>
                                                    {":  "} <b>{loadstatus}</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>
                                    </CardActions>
                                </Card>
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            StorTower Data
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="DC Voltage:" />
                                                <Typography>
                                                    <b>{batteryvoltage}V</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-AC-ACTIVE" primary="DC Current:" />
                                                <Typography>
                                                    <b>{batterycurrent}A</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Battery SOC:" />
                                                <Typography>
                                                    <b>{batterycapacity}%</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Battery Status:" />
                                                <Typography>
                                                    <b>{batterymode}</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>

                            </div>
                        </div>

                        <div className="timeholder">
                            <h5 className="heading"><span><b>Last Update Time: {batteryupdate}</b></span></h5>
                            <div className="beccards">

                                {/*<Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Stortower Data :
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Option:" />
                                                <Typography>
                                                    <b>{ }kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="Option:" />
                                                <Typography>
                                                    <b>{ }kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-AC-ACTIVE" primary="Option:" />
                                                <Typography>
                                                    <b>{ }kW</b>
                                                </Typography>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Option:" />
                                                <Typography>
                                                    <b>{ }kW</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
    </Card>*/}
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Stortower Data: General
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <DeviceThermostatIcon />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Stortower Temp:" />
                                                <Typography>
                                                    <b>{batterytemp}°C</b>
                                                </Typography>
                                            </ListItem>

                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>



                            </div>
                        </div>

                    </Carousel>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className="timeholder">
                        <h5 className="heading"><span><b>Charge and Discharge Data</b></span></h5>
                        <div className="beccards">

                            <Card sx={{ minWidth: 600 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Total Amount to Discharged today { }
                                    </Typography>
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    >

                                        <ListItem>
                                            <ListItemIcon>
                                                <BoltIcon />
                                            </ListItemIcon>
                                            <ListItemText id="KMCC-AC-ACTIVE" primary="Amount Discharged:" />
                                            <Typography>
                                                {batterydischargeday}kW
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 600 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Total Amount Charged Today { }
                                    </Typography>
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    >

                                        <ListItem>
                                            <ListItemIcon>
                                                <BoltIcon />
                                            </ListItemIcon>
                                            <ListItemText id="KMCC-AC-ACTIVE" primary="Amount Charged:" />
                                            <Typography>
                                                {batterychargeday}kWh
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>
                        </div>
                    </div>
                </CustomTabPanel>

            </Box>

            <div className="accordianholder">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            General settings
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{"Selected Dates: " + datedisplay + " to " + enddisplay}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="accinside1">
                            <div className="insidedatapick2">
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className="selectordata">

                                <Typography>
                                    Make Changes and click Update Selection
                                </Typography>
                                <Button>
                                    <CSVLink filename={"Action_porty_data.csv"} data={csvData}>Download data for selection</CSVLink>
                                </Button>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="efrdatachart">
                <h5 className="topheading">DATA</h5>
                <ResponsiveContainer width="100%" aspect={4 / 2} >
                    <LineChart width={500} height={300} data={data2}>
                        <XAxis dataKey="Timestamp" tick={{ fontSize: 15 }} label={{ value: 'Format in YYYY/MM/DD HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                        <Tooltip />
                        <YAxis domain={[-3, 20]} label={{ value: 'StorTower & Solar Total Power(kW)', angle: -90, position: 'insideLeft', dy: 30, dx: 10, fontFamily: 'Roboto, sans-serif' }} />

                        <CartesianGrid strokeDasharray="5 5" />
                        <Line name="Grid Power (kW)" type="monotone" dataKey="Grid_power_kW" stroke="#8884d8" dot={false} />
                        <Line name="Load Power (kW)" type="monotone" dataKey="Load_power_kW" stroke="#222AD5" dot={false} />
                        <Line name="PV Power (kW)" type="monotone" dataKey="PV_power_kW" stroke="#22D525" dot={false} />
                        {/*<Line name="(Building) Total Power (kW)" type="monotone" dataKey="Total_power" stroke="#DA1F1F" dot={false} />*/}
                        <Line name="(StorTower) Total AC power (kW)" type="monotone" dataKey="Stortower power (kW)" stroke="#E508F3" dot={false} />
                        {/*<Line name="Battery Voltage(V)" type="monotone" dataKey="Battery Voltage(V)" stroke="#202D21" dot={false} />*/}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}