import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import "./profile.css"
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';

const cookies = new Cookies();


const token = cookies.get("TOKEN");

const label = { inputProps: { 'aria-label': 'Switch demo' } };



export default function Profile() {

    return (
        <div>
            <Topbar />
            <div className="maincontrol21">
                <div className="topbackground">
                    <div className="leftstuff">
                        <Avatar >AD</Avatar>
                        <div className="avatarname">
                            <span><b>User Name</b></span>
                            <span>Title</span>
                        </div>
                    </div>
                </div>
                <div className="profileinfocard">
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Email Settings
                            </Typography>
                            <List
                                sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}
                            >
                                <ListItem>
                                    <ListItemIcon>
                                        <Switch {...label} defaultChecked />
                                    </ListItemIcon>
                                    <Typography>
                                        Send Email if Main Sequnece has stopped.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Switch {...label} defaultChecked />
                                    </ListItemIcon>
                                    <Typography>
                                        Send Email if Error in Solar Edge Data Collection.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Switch {...label} />
                                    </ListItemIcon>
                                    <Typography>
                                        Send Email if Error in StorTower.
                                    </Typography>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Account Settings
                            </Typography>
                            <List
                                sx={{ width: '100%', maxWidth: 700, bgcolor: 'background.paper' }}
                            >
                                <ListItem>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <Typography sx={{ marginRight: 2 }}>
                                        Change Username
                                    </Typography>
                                    <TextField
                                        disabled
                                        id="outlined-disabled"
                                        label="Username"
                                        defaultValue="Username"
                                    />

                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <Typography sx={{ marginRight: 2 }}>
                                        Change Password
                                    </Typography>
                                    <TextField
                                        disabled
                                        id="outlined-disabled"
                                        label="Password"
                                        defaultValue="Password"
                                    />

                                </ListItem>

                            </List>
                        </CardContent>
                    </Card>

                </div>
            </div>

        </div>
    )
}