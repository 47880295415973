import React, { useEffect, useState, useCallback, PureComponent } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import "./adminpagestryle.css";
import Mainappbar from "../pagecomponents/Mainappbar";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import Weatherwidget from "../pagecomponents/weatherwidget";


const cookies = new Cookies();


const token = cookies.get("TOKEN");

const steps = [
    {
        label: 'Project Data',
        description: `data place holder`,
        Text: `temp data1`,
    },
    {
        label: 'Project Data',
        description:
            'data place holder',
        Text: `temp data2`,
    },
    {
        label: 'Project Data',
        description: `data place holder`,
        Text: `temp data3`,
    },
];

const data = [
    { name: 'UPTime', value: 98 },
    { name: 'Downtime', value: 1 },
    { name: 'Errors', value: 1 },
];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Time:${value}%`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};


export default function Adminmain() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    ///////////////
    const [activeStep2, setActiveStep2] = React.useState(0);
    const maxSteps2 = steps.length;
    const handleNext2 = () => {
        setActiveStep2((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack2 = () => {
        setActiveStep2((prevActiveStep) => prevActiveStep - 1);
    };
    ///////////

    const [activeStep3, setActiveStep3] = React.useState(0);
    const maxSteps3 = steps.length;
    const handleNext3 = () => {
        setActiveStep3((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack3 = () => {
        setActiveStep3((prevActiveStep) => prevActiveStep - 1);
    };

    /////////////////
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );



    const dataget = () => {

    }

    return (
        <div>
            <Mainappbar />
            <div>
                <div className="topcard">
                    <div>
                        <h4>open projects</h4>
                    </div>
                    <div className="cardcarousal">
                        <div className="card">
                            <span><b>card top</b></span>
                        </div>
                        <div className="cardwithimg">
                            <span><b>card top</b></span>
                            <Typography>
                                inforamtion on the project
                            </Typography>
                            <img>

                            </img>
                        </div>
                    </div>
                </div>
                <div className="underdata">
                    <div>
                        <span>

                        </span>
                    </div>
                </div>
            </div>
            <div className="topchartarea">
                <div className="chartpie">
                    <h5 className="projecthead">Project UP/Down time</h5>
                    Weatherwidget
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={data}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="chartpie">
                    <h5 className="projecthead">Project UP/Down time</h5>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={data}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="chartpie">
                    <h5 className="projecthead">Project UP/Down time</h5>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={data}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="topcontrolarea">
                <div classname="datanext">
                    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            <Typography>{steps[activeStep].label}</Typography>
                        </Paper>
                        <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
                            <Typography><b>Last Update Time:</b> {steps[activeStep].description}</Typography>
                            <Typography><b>Last Update Message:</b>{steps[activeStep].Text}</Typography>
                            <Typography><b>Errors:</b> </Typography>
                        </Box>
                        <MobileStepper
                            variant="text"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    Next
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </div>
                <div classname="datanext">
                    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            <Typography>{steps[activeStep2].label}</Typography>
                        </Paper>
                        <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
                            <Typography><b>Last Update Time:</b> {steps[activeStep].description}</Typography>
                            <Typography><b>Last Update Message:</b>{steps[activeStep].Text}</Typography>
                            <Typography><b>Errors:</b> </Typography>
                        </Box>
                        <MobileStepper
                            variant="text"
                            steps={maxSteps2}
                            position="static"
                            activeStep={activeStep2}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext2}
                                    disabled={activeStep2 === maxSteps2 - 1}
                                >
                                    Next
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack2} disabled={activeStep2 === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </div>
                <div classname="datanext">
                    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            <Typography>{steps[activeStep3].label}</Typography>
                        </Paper>
                        <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
                            <Typography><b>Last Update Time:</b> {steps[activeStep].description}</Typography>
                            <Typography><b>Last Update Message:</b>{steps[activeStep].Text}</Typography>
                            <Typography><b>Errors:</b> </Typography>
                        </Box>
                        <MobileStepper
                            variant="text"
                            steps={maxSteps3}
                            position="static"
                            activeStep={activeStep3}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext3}
                                    disabled={activeStep3 === maxSteps3 - 1}
                                >
                                    Next
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack3} disabled={activeStep3 === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}