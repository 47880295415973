import React from "react";
import { Col, Row } from "react-bootstrap";
//import Login from "./Login";
import Bec_login from "./components/bec_account/bec_login";

export default function BecAccount() {
    return (
        <Row>
            <Bec_login />
        </Row >
    );
}