import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import BoltIcon from '@mui/icons-material/Bolt';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
    LineChart,
    //Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    //ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';

import "./pkcGraphs.css";

const cookies = new Cookies();


const token = cookies.get("TOKEN");


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Pkcgraphs() {
    const [expanded, setExpanded] = React.useState(false);
    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [enddisplay, setEnddisplay] = useState("Select Days from the Calender for display");
    const [data, setData] = useState("");
    const [totalCons, setTotalcons] = useState("0");
    const [totalProd, setTotalprod] = useState("0");
    const [totalEV, setTotalev] = useState("0");
    const [totalexp, setTotalexp] = useState("0");
    const [isSending, setIsSending] = useState(false)
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [setTotalprods, Totalprods] = useState('0');
    const [setTotalconn, Totalconn] = useState('0');
    const [setLastupdate, Lastupdate] = useState('0');
    //const [output_data, setOutput] = useState("");
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const savedate = () => {
        setIsSending(!isSending)
    }

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        // set configurations for the API call here
        Getprodcons()

        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/table",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]

            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                setDisplay(new Date(output_data[0]["date/time"]).toDateString('en-US'))
                setEnddisplay(new Date(output_data[output_data.length - 1]["date/time"]).toDateString('en-US'))
                const hours = Math.abs(new Date(output_data[0]["date/time"]).toDateString('en-US') - new Date(output_data[output_data.length - 1]["date/time"])) / 36e5;
                var total_cons = 0;
                var total_gen = 0;
                var total_grid = 0;
                var total_ev = 0;

                console.log('lastdate', output_data[output_data.length - 1]["Grid_power_kW"])
                console.log('grid', output_data[output_data.length - 1]["date/time"]);
                for (var i = 0; i < output_data.length; i++) {
                    total_grid += output_data[i]["Grid_power_kW"]
                    total_cons += output_data[i]["Load_power_kW"]
                    total_gen += output_data[i]["PV_power_kW"]
                    total_ev += output_data[i]["Total_EV_power"]
                    output_data[i]["Battery_Voltage_V"] = (output_data[i]["Battery_Voltage_V"] / 10)
                    output_data[i]["Total_EV_kW"] = (Math.abs((output_data[i]["EV_power_phase_1"]) / 1000)) + (Math.abs((output_data[i]["EV_power_phase_2"]) / 1000)) + (Math.abs((output_data[i]["EV_power_phase_3"]) / 1000))
                    output_data[i]["EV_phase1_kW"] = (Math.abs((output_data[i]["EV_power_phase_1"]) / 1000))
                    output_data[i]["EV_phase2_kW"] = (Math.abs((output_data[i]["EV_power_phase_2"]) / 1000))
                    output_data[i]["EV_phase3_kW"] = (Math.abs((output_data[i]["EV_power_phase_3"]) / 1000))
                    output_data[i]["Battery_Current_A"] = ((output_data[i]["Battery_Current_A"]) / 10)
                    output_data[i]["Grid_power_kW"] = ((output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"]) - (((output_data[i]['Total_EV_power']) / 1000) + (output_data[i]['Battery_Voltage_V'] * (output_data[i]['Battery_Current_A'] / 10)) / 1000)).toFixed(2)
                }
                setTotalcons((total_cons).toFixed(2))
                setTotalprod(total_gen.toFixed(2))
                setTotalev((Math.abs(total_ev) / 1000).toFixed(2))
                setTotalexp((total_gen - total_cons).toFixed(2))

                setData(output_data)
            })
            .catch((error) => {
                error = new Error();
            });



    }, [isSending]);

    const Getprodcons = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/totalProduction",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        // make the API call
        axios(configuration)
            .then((result) => {
                const total_prod = result.data
                Totalprods(total_prod[0]['production'])
                Totalconn(total_prod[0]['consumption'])
                Lastupdate(total_prod[0]['lastupdate'])
                console.log(total_prod)
            }).catch((error) => {
                error = new Error();
            });
    }

    for (let i = 0; i < data.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(data[i]["date/time"])
            const day = new Date(data[i]["date/time"])
            data[i]["time"] = day;
        }
        else {
            const t = new Date(data[i]["date/time"]).toLocaleTimeString('it-IT')
            const day = new Date(data[i]["date/time"]).toLocaleDateString('en-US')
            data[i]["time"] = t + ' ' + day;
        }

    }

    const lastupT = new Date(setLastupdate).toLocaleDateString('en-US') + " " + new Date(setLastupdate).toLocaleTimeString('it-IT')




    return (
        <div>
            <Topbar />
            <div className="accordianmaintop">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            General settings
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{"Selected Dates: " + datedisplay + " to " + enddisplay}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="accinside1">
                            <div className="insidedatapick2">
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className="selectordata">

                                <Typography>
                                    Make Changes and click Update Selection
                                </Typography>
                                <div className="dateformatselect">
                                    <span>Set time format: </span>
                                    LocalTime

                                    <Radio
                                        checked={selectedValue === 'Localtime'}
                                        onChange={handleChange2}
                                        value="Localtime"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    (UTC/GMT)
                                    <Radio
                                        checked={selectedValue === 'UTC/GMT'}
                                        onChange={handleChange2}
                                        value="UTC/GMT"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />

                                </div>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="graphContainerrows">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
                            scrollButtons="auto">
                            <Tab label="PV" {...a11yProps(0)} />
                            <Tab label="GRID" {...a11yProps(1)} />
                            <Tab label="LOAD" {...a11yProps(2)} />
                            <Tab label="EV Total" {...a11yProps(3)} />
                            <Tab label="EV Phase 1" {...a11yProps(4)} />
                            <Tab label="EV Phase 2" {...a11yProps(5)} />
                            <Tab label="EV Phase 3" {...a11yProps(6)} />
                            <Tab label="StorTower Voltage" {...a11yProps(7)} />
                            <Tab label="StorTower Current" {...a11yProps(8)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'PV (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: 10, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='PV (kW)' type="monotone" dataKey="PV_power_kW" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13434" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F13434" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13434" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F13434" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'Grid (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: 10, fontFamily: 'Roboto, sans-serif' }} domain={[-100, 100]} />
                                <Tooltip />
                                <Area name='Grid (kW)' type="monotone" dataKey="Grid_power_kW" stroke="#F13434" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#B2B90C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#B2B90C" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#B2B90C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#B2B90C" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'Load (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: 10, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='Load (kW)' type="monotone" dataKey="Load_power_kW" stroke="#B2B90C" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#193FAC" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#193FAC" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#193FAC" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#193FAC" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'EV Charger Total (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: -4, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='EV Charger Total (kW)' type="monotone" dataKey="Total_EV_kW" stroke="#193FAC" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8E28ED" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8E28ED" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8E28ED" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8E28ED" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'EV Chager Phase 1 (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: -4, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='EV Chager Phase 1 (kW)' type="monotone" dataKey="EV_phase1_kW" stroke="#8E28ED" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#7E27CF" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#7E27CF" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#7E27CF" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#7E27CF" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'EV Chager Phase 2 (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: -4, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='EV Chager Phase 2 (kW)' type="monotone" dataKey="EV_phase2_kW" stroke="#7E27CF" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#551E88" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#551E88" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#551E88" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#551E88" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in  HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'EV Chager Phase 3 (kW)', angle: -90, position: 'insideLeft', dy: 30, dx: -4, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='EV Chager Phase 3 (kW)' type="monotone" dataKey="EV_phase3_kW" stroke="#551E88" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#EA26B3" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#EA26B3" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#EA26B3" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#EA26B3" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'StorTower Voltage (V)', angle: -90, position: 'insideLeft', dy: 30, dx: -4, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='StorTower Voltage (V)' type="monotone" dataKey="Battery_Voltage_V" stroke="#EA26B3" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                        <ResponsiveContainer width="100%" aspect={4 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#387F82" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#387F82" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#387F82" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#387F82" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YY', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis label={{ value: 'Stortower Current (A)', angle: -90, position: 'insideLeft', dy: 30, dx: -4, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                <Area name='Stortower Current (A)' type="monotone" dataKey="Battery_Current_A" stroke="#387F82" fillOpacity={1} fill="url(#colorPv)" />
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </TabPanel>
                </Box>
            </div>
            <div className="cardholdercon">
                <Card sx={{ minWidth: 400 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Data Summary
                        </Typography>
                        <List
                            sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <BoltIcon />
                                </ListItemIcon>
                                <ListItemText id="KMCC-battery-percent" primary="Total Consumption" />
                                <Typography>
                                    {(setTotalconn / 1000).toFixed(2)}kWh
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SolarPowerIcon />
                                </ListItemIcon>
                                <ListItemText id="KMCC-AC-ACTIVE" primary="Total Production" />
                                <Typography>
                                    {(setTotalprods / 1000).toFixed(2)}kWh
                                </Typography>
                            </ListItem>
                        </List>
                    </CardContent>
                </ Card>

                <Card sx={{ minWidth: 400 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Data Summary
                        </Typography>
                        <List
                            sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <CalendarTodayIcon />
                                </ListItemIcon>
                                <ListItemText id="KMCC-battery-percent" primary="Last Update Time" />
                                <Typography>
                                    {lastupT}
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <BoltIcon />
                                </ListItemIcon>
                                <ListItemText id="KMCC-AC-ACTIVE" primary="Excess(+) or Deficit(-)" />
                                <Typography>
                                    {((setTotalprods - setTotalconn) / 1000).toFixed(2)}kWh
                                </Typography>
                            </ListItem>
                        </List>
                    </CardContent>
                </ Card>
            </div>


        </div>
    )
}