import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topappbar from "../pagecomponents/Topappbar";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Tooltip from "@mui/material/Tooltip";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Modal from '@mui/material/Modal';
import './storhubcontrolmain.css'


const cookies = new Cookies();


const token = cookies.get("TOKEN");

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function StorhubControlmain() {
    const [startandendcharge, Setstartandendcharge] = useState("");
    const [chargepower, setChargepower] = useState("");
    const [dischargepower, setDischargepower] = useState("");
    const [nowchargepower, setNowchargepower] = useState("");
    const [nowdischargepower, setNowdischargepower] = useState("");
    const [startandenddischarge, Setstartandenddischarge] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [device, setdevice] = React.useState("KMCC");
    const [urlend, setUrlend] = React.useState("kmcc");
    const openselector = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);
    const [chargetimeset, setChargetime] = React.useState(false);
    const [dischargetimeset, setDischargetime] = React.useState(false);
    const [errorcheck, setErrorcheck] = React.useState(false);
    const [clearfaults, setClearfaults] = React.useState(false);
    const [startdevice, setStartdevice] = React.useState(false);
    const [enddischarge, setEnddischarge] = React.useState(false);
    const [begindischarge, setBegindischarge] = React.useState(false);
    const [chargestart, setChargestart] = React.useState(false);
    const [stopcharge, setStopcharge] = React.useState(false);
    const [replydata, setReplydata] = useState([]);
    const [acceptuserterm, setUserterm] = useState(false);

    ///////////////
    const manualcontrol = () => {
        setUserterm(!acceptuserterm)
    }



    //////////////

    const delreplies = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/clearuserdata",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                console.log(result)

            })
            .catch((error) => {
                error = new Error();
            });
    }


    const getreply = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/getreply",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                const output_dd = result.data
                console.log(output_dd)
                setReplydata(output_dd)

            })
            .catch((error) => {
                error = new Error();
            });
    }


    ////////////////

    const modalOpen = (settingno) => {
        setOpen(true)
        switch (settingno) {
            case 1:
                setChargetime(true);
                break;
            case 2:
                setDischargetime(true);
                break;
            case 3:
                setErrorcheck(true);
                break;
            case 4:
                setClearfaults(true);
                break;
            case 5:
                setStartdevice(true);
                break;
            case 6:
                setEnddischarge(true);
                break;
            case 7:
                setBegindischarge(true);
                break;
            case 8:
                setChargestart(true);
                break;
            case 9:
                setStopcharge(true);
        }

    };


    const modalClose = () => {
        setOpen(false);
        setChargetime(false);
        setDischargetime(false);
        setErrorcheck(false);
        setClearfaults(false);
        setStartdevice(false);
        setEnddischarge(false);
        setBegindischarge(false);
        setChargestart(false);
        setStopcharge(false);

    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handledevice = (name) => {
        switch (name) {
            case 1:
                setdevice("KMCC")
                setUrlend("kmcc")
                break;
            case 2:
                setdevice("Townhall")
                setUrlend("townhall")
                break;
        }
    };

    const setchargetimes = (chargeTime) => {
        if (startandendcharge === "") {
            alert("Textfield empty");
        }
        else {
            try {
                console.log(startandendcharge)
                var partsArray = startandendcharge.split(' ');
                var firsttime = partsArray[0].split(':')
                var secondtime = partsArray[1].split(':')
                if (firsttime[0] > 24 || firsttime[1] > 60 || secondtime[0] > 24 || secondtime[1] > 60) {
                    alert("incorrect time format")
                }
                else {
                    const timebetween = secondtime[0] - firsttime[0]
                    if ((parseInt(secondtime[0]) - parseInt(firsttime[0])) <= 0) {
                        alert("time difference must be more than an hour")
                    }
                    else {
                        const starttime = new Date(null, null, null, firsttime[0], firsttime[1]);
                        const endtime = new Date(null, null, null, secondtime[0], secondtime[1]);
                        sendchargecommand()
                        alert(starttime + " " + endtime)
                    }
                }
            }
            catch (err) {
                alert("Incorrect format");
            }
        }

        console.log(chargeTime)

    }

    const sendchargecommand = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/sendchargecommand",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "chargetimes": startandendcharge,
                "chargepower": chargepower
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }
    //discharge

    const setdischargetimes = (dischargeTime) => {
        if (startandenddischarge === "") {
            alert("Textfield empty");
        }
        else {
            try {
                console.log(startandenddischarge)
                var partsArray = startandenddischarge.split(' ');
                var firsttime = partsArray[0].split(':')
                var secondtime = partsArray[1].split(':')
                if (firsttime[0] > 24 || firsttime[1] > 60 || secondtime[0] > 24 || secondtime[1] > 60) {
                    alert("incorrect time format")
                }
                else {
                    const timebetween = secondtime[0] - firsttime[0]
                    if ((parseInt(secondtime[0]) - parseInt(firsttime[0])) <= 0) {
                        alert("time difference must be more than an hour")
                    }
                    else {
                        if ((parseInt(secondtime[0]) - parseInt(firsttime[0])) > 2) {
                            alert("time difference is more than 2 hours")
                        }
                        else {
                            if ((parseInt(secondtime[1]) - parseInt(firsttime[1]) > 2)) {
                                alert("time difference is more than 2 hours")
                            }
                            else {
                                const starttime = new Date(null, null, null, firsttime[0], firsttime[1]);
                                const endtime = new Date(null, null, null, secondtime[0], secondtime[1]);
                                senddischargecommand()
                                alert(starttime + " " + endtime)
                            }
                        }

                    }
                }
            }
            catch (err) {
                alert("Incorrect format");
            }
        }

        console.log(dischargeTime)

    }

    const senddischargecommand = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/senddischargecommand",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "chargetimes": startandenddischarge,
                "dischargepower": dischargepower
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const checkforerrors = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/checkforerrors",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const clearstorhub = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/clearstorhub",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const startstorhub = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/startstorhub",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const stopdischarge = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/stopdischarge",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const startdischarge = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/startdischarge",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "dischargepower": nowdischargepower
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const startcharge = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/startcharge",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "dischargepower": nowchargepower
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const endstartcharge = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/endstartcharge",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((result) => {
                alert(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    return (
        <div>
            <Topappbar />
            <div className="manualswitch">
                <span className="insidewordstop">Click to change to manual control</span>
                <Switch checked={acceptuserterm}
                    onChange={manualcontrol}
                    inputProps={{ 'aria-label': 'controlled' }} />

            </div>
            {acceptuserterm ?
                <div>
                    <div className="selectdevice">
                        <span className="textintop"><b>{"Currently Controlling: " + device}</b></span>
                        <Button sx={{ marginLeft: 2, marginBottom: 2 }}
                            id="fade-button"
                            aria-controls={openselector ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openselector ? 'true' : undefined}
                            onClick={handleMenuClick}>Select Site</Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={openselector}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={() => handledevice(1)}>KMCC</MenuItem>
                            <MenuItem onClick={() => handledevice(2)}>TownHall</MenuItem>
                        </Menu>
                    </div>
                    <div className="tabletop">
                        <Box component="form" noValidate sx={{
                            mt: 1, display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '80%',
                            flexWrap: 'nowrap',
                            alignContent: 'normal',
                            margin: 'auto',
                        }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Action</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Parameters</TableCell>
                                            <TableCell>Command</TableCell>
                                            <TableCell>Help</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <Tooltip title="this command sets the charging hours for the device">
                                                <TableCell>Set Charge Time (hh:mm hh:mm) Start and End</TableCell>
                                            </Tooltip>
                                            <TableCell >
                                                <Tooltip title="enter the hours to charge in form HH:MM HH:MM">
                                                    <TextField sx={{ m: 2 }} id="filled-basic" label="hh:mm hh:mm" variant="filled" value={startandendcharge}
                                                        onChange={(chargeTime) => Setstartandendcharge(chargeTime.target.value)} />
                                                </Tooltip>
                                                <Tooltip title="enter the charging power">
                                                    <TextField sx={{ m: 2 }} id="filled-basic" label="set charge power kW" variant="filled" value={chargepower}
                                                        onChange={(chargepower) => setChargepower(chargepower.target.value)}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell >
                                                <Tooltip title="click to send command">
                                                    <Button onClick={(chargeTime) => setchargetimes(chargeTime)}>Send command</Button>
                                                </Tooltip>
                                            </TableCell>
                                            <Tooltip title="Click on ? to learn more about this commmand">
                                                <TableCell>
                                                    <QuestionMarkIcon onClick={() => modalOpen(1)}>
                                                    </QuestionMarkIcon>
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Start Charge </TableCell>
                                            <TableCell ><TextField sx={{ m: 2 }} id="filled-basic" label="set charge power kW" variant="filled" value={nowchargepower}
                                                onChange={(nowchargepower) => setNowchargepower(nowchargepower.target.value)}
                                            /></TableCell>
                                            <TableCell ><Button onClick={() => startcharge()}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(8)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Stop Battery Charge</TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell ><Button onClick={() => endstartcharge()}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(9)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                    <div classname="midtable">
                        <Box component="form" noValidate sx={{
                            mt: 1, display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '80%',
                            flexWrap: 'nowrap',
                            alignContent: 'normal',
                            margin: 'auto',
                        }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Action</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Parameters</TableCell>
                                            <TableCell>Command</TableCell>
                                            <TableCell>Help</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Set Discharge Time (max 2 hours)</TableCell>
                                            <TableCell ><TextField sx={{ m: 2 }} id="filled-basic" label="hh:mm hh:mm" variant="filled" value={startandenddischarge}
                                                onChange={(dischargeTime) => Setstartandenddischarge(dischargeTime.target.value)} />
                                                <TextField sx={{ m: 2 }} id="filled-basic" label="set discharge power kW" variant="filled" value={dischargepower}
                                                    onChange={(dischargepower) => setDischargepower(dischargepower.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell ><Button onClick={(dischargeTime) => setdischargetimes(dischargeTime)}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(2)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Start Discharge </TableCell>
                                            <TableCell ><TextField sx={{ m: 2 }} id="filled-basic" label="set discharge power kW" variant="filled" value={nowdischargepower}
                                                onChange={(nowdischargepower) => setNowdischargepower(nowdischargepower.target.value)}
                                            /></TableCell>
                                            <TableCell ><Button onClick={() => startdischarge()}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(7)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Stop Discharge</TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell ><Button onClick={() => stopdischarge()}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(6)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>

                    <div className="tablebottom">
                        <Box component="form" noValidate sx={{
                            mt: 1, display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '80%',
                            flexWrap: 'nowrap',
                            alignContent: 'normal',
                            margin: 'auto',
                        }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Action</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Parameters</TableCell>
                                            <TableCell>Command</TableCell>
                                            <TableCell>Help</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/*<TableRow>
                                        <TableCell>Check for Errors</TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ><Button onClick={() => checkforerrors()}>Send command</Button></TableCell>
                                        <TableCell>
                                            <QuestionMarkIcon onClick={() => modalOpen(3)}>
                                            </QuestionMarkIcon>
                                        </TableCell>
                                    </TableRow>*/}
                                        <TableRow>
                                            <TableCell>Clear Faults in Storhub</TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell ><Button onClick={() => clearstorhub()}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(4)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Start Storhub</TableCell>
                                            <TableCell ></TableCell>
                                            <TableCell ><Button onClick={() => startstorhub()}>Send command</Button></TableCell>
                                            <TableCell>
                                                <QuestionMarkIcon onClick={() => modalOpen(5)}>
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </div>
                    <Modal
                        open={open}
                        onClose={modalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {chargetimeset ? "Charging with Time and Power" : null}
                                {dischargetimeset ? "Dischrging with Time and Power" : null}
                                {errorcheck ? "Error Checker" : null}
                                {clearfaults ? "Clear Faults in Storhub" : null}
                                {startdevice ? "Start Storhub" : null}
                                {enddischarge ? "End Discharging" : null}
                                {begindischarge ? "Start Discharging" : null}
                                {chargestart ? "Start Charging" : null}
                                {stopcharge ? "Stop Charging" : null}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {chargetimeset ? "Set charge times in HH:MM HH:MM format and the charge power in kW and click send command." : null}
                                {dischargetimeset ? "Set discharge times in HH:MM HH:MM format and the charge power in kW and click send command." : null}
                                {errorcheck ? "This command checks for errors output should be displayed on the box below the commands table when refresh is clicked." : null}
                                {clearfaults ? "This command clears faults in the storhub click refresh to check if faults were cleared." : null}
                                {startdevice ? "This command starts storhubs in the storhub click refresh to check if the command was correctly run." : null}
                                {enddischarge ? "This command sends end discharge command to the storhub instantly." : null}
                                {begindischarge ? "This command sends begin discharge command to the storhub instantly." : null}
                                {chargestart ? "Set the charge power required in the set charge power box in Kw and click send command. This is also an instant command." : null}
                                {stopcharge ? "This command stops battery charging immediately(If battery is charging)." : null}
                            </Typography>
                        </Box>
                    </Modal>
                    <div className="outputarea">
                        <span className="textname">Click refresh to get output from control commands sent</span>
                        <Button onClick={() => getreply()}>Refresh</Button>
                        <span className="textname">Click clear to clear the outputs from commands</span>
                        <Button onClick={() => delreplies()}>Clear</Button>
                    </div>
                    <div className="outputadata">
                        <ul>
                            {replydata.map((replydata, index) => {
                                return <li key={index}>{JSON.stringify(replydata)}</li>
                            })}
                        </ul>

                    </div>
                </div> : null}
        </div>
    )
}