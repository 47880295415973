import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topappbar from "../pagecomponents/Topappbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const cookies = new Cookies();


const token = cookies.get("TOKEN");


export default function Storhuberrors() {

    const [errordatas, setErrordata] = useState([]);


    const rows = errordatas

    useEffect(() => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/errors",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {

                const errordata = response.data
                setErrordata(errordata)
                console.log(errordata)
            })
            .catch((error) => {
                error = new Error();
            });

    }, [])




    return (
        <div>
            <Topappbar />
            <Box component="form" noValidate sx={{
                mt: 1, display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                flexWrap: 'nowrap',
                alignContent: 'normal',
                margin: 'auto',
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell align="right">Error</TableCell>
                                <TableCell align="right">Device</TableCell>
                                <TableCell align="right">Error</TableCell>
                                <TableCell align="right">Severity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.Timestamp}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.Timestamp}
                                    </TableCell>
                                    <TableCell align="right">{"Data not Updated"}</TableCell>
                                    <TableCell align="right">{row.device}</TableCell>
                                    <TableCell align="right">{row.Error_code}</TableCell>
                                    <TableCell align="right">{row.Severity}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}