import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topappbar from "../pagecomponents/Topappbar";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Radio from '@mui/material/Radio';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './storhubanalytics.css'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';

const cookies = new Cookies();


const token = cookies.get("TOKEN");


const theme = createTheme({
    palette: {
        primary: {
            main: "#010101",
            contrastText: "#fff" //button text white instead of black
        },
        background: {
            default: "#394764"
        }
    }
});

export default function StorhubAnalytics() {

    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);
    const [plcdata, setPlcData] = useState([]);
    const [pcsdata, setPcsdata] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [device, setdevice] = React.useState("KMCC");
    const [urlend, setUrlend] = React.useState("kmcc");
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [loading, setLoading] = useState(false);
    const [isSending, setIsSending] = useState(false)

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const [flag, setFlag] = useState(true);
    const [flag2, setFlag2] = useState(false);


    const savedate = () => {
        setIsSending(!isSending)
    }

    const openselector = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handledevice = (name) => {
        switch (name) {
            case 1:
                setdevice("KMCC")
                setUrlend("kmcc")
                setFlag2(false);
                setFlag(true);
                break;
            case 2:
                setdevice("Townhall")
                setUrlend("townhall")
                setFlag2(true);
                setFlag(false);
                break;
        }
    };
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        setLoading(true)
        plc()
        pcsdatainsert()
        setLoading(false)
    }, [isSending, urlend]);

    const plc = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/plcregisters",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const plc_out = response.data
                setPlcData(plc_out)
                console.log(plc_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const pcsdatainsert = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/pcsregisterdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const pcs_out = response.data
                setPcsdata(pcs_out)
                console.log(pcs_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    for (let i = 0; i < plcdata.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(plcdata[i]["date"])
            const day = new Date(plcdata[i]["date"])
            plcdata[i]["time"] = day;
        }
        else {
            const t = new Date(plcdata[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(plcdata[i]["date"]).toLocaleDateString('en-US')
            plcdata[i]["time"] = t + ' ' + day;
        }

    }

    for (let i = 0; i < pcsdata.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(pcsdata[i]["date"])
            const day = new Date(pcsdata[i]["date"])
            pcsdata[i]["time"] = day;
        }
        else {
            const t = new Date(pcsdata[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(pcsdata[i]["date"]).toLocaleDateString('en-US')
            pcsdata[i]["time"] = t + ' ' + day;
        }

    }

    return (
        <div>
            <Topappbar />
            <div>
                <div className="toparea">
                    <div className="topleft1">
                        <ThemeProvider theme={theme}>
                            <Button variant={flag ? "contained" : "outlined"} sx={{ marginRight: 2 }} className="topbutton1" onClick={() => handledevice(1)}>KMCC</Button>
                            <Button variant={flag2 ? "contained" : "outlined"} sx={{ marginRight: 2 }} className="topbutton2" onClick={() => handledevice(2)}>TOWNHALL</Button>
                        </ThemeProvider>
                    </div>
                    <div className="topright2">
                        <span className="textonthetoptt"><b>{"Active Site: " + device}</b></span>
                    </div>
                </div>
                <div className="acctopf">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Click to See More Options</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="accordianInside">
                                <div className="insidell">
                                    <div><b>Select options and click Update selection</b></div>
                                    <div className="selecttimeformat">
                                        <span>Set time format: </span>
                                        LocalTime
                                        <Radio
                                            checked={selectedValue === 'Localtime'}
                                            onChange={handleChange}
                                            value="Localtime"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        (UTC/GMT)
                                        <Radio
                                            checked={selectedValue === 'UTC/GMT'}
                                            onChange={handleChange}
                                            value="UTC/GMT"
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': 'B' }}
                                        />
                                    </div>
                                    {/*<div className="contch">
                                        <span className="textonthetoptt"><b>{"Active Site: " + device}</b></span>
                                        <Button sx={{ marginLeft: 2, marginBottom: 2 }}
                                            id="fade-button"
                                            aria-controls={openselector ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openselector ? 'true' : undefined}
                                            onClick={handleMenuClick}>Select Site</Button>
                                        <Menu
                                            id="fade-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'fade-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={openselector}
                                            onClose={handleClose}
                                            TransitionComponent={Fade}
                                        >
                                            <MenuItem onClick={() => handledevice(1)}>KMCC</MenuItem>
                                            <MenuItem onClick={() => handledevice(2)}>Town-Hall</MenuItem>
                                        </Menu>

                                        </div>*/}
                                    <div className="savebutton">
                                        <Button onClick={savedate}>Update selection</Button>
                                    </div>
                                </div>
                                <div className="dateselectors">
                                    <DateRangePicker
                                        color='#f7fef7'
                                        onChange={item => setState([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={state}
                                        minDate={new Date("January 1, 2023 00:00:00")}
                                        maxDate={addDays(new Date(), 1)}
                                        direction="horizontal"
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <div className='topGraphcontent'>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Battery SOC (%)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={plcdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} domain={[0, 100]} label={{ value: 'soc level in %', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Line type="monotone" dataKey="soc_power" stroke="#64b42c" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Total DC Voltage (V)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={plcdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} label={{ value: 'Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="total_dc_voltage" stroke="#8884d8" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className='topGraphcontent2'>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Total DC Current (A)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={plcdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} domain={[-350, 200]} tickCount={12} label={{ value: 'Current A', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="total_dc_current" stroke="#8884d8" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Total Battery Power (kW)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={plcdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} domain={[-250, 100]} tickCount={10} label={{ value: 'Power W', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="total_battery_power" stroke="#8884d8" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className='topGraphcontent2'>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Total AC Active Power (kW)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={pcsdata}>
                            <XAxis tick={{ fontSize: 15 }} dataKey="time" label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />

                            <YAxis tick={{ fontSize: 15 }} domain={[-210, 100]} tickCount={10} label={{ value: 'Active Power W', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="4 4" />
                            <Line type="monotone" dataKey="total_ac_active_power" stroke="#8884d8" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Frequency (Hz)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={pcsdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} label={{ value: 'Frequency Hz', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="frequency" stroke="#8884d8" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className='topGraphcontent2'>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Min & Max Cell Voltage (V)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={plcdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} domain={[2.5, 4]} label={{ value: 'Cell Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="cellVoltage_1" stroke="#8884d8" dot={false} />
                            <Line type="monotone" dataKey="cellVoltage_2" stroke="#7100FF" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className='rowGraphcontent'>
                    <h4 className="heaading">Temperature (C)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={plcdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} label={{ value: 'Temp C', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="temp" stroke="#8884d8" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className='topGraphcontent2'>
                <div className='onegraphrow'>
                    <h4 className="heaading">Line Voltages 1, 2, 3 (V)</h4>
                    <ResponsiveContainer width="100%" aspect={4 / 2} >
                        <LineChart width={500} height={300} data={pcsdata}>
                            <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <Tooltip />
                            <YAxis tick={{ fontSize: 15 }} domain={[350, 400]} label={{ value: 'Grid Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <CartesianGrid strokeDasharray="5 5" />
                            <Line type="monotone" dataKey="Grid_Voltage_1" stroke="#8884d8" dot={false} />
                            <Line type="monotone" dataKey="Grid_Voltage_2" stroke="#7100FF" dot={false} />
                            <Line type="monotone" dataKey="Grid_Voltage_3" stroke="#E900FF" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    )
}