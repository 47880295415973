import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import ResidentailTopbar from "../pagecomponents/ResidentialTopbar";

const cookies = new Cookies();


const token = cookies.get("TOKEN");

export default function Residentialerrors() {

    return (
        <div>
            <ResidentailTopbar />

        </div>
    )
}