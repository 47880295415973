import React from "react";
import Actiontopbar from "./Actiontopbar";

export default function Actionlogs() {

    return (
        <div>
            <Actiontopbar />
        </div>
    )
}