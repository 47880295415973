import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Topappbar from "../pagecomponents/Topappbar";
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import grid from "../animation/animationImages/new_icons/Grid.svg";
import stortower from "../animation/animationImages/new_icons/Battery_System.svg";
import cityhall from "../animation/animationImages/new_icons/city-hall.svg";
import buildingpfc from "../animation/animationImages/new_icons/building_pfc.svg";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Copyright from "../pagecomponents/copyright";
import Select from '@mui/material/Select';
import Xarrow, { useXarrow, Xwrapper } from "../../../node_modules/react-xarrows";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BoltIcon from '@mui/icons-material/Bolt';
import TodayIcon from '@mui/icons-material/Today';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import './storhubmainpage.css'
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    AreaChart,
    Area,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';


const cookies = new Cookies();


const token = cookies.get("TOKEN");



const analyticspage = () => {
    window.location.href = "/storhubanalytics"

}

const predpage = () => {
    window.location.href = "/storhubs"

}


//// tab settings

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

///////

function TabPanel2(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel2.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Storhubmainpage() {
    const options = ['Battery SOC (%)', 'Total DC Voltage (V)', 'Total DC Current (A)', 'Total Battery power (kW)', 'Total AC Active power (kW)', 'Frequency (Hz)', 'Min & Max Cell Voltage (V)', 'Temperature (C)', 'Line Voltages 1, 2, 3 (V)'];
    const options2 = ['Battery SOC (%)', 'Total DC Voltage (V)', 'Total DC Current (A)', 'Total Battery power (kW)', 'Total AC Active power (kW)', 'Frequency (Hz)', 'Min & Max Cell Voltage (V)', 'Temperature (C)', 'Line Voltages 1, 2, 3 (V)'];
    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);
    ///tab
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    ///

    const [value2, setValue2] = React.useState(0);

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };


    const [preddate, setPredictiondate] = useState('');
    const [plcdata, setPlcData] = useState([]);
    const [pcsdata, setPcsdata] = useState([]);
    const [plcdatatown, setPlcDatatown] = useState([]);
    const [pcsdatatown, setPcsdatatown] = useState([]);
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [gridtobattery, setGridtobattery] = useState({ animation: 0, showHead: false, color: '#E0E0E0' });
    const [batterytoout, setBatterytoout] = useState({ animation: 0, showHead: false, color: '#E0E0E0' });
    const [data2, setData] = useState([]);
    const [socdata1, setSocdata1] = useState('');
    const [socdata2, setSocdata2] = useState('');
    const [acactive1, setAcactive1] = useState('');
    const [acactive2, setAcactive2] = useState('');
    const [lastupdate1, setLastupdate1] = useState('');
    const [lastupdate2, setLastupdate2] = useState('');

    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);



    useEffect(() => {
        plc()
        pcsdatainsert()
        preddata()
        storhubstatus()
        plctown()
        pcsdatainserttown()
    }, []);

    const updateXarrow = useXarrow();


    const preddata = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + "storubkmccpreds",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                console.log(output_data)
                setData(output_data)
                const nextpred_date = new Date(data2[1]['Timestamp']).toLocaleDateString('it-IT');
                setPredictiondate(nextpred_date)
                //console.log(nextpred_date)
                //console.log(data)

                console.log('data', typeof (obj));

            })
            .catch((error) => {
                error = new Error();
            });
    }

    const storhubstatus = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/storhubstatus",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                const stat = response.data[0].status;
                console.log(response.data);
                if (stat === "Charging") {
                    setGridtobattery({ animation: 1, showHead: false, color: '#64B42C' })
                    setBatterytoout({ animation: 0, showHead: false, color: '#FFFFFF' })
                } else if (stat === "Discharging") {
                    setGridtobattery({ animation: 0, showHead: false, color: '#FFFFFF' })
                    setBatterytoout({ animation: 1, showHead: false, color: '#64B42C' })
                }
                else {
                    setGridtobattery({ animation: 0, showHead: false, color: '#010101' })
                    setBatterytoout({ animation: 0, showHead: false, color: '#010101' })
                }
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const plc = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/kmcc/plcregisters",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const plc_out = response.data
                setPlcData(plc_out)
                setSocdata1(plc_out.slice(-1)[0]['soc_power'])
                setLastupdate1(plc_out.slice(-1)[0]['temp'])
                console.log(plc_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const pcsdatainsert = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/kmcc/pcsregisterdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const pcs_out = response.data
                setPcsdata(pcs_out)
                setAcactive1(pcs_out.slice(-1)[0]['total_ac_active_power'])
                console.log(pcs_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }
    //////////////////////////////
    const plctown = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/townhall/plcregisters",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const plc_out = response.data
                setPlcDatatown(plc_out)
                setSocdata2(plc_out.slice(-1)[0]['soc_power'])
                setLastupdate2(plc_out.slice(-1)[0]['temp'])
                console.log(plc_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const pcsdatainserttown = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/townhall/pcsregisterdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const pcs_out = response.data
                setPcsdatatown(pcs_out)
                setAcactive2(pcs_out.slice(-1)[0]['total_ac_active_power'])
                console.log(pcs_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }
    /////////////////////////////
    for (let i = 0; i < plcdata.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(plcdata[i]["date"]).getUTCDate()
            const day = new Date(plcdata[i]["date"]).toUTCString()
            plcdata[i]["time"] = day;
        }
        else {
            const t = new Date(plcdata[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(plcdata[i]["date"]).toLocaleDateString('en-US')
            plcdata[i]["time"] = t + ' ' + day;
        }

    }

    for (let i = 0; i < pcsdata.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(pcsdata[i]["date"])
            const day = new Date(pcsdata[i]["date"])
            pcsdata[i]["time"] = day;
        }
        else {
            const t = new Date(pcsdata[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(pcsdata[i]["date"]).toLocaleDateString('en-US')
            pcsdata[i]["time"] = t + ' ' + day;
        }

    }

    /////////////////////////////

    for (let i = 0; i < plcdatatown.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(plcdatatown[i]["date"]).getUTCDate()
            const day = new Date(plcdatatown[i]["date"]).toUTCString()
            plcdatatown[i]["time"] = day;
        }
        else {
            const t = new Date(plcdatatown[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(plcdatatown[i]["date"]).toLocaleDateString('en-US')
            plcdatatown[i]["time"] = t + ' ' + day;
        }

    }

    for (let i = 0; i < pcsdatatown.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(pcsdatatown[i]["date"])
            const day = new Date(pcsdatatown[i]["date"])
            pcsdatatown[i]["time"] = day;
        }
        else {
            const t = new Date(pcsdatatown[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(pcsdatatown[i]["date"]).toLocaleDateString('en-US')
            pcsdatatown[i]["time"] = t + ' ' + day;
        }

    }

    /////////////////////////////////
    const startdd = new Date(state[0]["startDate"]).toDateString()
    const enddd = new Date(state[0]["endDate"]).toDateString()


    const obj = data2;
    let outdata = Object.values(obj);
    const amounts = Math.max(...outdata.map(o => o.predictions_kW))

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    for (let i = 0; i < data2.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(data2[i]["Timestamp"])
            const day = new Date(data2[i]["Timestamp"]).toUTCString()
            data2[i]["localt"] = day;
        }
        else {
            const t = new Date(data2[i]["Timestamp"]).toLocaleTimeString('it-IT')
            const day = new Date(data2[i]["Timestamp"]).toLocaleDateString('en-US')
            data2[i]["localt"] = t + ' ' + day;
        }

    }

    for (let i = 0; i < data2.length; i++) {
        const t = new Date(data2[i]["Timestamp"]).toLocaleTimeString(navigator.language, { hour: '2-digit' }) //navigator.language, { hour: '2-digit' }
        const day = new Date(data2[i]["Timestamp"]).toLocaleDateString('en-US')
        data2[i]["onlytime"] = t;
        data2[i]["time"] = t + ' ' + day;
        data2[i]["timezoned"] = new Date(data2[i]["Timestamp"]).toLocaleString('en-US', { timeZone: 'America/Halifax' })
    }

    function getmaxtime(datain, maxamount) {
        for (let i = 0; i < datain.length; i++) {
            if (datain[i]["predictions_kW"] === maxamount) {
                if (selectedValue === 'UTC/GMT') {
                    return data2[i]["localt"]
                }
                else {
                    return data2[i]["localt"]
                }
            }
        }
    }

    function getTimestamp(datainto, maxval) {
        for (let i = 0; i < datainto.length; i++) {
            if (datainto[i]["predictions_kW"] === maxval) {
                const st = new Date(data2[i]["Timestamp"])
                const starttime = st.setHours(st.getHours() - 1);
                const endtime = st.setHours(st.getHours() + 2);
                const stt = new Date(starttime).toUTCString(navigator.language, { hour: '2-digit' }, 'en-US', { timeZone: 'America/Halifax' })
                const edd = new Date(endtime).toUTCString(navigator.language, { hour: '2-digit' }, 'en-US', { timeZone: 'America/Halifax' })
                return <p>{stt} <br></br> <b>TO </b><br></br> {edd}</p>
            }
        }
    }

    function getonlytime(datatoin, maximum) {
        for (let i = 0; i < datatoin.length; i++) {
            if (datatoin[i]["predictions_kW"] === maximum) {
                return new Date(datatoin[i]["Timestamp"]).toUTCString(navigator.language, { hour: '2-digit' }, 'en-US', { timeZone: 'America/Halifax' })
            }
        }
    }

    function changetime(hours) {
        const maxt = getTimestamp(data2, amounts)
        const dateCopy = new Date(maxt);
        dateCopy.setHours(dateCopy.getHours() + hours);
        return dateCopy;
    }

    function get_split(datatoin, maximum) {
        for (let i = 0; i < datatoin.length; i++) {
            if (datatoin[i]["predictions_kW"] === maximum) {
                const ttmp = datatoin[i]["time"].split(" ").pop();
                return ttmp;
            }
        }
    }



    const maxtime = getmaxtime(data2, amounts)
    const timeonly = getonlytime(data2, amounts)
    const maxt = getTimestamp(data2, amounts)
    const splitted = get_split(data2, amounts)

    console.log(typeof maxtime)


    var Difference_In_Time = state[0]["endDate"].getTime() - state[0]["startDate"].getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return (
        <div>
            <Topappbar />

            <div className="mainpagetopdiv" >
                <Xwrapper>
                    <div className="topanimationmainpage" onScroll={updateXarrow}>
                        <div className="imageanima" id={'kmccbuild'}>
                            <img className="animationbuilding" src={buildingpfc} width="50px" height="70px" />
                            <span><b>KMCC</b></span>
                        </div>
                        <div className="imageanima" id={'gridpower'}>
                            <img className="animationbuilding" src={grid} width="50px" height="70px" />
                            <span><b>Grid</b></span>
                        </div>

                        <div className="imageanima" id={'cityhall'}>
                            <img className="animationbuilding" src={cityhall} width="50px" height="70px" />
                            <span><b>TownHall</b></span>
                        </div>
                    </div>
                    <div className="secondanimlay">
                        <div className="imageanima" id={'stortow'}>
                            <img className="animationbuilding" src={stortower} width="50px" height="70px" />
                            <span><b>Storhub KMCC</b></span>
                        </div>
                        <div className="imageanima" id={'stortow2'}>
                            <img className="animationbuilding" src={stortower} width="50px" height="70px" />
                            <span><b>Storhub Townhall </b></span>
                        </div>
                    </div>
                    <Xarrow start={"gridpower"} end={"stortow"} path={"grid"} startAnchor={"left"} endAnchor={"top"} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: gridtobattery.animation }} showHead={gridtobattery.showHead} color={gridtobattery.color} />
                    <Xarrow start={"gridpower"} end={"stortow2"} path={"grid"} startAnchor={"right"} endAnchor={"top"} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: gridtobattery.animation }} showHead={gridtobattery.showHead} color={gridtobattery.color} />
                    <Xarrow start={"stortow2"} end={"cityhall"} path={"grid"} startAnchor={"top"} endAnchor={"left"} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout.animation }} showHead={batterytoout.showHead} color={batterytoout.color} />
                    <Xarrow start={"stortow"} end={"kmccbuild"} path={"grid"} startAnchor={"top"} endAnchor={"right"} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout.animation }} showHead={batterytoout.showHead} color={batterytoout.color} />
                    <Xarrow start={"gridpower"} end={"cityhall"} path={"grid"} startAnchor={"right"} endAnchor={"left"} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout.animation }} showHead={false} color={batterytoout.color} />{/*batterytoout.color*/}
                    <Xarrow start={"gridpower"} end={"kmccbuild"} path={"grid"} startAnchor={"left"} endAnchor={"right"} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout.animation }} showHead={false} color={batterytoout.color} />

                </Xwrapper>
                <div className="topmainpagearea">
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Discharge Period for
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {splitted}
                            </Typography>
                            <Typography variant="body2">
                                <p>{maxt} </p>
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => predpage()}>Get more data</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                KMCC Last Data
                            </Typography>
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            >
                                <ListItem>
                                    <ListItemIcon>
                                        <Battery2BarIcon />
                                    </ListItemIcon>
                                    <ListItemText id="KMCC-battery-percent" primary="Battery SOC" />
                                    <Typography>
                                        {socdata1}%
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <BoltIcon />
                                    </ListItemIcon>
                                    <ListItemText id="KMCC-AC-ACTIVE" primary="Active Power" />
                                    <Typography>
                                        {acactive1}kW
                                    </Typography>
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => analyticspage()}>Get more data</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                TownHall Last Data
                            </Typography>
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            >
                                <ListItem>
                                    <ListItemIcon>
                                        <Battery2BarIcon />
                                    </ListItemIcon>
                                    <ListItemText id="Townhall-battery-percent" primary="Battery SOC" />
                                    <Typography>
                                        {socdata2}%
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <BoltIcon />
                                    </ListItemIcon>
                                    <ListItemText id="Townhall-AC-ACTIVE" primary="Active Power" />
                                    <Typography>
                                        {acactive2}kW
                                    </Typography>
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button onClick={() => analyticspage()}>Get more data</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Storhub Temperature
                            </Typography>
                            <List
                                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            >
                                <ListItem>
                                    <ListItemIcon>
                                        <DeviceThermostatIcon />
                                    </ListItemIcon>
                                    <ListItemText id="Townhall-battery-percent" primary="KMCC Temp" />
                                    <Typography>
                                        {lastupdate1}°C
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <DeviceThermostatIcon />
                                    </ListItemIcon>
                                    <ListItemText id="Townhall-battery-percent" primary="TownHall Temp" />
                                    <Typography>
                                        {lastupdate2}°C
                                    </Typography>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </div>
                <div className="graphexplainer">
                    <div className="nameholder">
                        <h3>KMCC Data Graphs</h3>
                    </div>
                    <div className="nameholder">
                        <h3>TownHall Data Graphs</h3>
                    </div>
                </div>
                <div className="tabholder">
                    <div className="graphtab">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} variant="scrollable"
                                    scrollButtons="auto" aria-label="tabs">
                                    <Tab label="Battery SOC (%)" {...a11yProps(0)} />
                                    <Tab label="Total DC Voltage (V)" {...a11yProps(1)} />
                                    <Tab label="Total DC Current (A)" {...a11yProps(2)} />
                                    <Tab label="Total Battery power (kW)" {...a11yProps(3)} />
                                    <Tab label="Total AC Active power (kW)" {...a11yProps(4)} />
                                    <Tab label="Frequency (Hz)" {...a11yProps(5)} />
                                    <Tab label="Min & Max Cell Voltage (V)" {...a11yProps(6)} />
                                    <Tab label="Temperature (C)" {...a11yProps(7)} />
                                    <Tab label="Line Voltages 1, 2, 3 (V)" {...a11yProps(8)} />

                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>

                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[0, 100]} label={{ value: 'soc level in %', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Line type="monotone" dataKey="soc_power" stroke="#64b42c" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>

                            </TabPanel>
                            <TabPanel value={value} index={1}>

                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} label={{ value: 'Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="total_dc_voltage" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>

                            </TabPanel>
                            <TabPanel value={value} index={2}>

                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[-350, 200]} tickCount={12} label={{ value: 'Current A', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="total_dc_current" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>

                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[-250, 100]} tickCount={10} label={{ value: 'Power kW', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="total_battery_power" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={pcsdata}>
                                        <XAxis tick={{ fontSize: 15 }} dataKey="time" label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />

                                        <YAxis tick={{ fontSize: 15 }} domain={[-210, 100]} tickCount={10} label={{ value: 'Active Power W', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="4 4" />
                                        <Line type="monotone" dataKey="total_ac_active_power" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={pcsdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} label={{ value: 'Frequency Hz', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="frequency" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[2.5, 4]} label={{ value: 'Cell Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="cellVoltage_1" stroke="#8884d8" dot={false} />
                                        <Line type="monotone" dataKey="cellVoltage_2" stroke="#7100FF" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value} index={7}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} label={{ value: 'Temp C', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="temp" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value} index={8}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={pcsdata}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[350, 400]} label={{ value: 'Grid Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="Grid_Voltage_1" stroke="#8884d8" dot={false} />
                                        <Line type="monotone" dataKey="Grid_Voltage_2" stroke="#7100FF" dot={false} />
                                        <Line type="monotone" dataKey="Grid_Voltage_3" stroke="#E900FF" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                        </Box>
                    </div>
                    <div className="graphtab">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value2} onChange={handleChange2} variant="scrollable"
                                    scrollButtons="auto" aria-label="tabs">
                                    <Tab label="Battery SOC (%)" {...a11yProps2(0)} />
                                    <Tab label="Total DC Voltage (V)" {...a11yProps2(1)} />
                                    <Tab label="Total DC Current (A)" {...a11yProps2(2)} />
                                    <Tab label="Total Battery power (kW)" {...a11yProps2(3)} />
                                    <Tab label="Total AC Active power (kW)" {...a11yProps2(4)} />
                                    <Tab label="Frequency (Hz)" {...a11yProps2(5)} />
                                    <Tab label="Min & Max Cell Voltage (V)" {...a11yProps2(6)} />
                                    <Tab label="Temperature (C)" {...a11yProps2(7)} />
                                    <Tab label="Line Voltages 1, 2, 3 (V)" {...a11yProps2(8)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value2} index={0}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[0, 100]} label={{ value: 'soc level in %', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Line type="monotone" dataKey="soc_power" stroke="#64b42c" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={1}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} label={{ value: 'Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="total_dc_voltage" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={2}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[-350, 200]} tickCount={12} label={{ value: 'Current A', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="total_dc_current" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={3}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[-250, 100]} tickCount={10} label={{ value: 'Power W', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="total_battery_power" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={4}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={pcsdatatown}>
                                        <XAxis tick={{ fontSize: 15 }} dataKey="time" label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />

                                        <YAxis tick={{ fontSize: 15 }} domain={[-210, 100]} tickCount={10} label={{ value: 'Active Power W', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="4 4" />
                                        <Line type="monotone" dataKey="total_ac_active_power" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={5}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={pcsdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} label={{ value: 'Frequency Hz', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="frequency" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={6}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[2.5, 4]} label={{ value: 'Cell Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="cellVoltage_1" stroke="#8884d8" dot={false} />
                                        <Line type="monotone" dataKey="cellVoltage_2" stroke="#7100FF" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={7}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={plcdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} label={{ value: 'Temp C', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="temp" stroke="#8884d8" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel value={value2} index={8}>
                                <ResponsiveContainer width="100%" aspect={4 / 2} >
                                    <LineChart width={500} height={300} data={pcsdatatown}>
                                        <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                                        <Tooltip />
                                        <YAxis tick={{ fontSize: 15 }} domain={[350, 400]} label={{ value: 'Grid Voltage V', angle: -90, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                                        <CartesianGrid strokeDasharray="5 5" />
                                        <Line type="monotone" dataKey="Grid_Voltage_1" stroke="#8884d8" dot={false} />
                                        <Line type="monotone" dataKey="Grid_Voltage_2" stroke="#7100FF" dot={false} />
                                        <Line type="monotone" dataKey="Grid_Voltage_3" stroke="#E900FF" dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                        </Box>
                    </div>
                </div>
            </div>


            <Copyright sx={{ mt: 1, mb: 1 }} />
        </div>
    )
}