import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Mainappbar from "../pagecomponents/Mainappbar";
import './adminprojstyle.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Tooltip from "@mui/material/Tooltip";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Modal from '@mui/material/Modal';

const cookies = new Cookies();


const token = cookies.get("TOKEN");

export default function Adminprojectcontrol() {

    return (
        <div>
            <Mainappbar />
            <div>
                <h6>Project data select</h6>
                <div>
                    <Box component="form" noValidate sx={{
                        mt: 1, display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '80%',
                        flexWrap: 'nowrap',
                        alignContent: 'normal',
                        margin: 'auto',
                    }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>Parameters</TableCell>
                                        <TableCell>Set data</TableCell>
                                        <TableCell>Help</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <Tooltip title="this command sets the charging hours for the device">
                                            <TableCell>upload data</TableCell>
                                        </Tooltip>
                                        <TableCell >
                                            <Tooltip title="enter the hours to charge in form HH:MM HH:MM">
                                                <TextField sx={{ m: 2 }} id="filled-basic" label="hh:mm hh:mm" variant="filled"
                                                />
                                            </Tooltip>
                                            <Tooltip title="enter the charging power">
                                                <TextField sx={{ m: 2 }} id="filled-basic" label="set charge power kW" variant="filled"
                                                />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell >
                                            <Tooltip title="click to send command">
                                                <Button >upload data</Button>
                                            </Tooltip>
                                        </TableCell>
                                        <Tooltip title="Click on ? to learn more about this commmand">
                                            <TableCell>
                                                <QuestionMarkIcon >
                                                </QuestionMarkIcon>
                                            </TableCell>
                                        </Tooltip>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Start Charge </TableCell>
                                        <TableCell ><TextField sx={{ m: 2 }} id="filled-basic" label="set charge power kW" variant="filled"
                                        /></TableCell>
                                        <TableCell ><Button >upload data</Button></TableCell>
                                        <TableCell>
                                            <QuestionMarkIcon >
                                            </QuestionMarkIcon>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stop Battery Charge</TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ><Button >upload data</Button></TableCell>
                                        <TableCell>
                                            <QuestionMarkIcon>
                                            </QuestionMarkIcon>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </Box>
                </div>

            </div>



        </div>
    )
}