import React, { useEffect, useState } from "react";
import "./topbar.css"
import Storteralogo from '../../assets/icons/storteralogo.svg';
import { NotificationsNone, Settings, Logout } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DataArrayIcon from '@mui/icons-material/DataArray';
import TuneIcon from '@mui/icons-material/Tune';
import ErrorIcon from '@mui/icons-material/Error';
import flowbattery from "../animation/animationImages/flowbattery.png"
import SsidChartIcon from '@mui/icons-material/SsidChart';
import Cookies from "universal-cookie";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PeopleIcon from '@mui/icons-material/People';
//import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import DescriptionIcon from '@mui/icons-material/Description';
import axios from "axios";
import AccountBoxIcon from '@mui/icons-material/AccountBox';



const cookies = new Cookies();

const token = cookies.get("TOKEN");

const logout = () => {
    // destroy the cookie
    cookies.remove("TOKEN", { path: "/PKC" });
    // redirect user to the landing page
    window.location.href = "/PKC";
}

const dataVisual = () => {
    window.location.href = "/components/pages";
}

const mainpage = () => {
    window.location.href = "/pkchome"

}

const ai = () => {
    window.location.href = "/AI"

}


const systemcontrol = () => {
    window.location.href = "/systemControl"

}

/*const profile = () => {
    window.location.href = "/profile"

}*/

const rawdata = () => {
    window.location.href = "/rawData"
}

const graphspage = () => {
    window.location.href = "/pkccharts"
}

const logs = () => {
    window.location.href = "/pkclogs"
}

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function Topbar() {
    const theme = useTheme();
    const [time, setMessage] = useState("");
    const [status, setMessage2] = useState("");
    const [notice, setNotice] = useState("");
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open2 = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };




    // useEffect automatically executes once the page is fully loaded
    useEffect(() => {
        // set configurations for the API call here
        get_status();
        get_notifications();

    }, []);

    const get_notifications = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/pkc_notices",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const outs = result.data
                console.log(outs)
                setNotice(outs[0]);

            })
            .catch((error) => {
                error = new Error();
            });
    }

    const get_status = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/status",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                const firstout = output_data[0]
                setMessage(JSON.stringify(Date(firstout.time)));
                setMessage2(JSON.stringify(firstout.status));
            })
            .catch((error) => {
                error = new Error();
            });
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{
                    backgroundColor: 'white',
                    color: 'Black',
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        <img src={Storteralogo} alt="storteralogo" className="logon" width="100px" height="50px" onClick={() => mainpage()} />
                    </Typography>

                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        <h5>Status : <img className="animationbuilding" src={flowbattery} width="30px" height="30px" /></h5>
                    </Typography>



                    <IconButton aria-label="Notifications"
                        aria-controls={open2 ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleClick} >
                        <NotificationsNone type="submit" width="20px" height="20px" />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open2}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>{notice}</MenuItem>
                    </Menu>
                    <IconButton aria-label="Settings" onClick={() => systemcontrol()}>
                        < Settings type="submit" width="20px" height="20px" >
                        </Settings>
                    </IconButton>
                    <IconButton aria-label="User" onClick={() => logout()} >
                        < Logout type="submit" width="20px" height="20px" >
                        </Logout>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem onClick={() => mainpage()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Home
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => graphspage()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <SsidChartIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Graphs
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => dataVisual()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AnalyticsIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Analytics
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => rawdata()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <DataArrayIcon />
                            </ListItemIcon>
                            <ListItemText>
                                RawData
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => systemcontrol()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <TuneIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Control
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => logs()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Logs
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    {/*<ListItem onClick={() => profile()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Profile
                            </ListItemText>
                        </ListItemButton>
            </ListItem>*/}
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box>




    )
}