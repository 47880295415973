import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topappbar from "../pagecomponents/Topappbar";
import { addDays } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import './storhubdata.css'
import { DateRangePicker } from 'react-date-range';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { CSVLink, } from "react-csv";
import Radio from '@mui/material/Radio';
const cookies = new Cookies();


const token = cookies.get("TOKEN");




export default function StorhubData() {
    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);
    const [plcdata, setPlcData] = useState([]);
    const [pcsdata, setPcsdata] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [device, setdevice] = React.useState("KMCC");
    const [urlend, setUrlend] = React.useState("kmcc");
    const openselector = Boolean(anchorEl);
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [isSending, setIsSending] = useState(false)

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handledevice = (name) => {
        switch (name) {
            case 1:
                setdevice("KMCC")
                setUrlend("kmcc")
                break;
            case 2:
                setdevice("Townhall")
                setUrlend("townhall")
                break;
        }
    };
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        plc()
        pcsdatainsert()

    }, [isSending]);

    const plc = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/plcregisters",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const plc_out = response.data
                setPlcData(plc_out)
                console.log(plc_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const pcsdatainsert = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend + "/pcsregisterdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        axios(configuration)
            .then((response) => {

                const pcs_out = response.data
                setPcsdata(pcs_out)
                console.log(pcs_out)
            })
            .catch((error) => {
                error = new Error();
            });
    }
    ///change the utc gmt activation througn a button in settings in the accordian
    for (let i = 0; i < plcdata.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(plcdata[i]["date"]).getUTCDate()
            const day = new Date(plcdata[i]["date"]).toUTCString()
            plcdata[i]["time"] = day;
        }
        else {
            const t = new Date(plcdata[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(plcdata[i]["date"]).toLocaleDateString('en-US')
            plcdata[i]["time"] = t + ' ' + day;
        }

    }

    for (let i = 0; i < pcsdata.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(pcsdata[i]["date"])
            const day = new Date(pcsdata[i]["date"])
            pcsdata[i]["time"] = day;
        }
        else {
            const t = new Date(pcsdata[i]["date"]).toLocaleTimeString('it-IT')
            const day = new Date(pcsdata[i]["date"]).toLocaleDateString('en-US')
            pcsdata[i]["time"] = t + ' ' + day;
        }

    }


    const columnstest = [
        { field: 'time', headerName: 'Time (' + selectedValue + ')', width: 250 },
        { field: 'soc_power', headerName: 'Battery percentage (%)', width: 250 },
        { field: 'total_battery_power', headerName: 'Total Battery Power (kW)', width: 250 },
        { field: 'total_dc_voltage', headerName: 'Total DC Voltage (V)', width: 250 },
        { field: 'total_dc_current', headerName: 'Total DC Current (A)', width: 300 },
        { field: 'temp', headerName: 'Temperature (C)', width: 200 },
        { field: 'cellVoltage_1', headerName: 'Min Cell Voltage (V)', width: 200 },
        { field: 'cellVoltage_2', headerName: 'Max Cell Voltage (V)', width: 200 },
    ];

    const pcscols = [
        { field: 'time', headerName: 'Time (' + selectedValue + ')', width: 300 },
        { field: 'total_ac_active_power', headerName: 'Total AC Active Power (kW)', width: 250 },
        { field: 'L1_acive_power', headerName: 'L1 active Power (W)', width: 200 },
        { field: 'L2_active_power', headerName: 'L2 active Power (W)', width: 200 },
        { field: 'L3_active_power', headerName: 'L3 active Power (W)', width: 200 },
        { field: 'frequency', headerName: 'frequency (Hz)', width: 200 },
        { field: 'Grid_Voltage_1', headerName: 'Grid Voltage 1 (V)', width: 200 },
        { field: 'Grid_Voltage_2', headerName: 'Grid voltage 2 (V)', width: 200 },
        { field: 'Grid_Voltage_3', headerName: 'Grid voltage 3 (V)', width: 200 },

    ]

    const testrows = plcdata
    const pcsrows = pcsdata




    return (

        <div>
            <Topappbar />
            <div className="topselarea">
                <span className="textindatop"><b>{"Active Site: " + device}</b></span>
            </div>
            <div className="topaccdata">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Select Date and Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="insideacc">
                            <div className="leftdatas">
                                <div>
                                    <span><b>Select date and options and click Update selection</b></span>
                                </div>
                                <div className="timeandutc">
                                    set time format :
                                    LocalTime
                                    <Radio
                                        checked={selectedValue === 'Localtime'}
                                        onChange={handleChange}
                                        value="Localtime"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    (UTC/GMT)
                                    <Radio
                                        checked={selectedValue === 'UTC/GMT'}
                                        onChange={handleChange}
                                        value="UTC/GMT"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />

                                </div>
                                <div className="buttonandtext">
                                    <span className="tetxsss"><b>{"Active Site: " + device}</b></span>
                                    <Button sx={{ marginLeft: 2, marginBottom: 2 }}
                                        id="fade-button"
                                        aria-controls={openselector ? 'fade-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openselector ? 'true' : undefined}
                                        onClick={handleMenuClick}>Select Site</Button>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={openselector}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >
                                        <MenuItem onClick={() => handledevice(1)}>KMCC</MenuItem>
                                        <MenuItem onClick={() => handledevice(2)}>Town-Hall</MenuItem>
                                    </Menu>
                                </div>
                                <div className="downbottons">
                                    <CSVLink filename={"BatterySystemData.csv"} data={plcdata}>Download Battery system Data</CSVLink>
                                </div>
                                <div>
                                    <CSVLink filename={"PCSData.csv"} data={pcsdata}>Download pcs Data</CSVLink>
                                </div>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                            <div className="dateppic">
                                <DateRangePicker
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("January 1, 2023 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                />
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="tableholder">
                <div className="tablettp">
                    <h2 className="dataheading">Battery System Data</h2>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={testrows}
                            columns={columnstest}
                            pageSize={100}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row._id}
                        />
                    </Box>

                </div>
                <div className="tablebtm">
                    <h2 className="dataheading">PCS DATA</h2>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={pcsrows}
                            columns={pcscols}
                            pageSize={100}
                            rowsPerPageOptions={[5]}
                            getRowId={(row) => row._id}
                        />
                    </Box>
                </div>

            </div>
            <div>

            </div>
        </div>
    )
}