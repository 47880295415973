import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import Building from "../animation/animationImages/new_icons/Building.svg"
import Line from "../animation/animationImages/new_icons/line.svg"
import grid from "../animation/animationImages/new_icons/Grid.svg"
import importing_meter from "../animation/animationImages/new_icons/Meter_Importing.svg"
import export_meter from "../animation/animationImages/new_icons/Meter_Exporting.svg"
import carcharger from "../animation/animationImages/new_icons/EV_Charger.svg"
import solar from "../animation/animationImages/new_icons/Solar.svg"
import stortower from "../animation/animationImages/new_icons/Battery_System.svg"
import triacon from "../animation/animationImages/new_icons/TRIACON_1.svg"
import vertical_line from "../animation/animationImages/new_icons/vertical_line.svg"
import remoteload from "../animation/animationImages/new_icons/Loads.svg"
import Xarrow, { useXarrow, Xwrapper } from "../../../node_modules/react-xarrows";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Copyright from "../pagecomponents/copyright";
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CardContent from '@mui/material/CardContent';
import BoltIcon from '@mui/icons-material/Bolt';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { addDays } from 'date-fns';
import {
    LineChart,
    //Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as GraphTooltip,
    Legend,
    //ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';

import "./pkchome.css"

const cookies = new Cookies();


const token = cookies.get("TOKEN");

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '80%',
    bgcolor: 'background.paper',
    p: 4,
};

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


export default function Pkchome() {

    const [open, setOpen] = React.useState(false);
    const [buildingconsumption, setLoadgraph] = useState(false)
    const [pvgraph, setPvgraph] = useState(false)
    const [meter1graph, setMeter1graphgraph] = useState(false)
    const [gridPower, setGridpower] = useState(false)
    const [meter2graph, setMeter2graphgraph] = useState(false)
    const [RemoteLoadsgraph, setRemoteLoadsgraph] = useState(false)
    const [stortowergraph, setStortowergraph] = useState(false)
    const [flowbatterygraph, setFlowbatterygraph] = useState(false)
    const [triacongraph, setTriacongraph] = useState(false)
    const [carchargergraph, setCarchargergraph] = useState(false)
    const [nameofdevice, setNameofdevice] = useState('NO device')
    const [data, setData] = useState("");
    const [alldata, setAllData] = useState([0]);
    const [state, setState] = useState(0);
    const initialLines = { showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#E0E0E0' }
    const [remoteloadlines, setRemoteloadline] = useState(initialLines);
    const [pvlines, setPvline] = useState(initialLines);
    const [importorexport, setImportexport] = useState({ start: "Meterd1", end: "BuildingImg", showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#E0E0E0' });
    const [gridtometer2, setGridtometer2] = useState(initialLines);
    const [stortowerlines, setStortower] = useState({ start: "Meterd1", end: "grid1", showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#E0E0E0' });
    const [meter1andgrid, setMeter1andgrid] = useState({ start: "carCharger1", end: "stortower", showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#E0E0E0' });
    const [totaltodayproduction, setTodayprod] = useState(0);
    const [todaycons, setTodaycons] = useState(0);
    const [totalev, setTotalEV] = useState(0);
    const [timeouttime, setTimeouttime] = useState(0);
    const [setstortowerstate, setStortowerstate] = useState('Idle');
    const [setTotalprod, Totalprod] = useState('0');


    const out_data = data;
    const time = new Date(out_data['date/time']).toLocaleTimeString('it-IT');
    const date = new Date(out_data['date/time']).toLocaleDateString('it-IT');
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);

    const excess = (data.PV_power_kW - (data.Load_power_kW + data.remote_load_1 + data.remote_load_2 + data.remote_load_3) - (data.Total_EV_power) / 1000).toFixed(2)


    useEffect(() => {
        // set configurations for the API call here
        GetData();
        Getproduction();
        const interval = setInterval(() => {
            console.log('Logs every minute');
            GetData();
            Getproduction();
        }, MINUTE_MS);



        return () => clearInterval(interval);
    }, []);

    const MINUTE_MS = 300000;

    const Getproduction = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/totalProduction",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        // make the API call
        axios(configuration)
            .then((result) => {
                const total_prod = result.data
                Totalprod(total_prod[0]['production'])
                console.log(total_prod)
            }).catch((error) => {
                error = new Error();
            });
    }




    const GetData = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/table",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": refreshDate,
                "enddate": addDays(refreshDate, 1),
            }
        };
        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                const checkData = output_data[output_data.length - 1]
                const pv = checkData['PV_power_kW']
                const remoteloads = checkData['remote_load_1'] + checkData['remote_load_2'] + checkData['remote_load_3']
                const import_export = pv - checkData['Load_power_kW'] - remoteloads
                const grid_to_ev = checkData['Total_EV_power']
                const stortower_consumption = checkData['Battery_Current_A']
                const stortower_consumption1 = parseFloat(stortower_consumption) / 10;
                const power_direction = checkData["Battery power direction"]
                const datetime = new Date(output_data['date/time'])
                const addedtime = new Date(datetime.getTime() + 300000)
                const datenow = Date.now();
                var timeouts = Math.abs((addedtime - datenow) + 5000)
                var total_production_for_today = 0;
                var total_consumption = 0;
                var total_ev_cons = 0;
                setTimeouttime(timeouts)
                setData(output_data[output_data.length - 1])
                var coeff = 1000 * 60 * 5;
                for (let i = 0; i < output_data.length; i++) {
                    const t = new Date(output_data[i]["date/time"]).toLocaleTimeString('it-IT').replace(/(.*)\D\d+/, '$1')
                    const day = new Date(output_data[i]["date/time"]).toLocaleDateString('en-US')
                    output_data[i]['EV_power_phase_1'] = Math.abs(output_data[i]['EV_power_phase_1']);
                    output_data[i]['EV_power_phase_2'] = Math.abs(output_data[i]['EV_power_phase_2']);
                    output_data[i]['EV_power_phase_3'] = Math.abs(output_data[i]['EV_power_phase_3']);
                    //output_data[i]['Total_EV_power'] = output_data[i]['EV_power_phase_1'] + output_data[i]['EV_power_phase_2'] + output_data[i]['EV_power_phase_3'];
                    output_data[i]["date"] = day;
                    output_data[i]["time"] = t;
                    output_data[i]["Battery_Voltage_V"] = (output_data[i]["Battery_Voltage_V"] / 10)

                    total_consumption += (output_data[i]["Load_power_kW"] * 5 / 60)
                    total_ev_cons += (output_data[i]['Total_EV_power'] * 5 / 60)
                    //output_data[i]["roundedtime"] = new Date(Math.round(output_data[i]["date/time"].getTime() / coeff) * coeff)
                    output_data[i]['Meter1'] = (output_data[i]['PV_power_kW'] - output_data[i]['Load_power_kW']).toFixed(2)
                    output_data[i]['Meter2'] = ((output_data[i]['Total_EV_power']) / 1000) /*+ (output_data[i]['Battery_Voltage_V'] * (output_data[i]['Battery_Current_A'] / 10)) / 1000)*/
                    output_data[i]['Total_Grid'] = ((output_data[i]['PV_power_kW']) - (output_data[i]['Load_power_kW']) - ((output_data[i]['Total_EV_power']) / 1000)).toFixed(2)
                    if (output_data[i]["PV_power_kW"] > 0) {
                        total_production_for_today += (output_data[i]["PV_power_kW"] * 5 / 60)
                    }

                    if ((output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"]) > 0) {
                        output_data[i]['savings'] = output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"]
                    }
                    else {
                        output_data[i]['savings'] = 0
                    }
                }
                setTodayprod(total_production_for_today.toFixed(2))
                setTodaycons(total_consumption.toFixed(2))
                setTotalEV(((total_ev_cons) / 1000).toFixed(2))
                setAllData(output_data)
                console.log(stortower_consumption.charAt(0))
                if (remoteloads === 0) {
                    setRemoteloadline({ showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#2D2E2D' })
                } else {
                    setRemoteloadline({ showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#374EE9' })
                }
                if (pv > 0) {
                    setPvline({ showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#64b42c' })
                } else {
                    setPvline({ showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#2D2E2D' })
                }
                if (import_export === 0) {
                    setImportexport({ start: "Meterd1", end: "BuildingImg", showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#2D2E2D' })
                } else if (import_export > 0) {
                    setImportexport({ start: "BuildingImg", end: "Meterd1", showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#64b42c' })
                } else {
                    setImportexport({ start: "Meterd1", end: "BuildingImg", showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#FCAE1E' })
                }
                if (import_export === 0) {
                    setMeter1andgrid({ start: "Meterd1", end: "grid1", showXarrow: false, animation: 0, showHead: false, showTail: false, color: '#E0E0E0' })
                } else if (import_export > 0) {
                    setMeter1andgrid({ start: "Meterd1", end: "grid1", showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#64b42c' })
                } else {
                    setMeter1andgrid({ start: "grid1", end: "Meterd1", showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#FCAE1E' })
                }
                if (grid_to_ev === 0) {
                    setGridtometer2({ showXarrow: false, animation: 0, showHead: false, showTail: false, color: '#FCAE1E' })
                } else if (grid_to_ev > 0) {
                    setGridtometer2({ showXarrow: true, animation: -1, showHead: true, showTail: false, color: '#FCAE1E' })
                } else {
                    setGridtometer2({ showXarrow: true, animation: -1, showHead: true, showTail: false, color: '#FCAE1E' })
                }
                if ((stortower_consumption) / 10 > 5) {
                    setStortower({ start: "carCharger1", end: "stortower", showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#64b42c' })
                    setStortowerstate('Charging')
                } else if (stortower_consumption < 0) {
                    setStortowerstate('Discharging')
                    setStortower({ start: "stortower", end: "carCharger1", showXarrow: true, animation: 1, showHead: true, showTail: false, color: '#FCAE1E' })
                } else {
                    setStortowerstate('Idle')
                    setStortower({ start: "stortower", end: "carCharger1", showXarrow: true, animation: 0, showHead: false, showTail: false, color: '#2D2E2D' })
                }
            })
            .catch((error) => {
                error = new Error();
            });
    }



    const handleOpen = (name) => {
        setOpen(true)
        switch (name) {
            case 1:
                setPvgraph(true)
                setNameofdevice('PV Generation')
                break;
            case 2:
                setGridpower(true)
                setNameofdevice('Grid')
                break;
            case 3:
                setTriacongraph(true)
                setNameofdevice('Triacon')
                break;
            case 4:
                setLoadgraph(true)
                setNameofdevice('Load')
                break;
            case 5:
                setFlowbatterygraph(true)
                setNameofdevice('Flow Battery')
                break;
            case 6:
                setStortowergraph(true)
                setNameofdevice('Stortower')
                break;
            case 7:
                setCarchargergraph(true)
                setNameofdevice('Car Charger')
                break;
            case 8:
                setRemoteLoadsgraph(true)
                setNameofdevice('Remote Loads')
                break;
            case 9:
                setMeter1graphgraph(true)
                setNameofdevice('Meter 1')
                break;
            case 10:
                setMeter2graphgraph(true)
                setNameofdevice('Meter 2')
        }
    };

    const handleClose = () => {
        setOpen(false);
        setLoadgraph(false)
        setPvgraph(false)
        setMeter1graphgraph(false)
        setMeter2graphgraph(false)
        setGridpower(false)
        setRemoteLoadsgraph(false)
        setStortowergraph(false)
        setTriacongraph(false)
        setCarchargergraph(false)
        setFlowbatterygraph(false)

    }

    //const currnetdatadate = out_data[0]["date"]




    return (

        <div>
            <Topbar />
            <div className="homepagetopani">
                <Xwrapper>
                    <LightTooltip title="Last update time of all the data below. Data will be updated every 5 minutes and 8 seconds" arrow>
                        <h6 className="timeTitle" onChange={useXarrow()}><b>{"Last Update at: " + date + ' ' + time}</b></h6>
                    </LightTooltip>
                    <div className="AnimationContainer">
                        <div className="dayproductionContainer">
                            <div className="dayimg">
                                <img src={solar} width="50px" height="50px" />
                            </div>
                            <div className="values">
                                <span className="productiontitle">Production Today<br></br></span>
                                <span><b>{(setTotalprod / 1000).toFixed(2)}</b>kWh</span>
                            </div>
                        </div>
                        <div className="mainBuildingArea">
                            <div className="imagecontainersforbuilding">
                                <Tooltip title="click on the image for more info">
                                    <div className="RenewableInputs" >
                                        <div className="topcontent1" id={'solar1'}>
                                            <img className="animationbuilding" src={solar} width="50px" height="50px" onClick={() => handleOpen(1)} />
                                            <span className="animationtext2">Solar PV</span>
                                            <span className="animationtext2">Power</span>
                                        </div>
                                        <div className="bottomcontent1">
                                            <span className="smalltext1"> <b className="boldtext">{out_data['PV_power_kW'] + ""}</b>kW</span>
                                        </div>
                                    </div>
                                </Tooltip>
                                <Tooltip title="click on the image for more info">
                                    <div className="PowerUsers" >
                                        <div className="topcontent1" id={"BuildingImg"}>
                                            <img className="animationbuilding" src={Building} alt="building" width="50px" height="50px" onClick={() => handleOpen(4)} />
                                            <span className="animationtext2">Building</span>
                                            <span className="animationtext2">  Consumption</span>
                                        </div>
                                        <div className="bottomcontent1">
                                            <span className="smalltext1"><b className="boldtext">{out_data['Load_power_kW'] + ''}</b>kW</span>
                                        </div>
                                    </div>

                                </Tooltip>
                                <Tooltip title="click on the image for more info">
                                    <div className="Load" >
                                        <div className="topcontent1" id={'remoteloads'}>
                                            <img className="animationbuilding" src={remoteload} width="50px" height="50px" onClick={() => handleOpen(8)} />
                                            <span className="animationtext2">Loads</span>
                                            <span className="animationtext2">Consumption</span>
                                        </div>
                                        <div className="bottomcontent1">
                                            <span className="smalltext">1.<b className="boldtext">{(out_data['remote_load_1']) * 1000 + ''}</b>W</span>
                                            <span className="smalltext">2.<b className="boldtext">{(out_data['remote_load_2']) * 1000 + ''}</b>W</span>
                                            <span className="smalltext">3.<b className="boldtext">{(out_data['remote_load_3']) * 1000 + ''}</b>W</span>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="textcontainer">
                                <b className="mainbuildingtext" >Main Building</b>
                            </div>
                        </div>
                        <div className="metercontainer">
                            <Tooltip title="click on the image for more info">
                                <div className="meter1" id={'Meterd1'}>
                                    <img className="animationimage" src={(out_data['PV_power_kW'] - out_data['Load_power_kW']).toFixed(2) < 0 ? importing_meter : export_meter} width="60px" height="60px" onClick={() => handleOpen(9)} />
                                    <img className="lineinanimation1" src={vertical_line} height="60px" />
                                    <b className="animationtext2"> {(out_data['PV_power_kW'] - out_data['Load_power_kW']).toFixed(2) < 0 ? "Importing " + (Math.abs(out_data['PV_power_kW'] - out_data['Load_power_kW'])).toFixed(2) + ' kW from Grid' : "Exporting " + (Math.abs(out_data['PV_power_kW'] - out_data['Load_power_kW'])).toFixed(2) + ' kW to Grid'}</b>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="gridContent">
                            <Tooltip title="click on the image for more info">
                                <div className="GridInorOuttopcontent1" id={'grid1'}>
                                    <img className="animationbuilding" src={grid} width="50px" height="70px" onClick={() => handleOpen(2)} />
                                    <span className="animationtext2">Grid Power</span>
                                    <img className="lineinanimation" src={Line} width="70px" />
                                    <span className="smalltext"> <b className="boldtext">{(Math.abs(out_data['PV_power_kW'] - out_data['Load_power_kW'] - (out_data['Total_EV_power'] / 1000))).toFixed(2)}</b>kW</span>
                                </div>

                            </Tooltip>
                        </div>
                        <Tooltip title="click on the image for more info">
                            <div className="TriaconIntelligence">
                                <img id={'triaconint'} className="animationimage" src={triacon} width="100px" height="100px" onClick={() => handleOpen(3)} />
                                <span className="animationtext3">Total Potential Savings </span>
                                <span className="animationtext4"><b>23.47 MWh</b></span>
                            </div>
                        </Tooltip>

                        <div className="outsideMainArea">
                            <div className="carcontentcontainer" id={'carCharger1'}>
                                <div className="imagecontainerforcar">
                                    <Tooltip title="click on the image for more info">
                                        <div className="Car">
                                            <div className="topcontent">
                                                <img className="animationbuilding" src={carcharger} width="50px" height="50px" onClick={() => handleOpen(7)} />
                                                <span className="animationtext2">EV Charger</span>
                                            </div>
                                            <div className="bottomcontent">
                                                <span className="smalltext">PHASE 1 :<b className="boldtext">{(Math.abs(out_data['EV_power_phase_1'])) + ''}</b>W</span>
                                                <span className="smalltext">PHASE 2 :<b className="boldtext">{(Math.abs(out_data['EV_power_phase_2'])) + ''}</b>W</span>
                                                <span className="smalltext">PHASE 3 :<b className="boldtext">{(Math.abs(out_data['EV_power_phase_3'])) + ''}</b>W</span>
                                                <span className="smalltext">TOTAL   :<b className="boldtext">{(Math.abs(out_data['EV_power_phase_1']) + Math.abs(out_data['EV_power_phase_2']) + Math.abs(out_data['EV_power_phase_3'])) + ''}</b>W</span>
                                            </div>
                                        </div>
                                    </Tooltip>

                                </div>
                                <div className="textcontainer1">
                                    <b className="mainbuildingtext2">EV Substation</b>
                                </div>
                            </div>
                            <div className="batterymaincontainer">
                                <div className="batterycontainer">
                                    <Tooltip title="click on the image for more info">
                                        <div className="StorTower" id={'stortower'}>
                                            <div className="topcontent">
                                                <img className="animationbuilding" src={stortower} width="50px" height="50px" onClick={() => handleOpen(6)} />
                                                <span className="boldanimationtext2"><b>Stor</b>Tower</span>
                                            </div>
                                            <div className="bottomcontent">
                                                <span className="bigtext">{setstortowerstate}</span>
                                                <span className="smalltext">Capacity :<b className="boldtext">{out_data['Battery_Capacity_Percent']}</b>%</span>
                                                <span className="smalltext">Voltage :<b className="boldtext">{out_data['Battery_Voltage_V']}</b>V</span>
                                                <span className="smalltext">Currrent :<b className="boldtext">{(out_data['Battery_Current_A']) / 10}</b>A</span>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className="toptext">
                                    <b className="textinside">Battery System</b>
                                </div>
                            </div>
                        </div>
                        <div className="meter2container" >
                            <Tooltip title="click on the image for more info">
                                <div className="meter2" id={'Meterd2'}>
                                    <img className="animationimage" src={((Math.abs(out_data['EV_power_phase_1']) + Math.abs(out_data['EV_power_phase_2']) + Math.abs(out_data['EV_power_phase_3'])) + (out_data['Battery_Voltage_V'] * out_data['Battery_Current_A'] / 10) / 1000).toFixed(2) < 0 ? export_meter : importing_meter} width="60px" height="60px" onClick={() => handleOpen(10)} />
                                    <img className="lineinanimation1" src={vertical_line} height="60px" />
                                    <b className="animationtext2"> {(Math.abs(out_data['EV_power_phase_1']) + Math.abs(out_data['EV_power_phase_2']) + Math.abs(out_data['EV_power_phase_3'])) + (out_data['Battery_Voltage_V'] * out_data['Battery_Current_A'] / 10).toFixed(2) < 0 ? 'Using Excess ' + ((((Math.abs(out_data['EV_power_phase_1']) + Math.abs(out_data['EV_power_phase_2']) + Math.abs(out_data['EV_power_phase_3'])) / 1000) - (out_data['Battery_Voltage_V'] * (Math.abs(out_data['Battery_Current_A'] / 10))) / 1000)).toFixed(2) + '  kW' : 'Importing' + "        " + ((out_data['Total_EV_power'] / 1000) + (out_data['Battery_Voltage_V'] * (Math.abs(out_data['Battery_Current_A'] / 10))) / 1000).toFixed(2) + ' kW'}</b>
                                </div>
                            </Tooltip>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Button onClick={handleClose}>
                                    Close
                                </Button>

                                <h2 className="Title" id="TitleModal">{nameofdevice} - {today}</h2>
                                <ResponsiveContainer width="100%" aspect={3 / 1} >
                                    <AreaChart data={alldata} >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <GraphTooltip />
                                        <XAxis dataKey="time" interval={6} label={{ value: 'Time HH:MM', position: 'insideBottomRight', dy: 24, dx: -10, }} />
                                        {pvgraph ? <YAxis label={{ value: 'PV Generaion in kW', angle: -90, position: 'insideLeft', dy: 30, dx: 10, }} /> : null}
                                        {pvgraph ? <Area name="PV Generation (kW)" type="monotone" dataKey="PV_power_kW" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {buildingconsumption ? <YAxis label={{ value: 'Building Consumption (kW)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        {buildingconsumption ? <Area name="Building Consumption (kW)" type="monotone" dataKey="Load_power_kW" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {meter1graph ? <Area name="meter1 values (kW)" type="monotone" dataKey="Meter1" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {meter1graph ? <YAxis label={{ value: 'Meter 1 (kW)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} domain={[-100, 100]} /> : null}
                                        {gridPower ? <Area name="Grid Power (kW)" type="monotone" dataKey="Total_Grid" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {gridPower ? <YAxis label={{ value: 'Power from Grid (kW)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} domain={[-100, 100]} /> : null}
                                        {meter2graph ? <Area name="meter2 values (kW)" type="monotone" dataKey="Meter2" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {meter2graph ? <YAxis label={{ value: 'Meter 2 (kW)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        {RemoteLoadsgraph ? <Area name="Remote loads 1 (W)" type="monotone" dataKey="remote_load_1" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {RemoteLoadsgraph ? <Area name="Remote loads 2 (W)" type="monotone" dataKey="remote_load_2" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {RemoteLoadsgraph ? <Area name="Remote loads 3 (W)" type="monotone" dataKey="remote_load_3" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {RemoteLoadsgraph ? <YAxis label={{ value: 'Remote Loads (W)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        {stortowergraph ? <Area name="Stortower Battery Voltage (V)" type="monotone" dataKey="Battery_Voltage_V" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {stortowergraph ? <YAxis label={{ value: 'Stortower Battery Voltage (V)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        {triacongraph ? <Area name="Triacon Savings (kW)" type="monotone" dataKey="savings" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {triacongraph ? <YAxis label={{ value: 'Triacon Savings (kW)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        {carchargergraph ? <Area name="EV Charger Phase 1 (W)" type="monotone" dataKey="EV_power_phase_1" fillOpacity={0.1} stroke="#FF1A00" fill="#FF1A00" /> : null}
                                        {carchargergraph ? <Area name="EV Charger Phase 2 (W)" type="monotone" dataKey="EV_power_phase_2" fillOpacity={0.1} stroke="#0033FF" fill="#0033FF" /> : null}
                                        {carchargergraph ? <Area name="EV Charger Phase 3 (W)" type="monotone" dataKey="EV_power_phase_3" fillOpacity={0.1} stroke="#50FF00" fill="#50FF00" /> : null}
                                        {carchargergraph ? <YAxis label={{ value: 'EV Charger Phases and Total (W)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        {flowbatterygraph ? <Area name="Flow Battery Values (W)" type="monotone" dataKey="Total_EV_power" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                        {flowbatterygraph ? <YAxis label={{ value: 'FLowBattery Power (W)', angle: -90, position: 'insideBottomLeft', dy: -50, dx: 10, }} /> : null}
                                        <Legend label={{ fontFamily: 'Roboto' }} />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Box>
                        </Modal>

                        {importorexport.showXarrow ? <Xarrow start={importorexport.start} end={importorexport.end} color={importorexport.color} path={"straight"} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: importorexport.animation }} showHead={importorexport.showHead} strokeWidth={4} headSize={4} showTail={importorexport.showTail} /> : null}
                        {pvlines.showXarrow ? <Xarrow start={"solar1"} end={"BuildingImg"} startAnchor={"right"} endAnchor={"left"} color={pvlines.color} path={"straight"} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: pvlines.animation }} strokeWidth={4} headSize={4} showHead={pvlines.showHead} showTail={pvlines.showTail} /> : null}
                        {meter1andgrid.showXarrow ? <Xarrow start={meter1andgrid.start} end={meter1andgrid.end} color={meter1andgrid.color} path={"straight"} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: meter1andgrid.animation }} strokeWidth={4} headSize={4} showHead={meter1andgrid.showHead} showTail={meter1andgrid.showTail} /> : null}
                        {gridtometer2.showXarrow ? <Xarrow start={"grid1"} end={"Meterd2"} startAnchor={"right"} endAnchor={"left"} path={"straight"} color={gridtometer2.color} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: gridtometer2.animation }} strokeWidth={4} headSize={4} showHead={gridtometer2.showHead} showTail={gridtometer2.showTail} /> : null}
                        {remoteloadlines.showXarrow ? <Xarrow start={"BuildingImg"} end={"remoteloads"} startAnchor={"right"} endAnchor={"left"} color={remoteloadlines.color} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: remoteloadlines.animation }} strokeWidth={4} headSize={4} path='grid' showHead={remoteloadlines.showHead} showTail={remoteloadlines.showTail} /> : null}
                        {gridtometer2.showXarrow ? <Xarrow start={"Meterd2"} end={"carCharger1"} startAnchor={"bottom"} endAnchor={"top"} path={"straight"} color={gridtometer2.color} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: gridtometer2.animation }} strokeWidth={4} headSize={4} showHead={gridtometer2.showHead} showTail={gridtometer2.showTail} /> : null}
                        {stortowerlines.showXarrow ? <Xarrow start={stortowerlines.start} end={stortowerlines.end} color={stortowerlines.color} path={"straight"} dashness={{ strokeLen: 16, nonStrokeLen: 15, animation: stortowerlines.animation }} strokeWidth={4} headSize={4} showHead={stortowerlines.showHead} showTail={stortowerlines.showTail} /> : null}
                        {/*{data.stortowerlines.showXarrow ? <Xarrow start={"Meterd2"} end={"stortower"} color={data.stortowerlines.color} path={"grid"} dashness={{ animation: data.stortowerlines.animation }} showHead={data.stortowerlines.showHead} showTail={data.stortowerlines.showTail} /> : null}*/}
                    </div>
                </Xwrapper >
            </div>

            <Copyright />
        </div>
    )
}