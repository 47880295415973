import React from "react";
import { Col, Row } from "react-bootstrap";
//import Login from "./Login";
import SignIn from "./components/pages/SignIn";

export default function Account() {
  return (
    <Row>
      <SignIn />
    </Row >
  );
}
