import './datapage.css'
import React, { useEffect, useState } from "react";
//import { Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
//import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
//import { toBeEmpty } from '@testing-library/jest-dom/dist/matchers';
//import Chart from '../charts/chart'
//import AnimationDemo from './AnimationMain'
//import StortowerImage from '../animation/animationImages/Stortower.svg'
//import BuildingImage from '../animation/animationImages/Building.svg'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import Topbar from '../pagecomponents/TopBar';
//import Card from '../pagecomponents/Card';
//import { Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Copyright from '../pagecomponents/copyright';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    LineChart,
    //Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    //ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';
import { color } from '@mui/system';
import { SettingsBackupRestoreOutlined } from '@mui/icons-material';
//import { Block } from '@mui/icons-material';


const cookies = new Cookies();

// get token generated on login
const token = cookies.get("TOKEN");

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 950,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
}


export default function DataPage() {
    // set an initial state for the message we will receive after the API call
    //const [datadisplay, setMessage] = useState("");
    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [data, setData] = useState("");
    //const [output_data, setOutput] = useState("");
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const [totalCons, setTotalcons] = useState("0");
    const [totalProd, setTotalprod] = useState("0");
    const [totalEV, setTotalev] = useState("0");
    const [totalexp, setTotalexp] = useState("0");
    const [tableset, setTable] = useState(false);
    const [open, setOpen] = useState(false);
    const [pvGraph, setPvgraph] = useState(true);
    const [gridGraph, setGridgraph] = useState(true);
    const [consumptionGraph, setConsumptiongraph] = useState(true);
    const [evtotalGraph, setEvtotalgraph] = useState(true);
    const [evphase1Graph, setEvphase1graph] = useState(false);
    const [evphase2Graph, setEvphase2graph] = useState(false);
    const [evphase3Graph, setEvphase3graph] = useState(false);
    const [stortowervoltageGraph, setStortowervoltagegraph] = useState(false);
    const [stortoweramperageGraph, setStortoweramperagegraph] = useState(false);
    const [triaconsavings, setTriaconsavings] = useState(false);
    ///////
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    /////


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    const handleTable = () => {
        setTable(!tableset);
    };

    console.log(state[0]["startDate"]);
    console.log(state[0]["endDate"]);

    function createData(name, Value_in_kW) {
        return { name, Value_in_kW };
    }
    const rows = [
        createData('Total Generation', totalProd),
        createData('Total main building Consumption', totalCons),
        createData('Total EV Charger Consumption', totalEV),
        createData('Total Export to grid (+ is export/ - is import)', totalexp),

    ];

    const handleSwitches = (name) => {
        switch (name) {
            case 1:
                setPvgraph(prevCheck => !prevCheck);
                break;
            case 2:
                setGridgraph(prevCheck => !prevCheck)

                break;
            case 3:
                setConsumptiongraph(prevCheck => !prevCheck)

                break;
            case 4:
                setEvtotalgraph(prevCheck => !prevCheck)

                break;
            case 5:
                setEvphase1graph(prevCheck => !prevCheck)

                break;
            case 6:
                setEvphase2graph(prevCheck => !prevCheck)

                break;
            case 7:
                setEvphase3graph(prevCheck => !prevCheck)

                break;
            case 8:
                setStortowervoltagegraph(prevCheck => !prevCheck)

                break;
            case 9:
                setStortoweramperagegraph(prevCheck => !prevCheck)

                break;
            case 10:
                setTriaconsavings(prevCheck => !prevCheck)

        }
    };






    // useEffect automatically executes once the page is fully loaded
    useEffect(() => {
        // set configurations for the API call here

        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/table",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]

            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                setDisplay(output_data[0]["date/time"])

                var total_cons = 0;
                var total_gen = 0;
                var total_grid = 0;
                var total_ev = 0;

                console.log('lastdate', output_data[output_data.length - 1]["Grid_power_kW"])
                console.log('grid', output_data[output_data.length - 1]["date/time"])
                for (var i = 0; i < output_data.length; i++) {
                    total_grid += output_data[i]["Grid_power_kW"]
                    total_cons += output_data[i]["Load_power_kW"]
                    total_gen += output_data[i]["PV_power_kW"]
                    total_ev += output_data[i]["Total_EV_power"]
                    output_data[i]["Total_EV_kW"] = (Math.abs((output_data[i]["Total_EV_power"]) / 1000))
                    output_data[i]["EV_phase1_kW"] = (Math.abs((output_data[i]["EV_power_phase_1"]) / 1000))
                    output_data[i]["EV_phase2_kW"] = (Math.abs((output_data[i]["EV_power_phase_2"]) / 1000))
                    output_data[i]["EV_phase3_kW"] = (Math.abs((output_data[i]["EV_power_phase_3"]) / 1000))
                    output_data[i]["EV_phase3_kW"] = (Math.abs((output_data[i]["EV_power_phase_3"]) / 1000))
                    output_data[i]["Grid_power_kW"] = (output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"] - ((output_data[i]["Total_EV_power"]) / 1000)).toFixed(2)
                    output_data[i]["Battery_Voltage_V"] = output_data[i]["Battery_Voltage_V"] / 10
                    output_data[i]["Battery_Current_A"] = ((output_data[i]["Battery_Current_A"]) / 10)
                    if ((output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"]) > 0) {
                        output_data[i]["Triacon_savings"] = (output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"])
                    }
                    else {
                        output_data[i]["Triacon_savings"] = 0
                    }

                    output_data[i]["Load_power_kW"] = 0 - output_data[i]["Load_power_kW"]

                }
                setTotalcons(total_cons.toFixed(2))
                setTotalprod(total_gen.toFixed(2))
                setTotalev((total_ev / 1000).toFixed(2))
                setTotalexp((total_gen - total_cons).toFixed(2))

                setData(output_data)
            })
            .catch((error) => {
                error = new Error();
            });



    }, [state]);

    const day = new Date(datedisplay);
    const displayday = day.toLocaleDateString()
    //const convertedtime = new Date(data["date/time"]).toLocaleTimeString('it-IT')
    const maxvals = 0;
    for (let i = 0; i < data.length; i++) {
        const t = new Date(data[i]["date/time"]).toLocaleTimeString('it-IT')
        const day = new Date(data[i]["date/time"]).toLocaleDateString('en-US')
        data[i]["time"] = day + " " + t;
    }

    var Difference_In_Time = state[0]["endDate"].getTime() - state[0]["startDate"].getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    //const reverseddata = data.reverse();
    console.log(data)

    return (
        <div>
            <div className="DataPage">
                <Topbar />
                <div className="TopWrapper">
                    <div className='topaccordn'>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    General settings
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>Click here to select Graphs and Dates to Display</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <div className="DatePicker">
                                        <div className='datebutton'>
                                            <FormControlLabel control={<Switch checked={pvGraph} onChange={() => handleSwitches(1)} />} label="pv" />
                                            <FormControlLabel control={<Switch checked={gridGraph} onChange={() => handleSwitches(2)} />} label="Grid" />
                                            <FormControlLabel control={<Switch checked={consumptionGraph} onChange={() => handleSwitches(3)} />} label="Load" />
                                            <FormControlLabel control={<Switch checked={evtotalGraph} onChange={() => handleSwitches(4)} />} label="EV Total" />
                                            <FormControlLabel control={<Switch checked={evphase1Graph} onChange={() => handleSwitches(5)} />} label="EV Phase 1" />
                                            <FormControlLabel control={<Switch checked={evphase2Graph} onChange={() => handleSwitches(6)} />} label="EV Phase 2" />
                                            <FormControlLabel control={<Switch checked={evphase3Graph} onChange={() => handleSwitches(7)} />} label="EV Phase 3" />
                                            <FormControlLabel control={<Switch checked={stortowervoltageGraph} onChange={() => handleSwitches(8)} />} label="StorTower Voltage" />
                                            <FormControlLabel control={<Switch checked={stortoweramperageGraph} onChange={() => handleSwitches(9)} />} label="StorTower Current" />
                                            <FormControlLabel control={<Switch checked={triaconsavings} onChange={() => handleSwitches(10)} />} label="Triacon Savings" />
                                            <Button variant="contained" onClick={handleOpen}>Click to Select Date</Button>
                                        </div>
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/*<div className="SummaryData">
                        <div className='Topheader2'>
                            <div>
                                <Button onClick={handleTable}>
                                    {tableset ? <RemoveCircleIcon /> : <AddCircleIcon />}
                                </Button>
                            </div>
                            <div>
                                <h3 className='summaryDataHeader'>Summary for Selected Days</h3>
                            </div>
                        </div>
                        {tableset ? (<TableContainer component={Paper}>
                            <Table sx={{ minWidth: 600 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total for Selected Dates</TableCell>
                                        <TableCell align="right">Value</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.Value_in_kW + " kW"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>) : null}
                                    </div>*/}

                    <div className='chart'>
                        <ResponsiveContainer width="100%" aspect={3 / 1} >
                            <AreaChart data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <defs>
                                    <linearGradient id="colorLoad" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="time" tick={{ fontSize: 15 }} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottomLeft', dy: 24, dx: -10, fontFamily: 'Roboto, sans-serif' }} />
                                <YAxis domain={[-100, 100]} label={{ value: 'Values', angle: -90, position: 'insideLeft', dy: 30, dx: 10, fontFamily: 'Roboto, sans-serif' }} />
                                <Tooltip />
                                {gridGraph ? <Area name='Grid (kW)' type="monotone" dataKey="Grid_power_kW" fillOpacity={0.1} stroke="#EE2B02" fill="#EE2B02" /> : null}
                                {consumptionGraph ? <Area name='Load (kW)' type="monotone" dataKey="Load_power_kW" stroke="#8884d8" fillOpacity={1} fill="url(#colorLoad)" /> : null}
                                {pvGraph ? <Area name='PV (kW)' type="monotone" dataKey="PV_power_kW" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> : null}
                                {evtotalGraph ? <Area name='EV Charger Total (kW)' type="monotone" dataKey="Total_EV_kW" stroke="#ca82af" fillOpacity={0.1} fill="#ca82af" /> : null}
                                {evphase1Graph ? <Area name='EV Chager Phase 1 (kW)' type="monotone" dataKey="EV_phase1_kW" stroke="#62B650" fillOpacity={0.1} fill="#62B650" /> : null}
                                {evphase2Graph ? <Area name='EV Chager Phase 2 (kW)' type="monotone" dataKey="EV_phase2_kW" stroke="#82C674" fillOpacity={0.1} fill="#82C674" /> : null}
                                {evphase3Graph ? <Area name='EV Chager Phase 3 (kW)' type="monotone" dataKey="EV_phase3_kW" stroke="#26E13E" fillOpacity={0.1} fill="#26E13E" /> : null}
                                {stortowervoltageGraph ? <Area name='StorTower Voltage (V)' type="monotone" dataKey="Battery_Voltage_V" stroke="#15FAD3" fillOpacity={0.1} fill="#15FAD3" /> : null}
                                {stortoweramperageGraph ? <Area name='Stortower Current (A)' type="monotone" dataKey="Battery_Current_A" stroke="#31E4C6" fillOpacity={0.1} fill="#31E4C6" /> : null}
                                {triaconsavings ? <Area name='Triacon savings (kW)' type="monotone" dataKey="Triacon_savings" stroke="#31E4C6" fillOpacity={0.1} fill="#31E4C9" /> : null}
                                <Legend label={{ fontFamily: 'Roboto, sans-serif' }} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <Modal
                        hideBackdrop
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{ ...style, width: 950 }}>
                            <div className='Daterange'>
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 2)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className='InsideButton'>
                                <Button onClick={handleClose}>OK</Button>
                            </div>
                        </Box>
                    </Modal>
                </div>

            </div >


        </div>
    );
}




