import React, { useState } from "react";



export default function SwipeableTemporaryDrawer() {


    return (
        <div>
        </div>
    );
}