import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Bec_topappbar from "./bec_topappbar";
import { DataGrid } from "@mui/x-data-grid";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { CSVLink, } from "react-csv";
import "./bec_analytics_style.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
//import { Button } from "@mui/material";
import Copyright from "../pagecomponents/copyright";

const cookies = new Cookies();


const token = cookies.get("TOKEN");


export default function Bec_analytics() {
    const refreshDate = new Date();
    const [showClaender, setShowClaender] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [enddisplay, setEnddisplay] = useState("Select Days from the Calender for display");

    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [isSending, setIsSending] = useState(false)

    const handleCalender = () => {
        setShowClaender(!showClaender);
    }

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    refreshDate.setHours(0, 0, 0, 0);
    const [data, setData] = useState("");
    //const [output_data, setOutput] = useState("");
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Timestamp', headerName: 'Date', width: 200 },
        { field: 'Battery Voltage(V)', headerName: 'Battery Voltage(V)', width: 150 },
        { field: 'Battery Capacity(%)', headerName: 'Battery Capacity(%)', width: 150 },
        { field: 'Battery Current(A)', headerName: 'Battery Current(A)', width: 150 },
        { field: 'AC input voltage R(V)', headerName: 'AC input voltage R(V)', width: 200 },
        { field: 'AC Input Voltage S(V)', headerName: 'AC input voltage S(V)', width: 200 },
        { field: 'AC Input Voltage T(V)', headerName: 'AC input voltage T(V)', width: 200 },
        { field: 'AC input current R(A)', headerName: 'AC input current R(A)', width: 200 },
        { field: 'AC Input Current S(A)', headerName: 'AC input current S(A)', width: 200 },
        { field: 'AC Input Current T(A)', headerName: 'AC input current T(A)', width: 200 },
        { field: 'AC output frequency(Hz)', headerName: 'AC output frequency(Hz)', width: 200 },
        { field: 'Temperature Interne(°C)', headerName: 'Temperature Interne(°C)', width: 200 },
        { field: 'AC input active power R(kW)', headerName: 'AC input active power R(kW)', width: 250 },
        { field: 'AC input active power S(kW)', headerName: 'AC input active power S(kW)', width: 250 },
        { field: 'AC input active powerT(kW)', headerName: 'AC input active power T(kW)', width: 250 },
        { field: 'AC input total active power(kW)', headerName: 'AC input total active power(kW)', width: 250 },
        { field: 'power_phase_1', headerName: 'Phase 1(kW)', width: 250 },
        { field: 'power_phase_2', headerName: 'Phase 2(kW)', width: 250 },
        { field: 'power_phase_3', headerName: 'Phase 3(kW)', width: 250 },
        { field: 'Total_power', headerName: 'Phase Total(kW)', width: 250 },
    ];

    const rows = data
    const csvData = data

    useEffect(() => {
        // set configurations for the API call here

        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/bec_comb_data",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]

            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))
                for (let i = 0; i < output_data.length; i++) {
                    output_data[i]['power_phase_1'] = output_data[i]['power_phase_1'] / 1000
                    output_data[i]['power_phase_2'] = output_data[i]['power_phase_2'] / 1000
                    output_data[i]['power_phase_3'] = output_data[i]['power_phase_3'] / 1000
                    output_data[i]['Total_power'] = output_data[i]['Total_power'] / 1000

                }

                console.log("apicalled")
                console.log(output_data)

                setData(output_data)
                setDisplay(new Date(output_data[0]["date/time"]).toDateString('en-US'))
                setEnddisplay(new Date(output_data[output_data.length - 1]["date/time"]).toDateString('en-US'))
            })
            .catch((error) => {
                error = new Error();
            });
    }, [isSending]);

    const viewportHeight = window.innerHeight;

    return (
        <div>
            <Bec_topappbar />
            <div className="rawdataaccor2">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            General settings
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{"Selected Dates: " + datedisplay + " to " + enddisplay}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="accinside1">
                            <div className="insidedatapick2">
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2023 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className="selectordata">

                                <Typography>
                                    Make Changes and click Update Selection
                                </Typography>
                                <Button>
                                    <CSVLink filename={"bec_data.csv"} data={csvData}>Download data for selection</CSVLink>
                                </Button>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>

            <div className="grids2">
                <Box sx={{ height: viewportHeight * 0.7, width: '100%', marginTop: 10 }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[17]}
                        checkboxSelection
                    />
                </Box>
            </div>
        </div>
    )
}