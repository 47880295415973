import { Switch, Route, Redirect } from "react-router-dom";
import Account from "./Account";
import ProtectedRoutes from "./ProtectedRoutes";
import ProtectedRoutes2 from "./ProtectedRoutes2";
import ProtectedRoutes3 from "./ProtectedRoutes3";
import ProtectedRoutes4 from "./ProtectedRoutes4";
import ProtectedRoutes6 from "./ProtectedRoutes6";
import DataPage from "./components/pages/DataPage"
import AI from "./components/pages/Ai";
import AccountControl from "./components/pages/accountControl";
import Calculations from "./components/pages/calculations";
import SystemControl from "./components/pages/systemcontrol";
import RawData from "./components/pages/rawData";
import Storhubs from "./Storhubs"
import AccountHandler from "./AccountHandler";
import StorhubAnalytics from "./components/pages/StorhubAnalytics";
import StorhubControl from "./components/pages/StorhubControl";
import StorhubData from "./components/pages/StorhubData";
import Storhuberrors from "./components/pages/Storhuberrors";
import StorhubControlmain from "./components/pages/StorhubControlmain";
import Residentailhandler from "./Residentailhandler";
import Residential from "./components/pages/Residential";
import ResidentialData from "./components/pages/ResidentialData";
import Residentialpredictions from "./components/pages/Residentialpredictions";
import ResidentialControl from "./components/pages/Residentialcontrol";
import Residentialerrors from "./components/pages/Residentialerrors";
import Storhubmainpage from "./components/pages/Storhubmainpage";
import Pkchome from "./components/pages/Pkchome";
import Pkcgraphs from "./components/pages/Pkcgraphs";
//import AdminPage from "./components/pages/AdminPage";
import Adminprojectcontrol from "./components/pages/Adminprojectcontrol";
import Adminerrors from "./components/pages/Adminerrors";
import Profile from "./components/pages/Profile";
import Pkcupdates from "./components/pages/pkcupdates";
import BecAccount from "./BecAccount";
import Bec_home from "./components/bec_account/bec_home";
import Bec_analytics from "./components/bec_account/bec_analytics";
import Bec_graphs from "./components/bec_account/bec_graphs";
import Bec_logs from "./components/bec_account/bec_logs";
import AdminAccount from "./AdminAccount";
import ProtectedRoutes5 from "./ProtectedRoutes5";
import Adminmain from "./components/admin/Adminmain";
import Actionmain from "./components/actionporty/Actionmain";
import ActionPortyAccount from "./ActionPortyAccount";
import Actionanalysis from "./components/actionporty/Actionanalysis";
import ActionData from "./components/actionporty/ActionData";
import Actionlogs from "./components/actionporty/Actionlogs";



function App() {
  return (
    <div>


      {/* create routes here */}
      <Switch>
        <Route exact path="/" component={Account} />
        <Route exact path="/PKC" component={Account} />
        <Route exact path="/PFC" component={AccountHandler} />
        <Route exact path="/RES" component={Residentailhandler} />
        <Route exact path="/Repowering" component={BecAccount} />
        <Route exact path="/Administrator" component={AdminAccount} />
        <Route exact path="/Actionporty" component={ActionPortyAccount} />
        <ProtectedRoutes path="/pkchome" component={Pkchome} />
        <ProtectedRoutes path="/pkccharts" component={Pkcgraphs} />
        <ProtectedRoutes path="/components/pages" component={DataPage} />
        <ProtectedRoutes path="/AI" component={AI} />
        <ProtectedRoutes path="/account" component={AccountControl} />
        <ProtectedRoutes path="/Calculations" component={Calculations} />
        <ProtectedRoutes path="/systemControl" component={SystemControl} />
        <ProtectedRoutes path="/profile" component={Profile} />
        <ProtectedRoutes path="/rawData" component={RawData} />
        <ProtectedRoutes path='/pkclogs' component={Pkcupdates} />
        <ProtectedRoutes2 path="/storhubs" component={Storhubs} />
        <ProtectedRoutes2 path="/storhubanalytics" component={StorhubAnalytics} />
        <ProtectedRoutes2 path="/storhubcontrol" component={StorhubControl} />
        <ProtectedRoutes2 path="/storhubdata" component={StorhubData} />
        <ProtectedRoutes2 path="/storhuberrors" component={Storhuberrors} />
        <ProtectedRoutes2 path="/storhubcontrolmain" component={StorhubControlmain} />
        <ProtectedRoutes2 path="/home" component={Storhubmainpage} />
        <ProtectedRoutes3 path="/residentail" component={Residential} />
        <ProtectedRoutes3 path="/residentaildata" component={ResidentialData} />
        <ProtectedRoutes3 path="/residentialpredictions" component={Residentialpredictions} />
        <ProtectedRoutes3 path="/residentialcontrol" component={ResidentialControl} />
        <ProtectedRoutes3 path="/residentialerrors" component={Residentialerrors} />
        <ProtectedRoutes4 path="/home-repowering" component={Bec_home} />
        <ProtectedRoutes4 path='/analytics-repowering' component={Bec_analytics} />
        <ProtectedRoutes4 path='/graphs-repowering' component={Bec_graphs} />
        <ProtectedRoutes4 path='/logs-repowering' component={Bec_logs} />
        <ProtectedRoutes5 path='/admin-home' component={Adminmain} />
        <ProtectedRoutes5 exact path="/Admincontrol" component={Adminprojectcontrol} />
        <ProtectedRoutes5 exact path="/Adminerrors" component={Adminerrors} />
        <ProtectedRoutes6 exact path="/Actionportyhome" component={Actionmain} />
        <ProtectedRoutes6 exact path="/Actionanalysis" component={Actionanalysis} />
        <ProtectedRoutes6 exact path="/Actionportydata" component={ActionData} />
        <ProtectedRoutes6 exact path="/Actionportylogs" component={Actionlogs} />
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
    </div>
  );
}

export default App;
