import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import { DataGrid } from "@mui/x-data-grid";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { CSVLink, } from "react-csv";
import "./rawdata_style.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
//import { Button } from "@mui/material";
import Copyright from "../pagecomponents/copyright";
const cookies = new Cookies();


const token = cookies.get("TOKEN");



export default function RawData() {

    const refreshDate = new Date();
    const [showClaender, setShowClaender] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [enddisplay, setEnddisplay] = useState("Select Days from the Calender for display");
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [isSending, setIsSending] = useState(false)

    const handleCalender = () => {
        setShowClaender(!showClaender);
    }

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    refreshDate.setHours(0, 0, 0, 0);
    const [data, setData] = useState("");
    //const [output_data, setOutput] = useState("");
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'time', headerName: 'Date', width: 200 },
        { field: 'Load_power_kW', headerName: 'Building Cons KW', width: 130 },
        { field: 'PV_power_kW', headerName: 'PV Prod kW', width: 130 },
        { field: 'Grid_power_kW', headerName: 'Grid power kW', width: 130 },
        { field: 'Total_EV_power', headerName: 'Total EV W', width: 130 },
        { field: 'EV_power_phase_1', headerName: 'EV PHASE 1 W', width: 130 },
        { field: 'EV_power_phase_2', headerName: 'EV PHASE 2 W', width: 130 },
        { field: 'EV_power_phase_3', headerName: 'EV PHASE 3 W', width: 130 },
        { field: 'remote_load_1', headerName: 'REMOTE LOAD 1 kW', width: 130 },
        { field: 'remote_load_2', headerName: 'REMOTE LOAD 2 kW', width: 130 },
        { field: 'remote_load_3', headerName: 'REMOTE LOAD 3 kW', width: 130 },
        { field: 'AC_input_total_active_power_kW', headerName: 'input active power kW', width: 130 },
        { field: 'AC_output_active_power_R_kW', headerName: 'output active power kW', width: 130 },
        { field: 'Battery_Capacity_Percent', headerName: 'Battery %', width: 130 },
        { field: 'Battery_Current_A', headerName: 'Battery Current', width: 130 },
        { field: 'Battery_Voltage_V', headerName: 'battery Voltage', width: 130 },
    ];


    const rows = data
    const csvData = data

    useEffect(() => {
        // set configurations for the API call here

        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/table",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]

            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                console.log(output_data)
                setData(output_data)

                var total_cons = 0;
                var total_gen = 0;
                var total_grid = 0;
                var total_ev = 0;
                for (var i = 0; i < output_data.length; i++) {
                    total_grid += output_data[i]["Grid_power_kW"]
                    total_cons += output_data[i]["Load_power_kW"]
                    total_gen += output_data[i]["PV_power_kW"]
                    output_data[i]['EV_power_phase_1'] = Math.abs((output_data[i]['EV_power_phase_1']).toFixed(2));
                    output_data[i]['EV_power_phase_2'] = Math.abs((output_data[i]['EV_power_phase_2']).toFixed(2));
                    output_data[i]['EV_power_phase_3'] = Math.abs((output_data[i]['EV_power_phase_3']).toFixed(2));
                    output_data[i]['Total_EV_power'] = (Math.abs(output_data[i]['EV_power_phase_2']) + Math.abs(output_data[i]['EV_power_phase_1']) + Math.abs(output_data[i]['EV_power_phase_3'])).toFixed(2)
                    total_ev += output_data[i]["Total_EV_power"]
                    const t = new Date(output_data[i]["date/time"]).toLocaleTimeString('it-IT')
                    const day = new Date(output_data[i]["date/time"]).toLocaleDateString('en-US')
                    output_data[i]["time"] = day + " " + t;
                    output_data[i]["Total_EV_kW"] = (Math.abs((output_data[i]["Total_EV_power"]) / 1000)).toFixed(2)
                    output_data[i]["Battery_Current_A"] = ((output_data[i]["Battery_Current_A"] / 10)).toFixed(2)
                    output_data[i]["Grid_power_kW"] = (output_data[i]["PV_power_kW"] - output_data[i]["Load_power_kW"]).toFixed(2)
                    output_data[i]["id"] = output_data[i]["_id"]
                }
                setDisplay(new Date(output_data[0]["date/time"]).toDateString('en-US'))
                setEnddisplay(new Date(output_data[output_data.length - 1]["date/time"]).toDateString('en-US'))
            })
            .catch((error) => {
                error = new Error();
            });
    }, [isSending]);

    const viewportHeight = window.innerHeight;



    return (
        <div>
            <Topbar />

            <div className="rawdataaccor2">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            General settings
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{"Selected Dates: " + datedisplay + " to " + enddisplay}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="accinside1">
                            <div className="insidedatapick2">
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className="selectordata">

                                <Typography>
                                    Make Changes and click Update Selection
                                </Typography>
                                <Button>
                                    <CSVLink filename={"pkc_data.csv"} data={csvData}>Download data for selection</CSVLink>
                                </Button>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>

            <div className="Datagridpkc">
                <Box sx={{ height: viewportHeight * 0.7, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[17]}
                        checkboxSelection
                    />
                </Box>
            </div>
            <Copyright sx={{ mt: 1, mb: 1 }} />
        </div>
    )
}