import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "./components/pagecomponents/TopBar"
import { addDays, addHours } from 'date-fns';
//import { Button } from "@mui/material";
import Copyright from "./components/pagecomponents/copyright";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateRangePicker } from 'react-date-range';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import "./storhubs.css"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Radio from '@mui/material/Radio';
//import ResponsiveDrawer from "./components/pages/ResponsiveDrawer";
import Topappbar from "./components/pagecomponents/Topappbar";
import TextField from '@mui/material/TextField';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';
import { CSVLink, } from "react-csv";

const cookies = new Cookies();


const token = cookies.get("TOKEN");




export default function Storhubs() {
    const refreshDate = new Date();
    //const [selectedStorhub, setSelectstorhub] = useState('');
    refreshDate.setHours(0, 0, 0, 0);
    const [data, setData] = useState([]);
    const [download, setDownload] = useState('');
    const [preddate, setPredictiondate] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [device, setdevice] = React.useState("KMCC");
    const [urlend, setUrlend] = React.useState("storubkmccpreds");
    const [isSending, setIsSending] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const openselector = Boolean(anchorEl);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    const headers2 = [
        { label: "Timestamp", key: "Timestamp" },
        { label: "predictions_kW", key: "predictions_kW" },
        { label: "actual_kW", key: "actual_kW" },
    ];

    const handledevice = (name) => {
        switch (name) {
            case 1:
                setdevice("KMCC")
                setUrlend("storubkmccpreds")
                break;
            case 2:
                setdevice("Townhall")
                setUrlend("storubtownpreds")
                break;
        }
    };
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);


    useEffect(() => {
        // set configurations for the API call here

        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + urlend,
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const downdata = result.data
                setDownload(downdata)
                const output_data = result.data

                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                console.log(output_data)
                setData(output_data)

                const nextpred_date = new Date(data[1]['Timestamp']).toLocaleDateString('it-IT');
                setPredictiondate(nextpred_date)
                //console.log(nextpred_date)
                //console.log(data)

                console.log('data', typeof (obj));

            })
            .catch((error) => {
                error = new Error();
            });
    }, [isSending, urlend]);

    const startdd = new Date(state[0]["startDate"]).toDateString()
    const enddd = new Date(state[0]["endDate"]).toDateString()


    const obj = data;
    let outdata = Object.values(obj);
    const amounts = Math.max(...outdata.map(o => o.predictions_kW))

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    for (let i = 0; i < data.length; i++) {
        if (selectedValue === 'UTC/GMT') {
            const t = new Date(data[i]["Timestamp"])
            const day = new Date(data[i]["Timestamp"]).toUTCString()
            data[i]["localt"] = day;
        }
        else {
            const t = new Date(data[i]["Timestamp"]).toLocaleTimeString('it-IT')
            const day = new Date(data[i]["Timestamp"]).toLocaleDateString('en-US')
            data[i]["localt"] = t + ' ' + day;
        }

    }

    for (let i = 0; i < data.length; i++) {
        const t = new Date(data[i]["Timestamp"]).toLocaleTimeString(navigator.language, { hour: '2-digit' }) //navigator.language, { hour: '2-digit' }
        const day = new Date(data[i]["Timestamp"]).toLocaleDateString('en-US')
        data[i]["onlytime"] = t;
        data[i]["time"] = t + ' ' + day;
        data[i]["timezoned"] = new Date(data[i]["Timestamp"]).toLocaleString('en-US', { timeZone: 'America/Halifax' })
    }

    function getmaxtime(datain, maxamount) {
        for (let i = 0; i < datain.length; i++) {
            if (datain[i]["predictions_kW"] === maxamount) {
                if (selectedValue === 'UTC/GMT') {
                    return data[i]["localt"]
                }
                else {
                    return data[i]["localt"]
                }
            }
        }
    }

    function getTimestamp(datainto, maxval) {
        for (let i = 0; i < datainto.length; i++) {
            if (datainto[i]["predictions_kW"] === maxval) {
                const st = new Date(data[i]["Timestamp"])
                const starttime = st.setHours(st.getHours() - 1);
                const endtime = st.setHours(st.getHours() + 2);
                const stt = new Date(starttime).toUTCString(navigator.language, { hour: '2-digit' }, 'en-US', { timeZone: 'America/Halifax' })
                const edd = new Date(endtime).toUTCString(navigator.language, { hour: '2-digit' }, 'en-US', { timeZone: 'America/Halifax' })
                return <p>{stt} <br></br> <b>TO </b><br></br> {edd}</p>
            }
        }
    }

    function getonlytime(datatoin, maximum) {
        for (let i = 0; i < datatoin.length; i++) {
            if (datatoin[i]["predictions_kW"] === maximum) {
                return new Date(datatoin[i]["Timestamp"]).toUTCString(navigator.language, { hour: '2-digit' }, 'en-US', { timeZone: 'America/Halifax' })
            }
        }
    }

    function changetime(hours) {
        const maxt = getTimestamp(data, amounts)
        const dateCopy = new Date(maxt);
        dateCopy.setHours(dateCopy.getHours() + hours);
        return dateCopy;
    }

    function get_split(datatoin, maximum) {
        for (let i = 0; i < datatoin.length; i++) {
            if (datatoin[i]["predictions_kW"] === maximum) {
                const ttmp = datatoin[i]["time"].split(" ").pop();
                return ttmp;
            }
        }
    }





    const maxtime = getmaxtime(data, amounts)
    const timeonly = getonlytime(data, amounts)
    const maxt = getTimestamp(data, amounts)
    const splitted = get_split(data, amounts)


    console.log('to download', download)


    var Difference_In_Time = state[0]["endDate"].getTime() - state[0]["startDate"].getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)

    return (
        <div>
            <Topappbar />
            <Box component="div" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <h2 className="toptitle">BEC Demand Predictions from {startdd} to {enddd}</h2>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <span className="textchangeintop"><b>{"Active Site: " + device}</b></span>
                    <Button sx={{ marginLeft: 2, marginBottom: 2 }}
                        id="fade-button"
                        aria-controls={openselector ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openselector ? 'true' : undefined}
                        onClick={handleMenuClick}>Select Site</Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={openselector}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={() => handledevice(1)}>KMCC</MenuItem>
                        <MenuItem onClick={() => handledevice(2)}>Town-Hall</MenuItem>
                    </Menu>
                </Box>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Peak Demand
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {splitted}
                            </Typography>
                            <Typography variant="body2">
                                {amounts.toFixed(2) + ' kW'}
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Peak Time
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {splitted}
                            </Typography>
                            <Typography variant="body2">
                                {timeonly}
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Discharge Period for
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {splitted}
                            </Typography>
                            <Typography variant="body2">
                                <p>{maxt} </p>
                            </Typography>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignContent: 'center', marginBottom: 1, marginTop: 2, width: '100%' }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Change Date</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Button sx={{ marginBottom: 2 }} onClick={savedate}>Update selection</Button>
                            <div className="selecttimeformat">
                                <span>Set time format: </span>
                                LocalTime
                                <Radio
                                    checked={selectedValue === 'Localtime'}
                                    onChange={handleChange}
                                    value="Localtime"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}
                                />
                                (UTC/GMT)
                                <Radio
                                    checked={selectedValue === 'UTC/GMT'}
                                    onChange={handleChange}
                                    value="UTC/GMT"
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <Box >
                                        <Card sx={{ minWidth: 300 }}>
                                            <DateRangePicker
                                                color='#f7fef7'
                                                onChange={item => setState([item.selection])}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={state}
                                                minDate={new Date("April 1, 2022 00:00:00")}
                                                maxDate={addDays(new Date(), 3)}
                                                direction="horizontal"
                                            />
                                        </Card>
                                    </Box>
                                </Box>
                            </Box>
                            <div className="conte">
                                <CSVLink filename={"Prediction_data_selected.csv"} headers={headers2} data={download}>Download Actual & Prediction data for selection</CSVLink>
                            </div>

                        </AccordionDetails>
                    </Accordion>

                </Box>
                <div className="chartholder">
                    <h7 className='headingconact'>Predicted VS. Actual Consumption</h7>
                    <ResponsiveContainer width="100%" aspect={4 / 1}>
                        <AreaChart data={data}
                            margin={{ top: 20, right: 60, left: 10, bottom: 10 }}>
                            <XAxis dataKey="localt" interval={Difference_In_Days * 5} tick={{ fontSize: 15 }} label={{ value: 'Format in HH:MM:SS MM/DD/YYYY', position: 'insideBottom', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                            <YAxis tick={{ fontSize: 15 }} label={{ value: 'Generation in kW', angle: -90, dy: 50, dx: -6, position: 'insideLeft', fontFamily: 'Roboto, sans-serif' }} />
                            <ReferenceLine y={amounts} label="Max Demand" stroke="red" strokeDasharray="3 3" />
                            <ReferenceLine x={maxtime} stroke="red" label="Max Demand Time" />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="predictions_kW" stroke="#02C82C" fillOpacity={0.1} fill="#02C82C" />
                            <Area type="monotone" dataKey="actual_kW" stroke="#0B3BEC" fillOpacity={0.1} fill="#0B3BEC" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Box>


            <Copyright sx={{ mt: 1, mb: 1 }} />
        </div >


    );
}