import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import { Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const filter = createFilterOptions();

const cookies = new Cookies();


const token = cookies.get("TOKEN");

const query_commands = [{ label: ' Protocol ID', Command_Type: 'no_input', Command_Value: 'P003PI' },
{ label: 'Series number', Command_Type: 'no_input', Command_Value: 'P003ID' },
{ label: 'Cpu version', Command_Type: 'no_input', Command_Value: 'P004VFW' },
{ label: 'Secondary cpu', Command_Type: 'no_input', Command_Value: 'P005VFW2' },
{ label: 'Device model', Command_Type: 'no_input', Command_Value: 'P003MD' },
{ label: 'Rated information', Command_Type: 'no_input', Command_Value: 'P005PIRI' },
{ label: 'General status', Command_Type: 'no_input', Command_Value: 'P003GS' },
{ label: 'Power status', Command_Type: 'no_input', Command_Value: 'P003PS' },
{ label: 'Working mode', Command_Type: 'no_input', Command_Value: 'P004MOD' },
{ label: 'Warning status', Command_Type: 'no_input', Command_Value: 'P003WS' },
{ label: 'Enable disable flag status', Command_Type: 'no_input', Command_Value: 'P005FLAG' },
{ label: 'Current time', Command_Type: 'no_input', Command_Value: 'P002T' },
{ label: 'Total generated Energy', Command_Type: 'no_input', Command_Value: 'P003ET' },
{ label: 'Generated energy of year', Command_Type: 'yyyynnn', Command_Value: 'P010EY' },
{ label: 'Generated energy of month', Command_Type: 'yyyymmnnn', Command_Value: 'P012EM' },
{ label: 'Generated energy of day', Command_Type: 'yyyymmddnnn', Command_Value: 'P014ED' },
{ label: 'Generated energy of hour', Command_Type: 'yyyymmddhhnnn', Command_Value: 'P016EH' },
{ label: 'AC input voltage acceptable range for feed power', Command_Type: 'no_input', Command_Value: 'P004GOV' },
{ label: 'AC input frequency acceptable range for feed power', Command_Type: 'no_input', Command_Value: 'P004GOF' },
{ label: 'Maximum output power for feeding grid', Command_Type: 'no_input', Command_Value: 'P005GPMP' },
{ label: 'solar input mppt acceptable range', Command_Type: 'no_input', Command_Value: 'P006MPPTV' },
{ label: 'solar input voltage acceptable range', Command_Type: 'no_input', Command_Value: 'P003SV' },
{ label: 'lcd sleep wait time', Command_Type: 'no_input', Command_Value: 'P004LST' },
{ label: 'default value of changable parameter', Command_Type: 'no_input', Command_Value: 'P003DI' },
{ label: 'battery_setting', Command_Type: 'no_input', Command_Value: 'P005BATS' },
{ label: 'machine_model', Command_Type: 'no_input', Command_Value: 'P003DM' },
{ label: 'machine_adjustable_range', Command_Type: 'no_input', Command_Value: 'P004MAR' },
{ label: 'current_fault_status', Command_Type: 'no_input', Command_Value: 'P004CFS' },
{ label: 'history_fault_parameter', Command_Type: 'nn', Command_Value: 'P006HFS' },
{ label: 'energy_control_status', Command_Type: 'no_input', Command_Value: 'P005HECS' },
{ label: 'ac_input_long_time_highest_average_voltage', Command_Type: 'no_input', Command_Value: 'P006GLTHV' },
{ label: 'first_generated_energy_saved_time', Command_Type: 'no_input', Command_Value: 'P004FET' },
{ label: 'wait_time_for_feed_power', Command_Type: 'no_input', Command_Value: 'P003FT' },
{ label: 'ac_charge_time_bucket', Command_Type: 'no_input', Command_Value: 'P005ACCT' },
{ label: 'ac_supply_load_time_bucket', Command_Type: 'no_input', Command_Value: 'P005ACLT' },
{ label: 'feeding_grid_power_calibration', Command_Type: 'no_input', Command_Value: 'P006FPADJ' },
{ label: 'feed_in_power_factor', Command_Type: 'no_input', Command_Value: 'P006FPPF' },
{ label: 'auto_adjust_pf_with_power_info', Command_Type: 'no_input', Command_Value: 'P005AAPF' },
{ label: 'feed_in_grid_reactive_power', Command_Type: 'no_input', Command_Value: 'P005FPRA' },
{ label: 'enable_disable_machine_supply_power_to_loads', Command_Type: 'n', Command_Value: 'S005LON' },
{ label: 'set_enable_disable_status', Command_Type: 'mn', Command_Value: 'S004P' },
{ label: 'set_date_time', Command_Type: 'yymmddhhffss', Command_Value: 'S016DAT' },
{ label: 'set_ac_input_highest_voltage_for_feeding_power', Command_Type: 'nnnn', Command_Value: 'S009GOHV' },
{ label: 'set_ac_input_lowest_voltage_for_fedding_power', Command_Type: 'nnnn', Command_Value: 'S009GOLV' },
{ label: 'set_ac_input_highest_frequency_for_feeding_power', Command_Type: 'nnnn', Command_Value: 'S009GOHF' },
{ label: 'set_ac_input_lowest_frequency_for_fedding_power', Command_Type: 'nnnn', Command_Value: 'S009GOLF' },
{ label: 'set_max_power_for_feeding_grid', Command_Type: 'nnnnnn', Command_Value: 'S011GPMP' },
{ label: 'set_solar_input_highest_voltage', Command_Type: 'nnnn', Command_Value: 'S009SIHV' },
{ label: 'set_solar_input_lowest_voltage', Command_Type: 'nnnn', Command_Value: 'S009SILV' },
{ label: 'set_solar_input_highest_mppt_voltage', Command_Type: 'nnnn', Command_Value: 'S011MPPTHV' },
{ label: 'set_solar_input_lowest_mppt_voltage', Command_Type: 'nnnn', Command_Value: 'S011MPPTLV' },
{ label: 'set_LCD_sleep_wait_time', Command_Type: 'nn', Command_Value: 'S006LST' },
{ label: 'set_battery_max_charge_current', Command_Type: 'nnnn', Command_Value: 'S010MCHGC' },
{ label: 'set_battery_max_charge_voltage', Command_Type: 'mmmm,nnnn', Command_Value: 'S015MCHGV' },
{ label: 'set_ac_input_long_time_highest_average_voltage', Command_Type: 'nnnn', Command_Value: 'S010GLTHV' },
{ label: 'set_battery_discharge_voltage', Command_Type: 'aaaa,bbbb,cccc,dddd', Command_Value: 'S025BATDV' },
{ label: 'set_solar_energy_distribution_of_priority', Command_Type: 'nn', Command_Value: 'S006SEP' },
{ label: 'set_energy_distribution', Command_Type: 'mn', Command_Value: 'S005ED' },
{ label: 'set_battery_charger_application_in_floating_charging', Command_Type: 'aaaa,bbb,cccc', Command_Value: 'S017BCA' },
{ label: 'set_machine_model', Command_Type: 'nnn', Command_Value: 'S006DM' },
{ label: 'set_changable_parameter_restore_to_default_value', Command_Type: 'no_input', Command_Value: 'S003PF' },
{ label: 'set_ac_output_frequency_to_be_50_hz', Command_Type: 'no_input', Command_Value: 'S004F50' },
{ label: 'set_ac_output_frequency_to_be_60_hz', Command_Type: 'no_input', Command_Value: 'S004F60' },
{ label: 'set_ac_output_rated_voltage', Command_Type: 'nnnn', Command_Value: 'S006V' },
{ label: 'set_wait_time_for_feed_power', Command_Type: 'nnn', Command_Value: 'S006FT' },
{ label: 'set_ac_charge_time_bucket', Command_Type: 'aaaa,bbbb,cccc,dddd', Command_Value: 'S014ACCT' },
{ label: 'set_ac_supply_load_time_bucket', Command_Type: 'aaaa,bbbb', Command_Value: 'S014ACLT' },
{ label: 'set_battery_type', Command_Type: 'n', Command_Value: 'S004BT' },
{ label: 'set_battery_install_time', Command_Type: 'yymmddhhffss', Command_Value: 'S016BIT' },
{ label: 'li_fe_battery_self_test_by_charged_at_a_time', Command_Type: 'no_input', Command_Value: 'S009BST' },
{ label: 'ac_charge_keep_battery_voltage_setting', Command_Type: 'a,bbbb', Command_Value: 'S016ACCB' },
{ label: 'battery_temprature_sensor_compensation', Command_Type: 'nnn', Command_Value: 'S007BTS' },
{ label: 'max_ac_charging_current_from_ac', Command_Type: 'nnnn', Command_Value: 'S011MUCHGC' },
{ label: 'feeding_grid_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S012FPADJ' },
{ label: 'battery_discharge_max_current_in_hybrid_mode', Command_Type: 'nnnn', Command_Value: 'S009BDCM' },
{ label: 'set_feedin_power_factor', Command_Type: 'nnn', Command_Value: 'S008FPPF' },
{ label: 'enable_disable_parellel_for_output', Command_Type: 'n', Command_Value: 'S006PALE' },
{ label: 'r_phass_feeding_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S013FPRADJ' },
{ label: 's_phass_feeding_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S013FPSADJ' },
{ label: 't_phass_feeding_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S013FPTADJ' },
{ label: 'auto_adjust_pf_with_power', Command_Type: 'a,bbb,ccc', Command_Value: 'S014AAPF' },
{ label: 'set_feed_in_reactive_power', Command_Type: 'nnnn', Command_Value: 'S010FPRA' },
];



export default function AccountControl() {

    const [accesstoken, setaccesstoken] = React.useState("");
    const [output, setOutput] = useState("");
    const [commandtosend, setCommandtosend] = useState("");


    useEffect(() => {
        const configuration = {
            method: "post",
            url: "https://pkc-tower-backend.herokuapp.com/api/auth/login",
            //url: "http://localhost:3000/chartdata",
            data: {
                "email": "labunehewa@gmail.com",
                "password": "storTera12!"
            }
        };
        axios(configuration)
            .then((result) => {
                setaccesstoken(result.data.access_token)
            })
            .catch((error) => {
                error = new Error();
            });
    }, []);


    const sendcommand = () => {
        const configuration = {
            method: "get",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/testcommand",
            //url: "http://localhost:3000/chartdata",
            data: {
                "access_token": accesstoken,
                "command": commandtosend
            }
        };
        axios(configuration)
            .then((result) => {
                console.log(result.data.status)
                setOutput(result.data.status)
            })
            .catch((error) => {
                error = new Error();
            });
    }




    return (
        <div>
            <Topbar />
            <div className="TopAccountWrapper">
                <span>{commandtosend}</span>
                <TextField sx={{ m: 2 }} id="filled-basic" label="Set Command" variant="filled" value={commandtosend}
                    onChange={(commandtosend) => setCommandtosend(commandtosend.target.value)}
                />
                <Button onClick={() => sendcommand()}>Send Command</Button>
                <span>{output}</span>
            </div>
            <TableContainer component={Paper} sx={{ width: 800 }}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Command name</TableCell>
                            <TableCell align="right">Input</TableCell>
                            <TableCell align="right">Raw Command</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {query_commands.map((row) => (
                            <TableRow
                                key={row.label}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell align="left">{row.label}</TableCell>
                                <TableCell align="right">{row.Command_Type}</TableCell>
                                <TableCell align="right">{row.Command_Value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}