import React from "react";
import { Col, Row } from "react-bootstrap";
//import Login from "./Login";
import Actionlogin from "./components/actionporty/Actionlogin"

export default function ActionPortyAccount() {
    return (
        <Row>
            <Actionlogin />
        </Row >
    );
}