import React from "react";
import { Col, Row } from "react-bootstrap";
//import Login from "./Login";
import Adminlogin from "./components/admin/Adminlogin";


export default function AdminAccount() {
    return (
        <Row>
            <Adminlogin />
        </Row >
    );
}