import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";

const cookies = new Cookies();


const token = cookies.get("TOKEN");

export default function Calculations() {

    return (
        <div>
            <Topbar />
            <div>
                Welcome to Calculations page
            </div>
        </div>
    )
}