import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import SwipeableTemporaryDrawer from "../pagecomponents/SwipableDrawer";

const cookies = new Cookies();


const token = cookies.get("TOKEN");

export default function AI() {

    return (
        <div>
            <Topbar />
            <div>
                <SwipeableTemporaryDrawer />
                AI NOT AVAILABLE
            </div>

        </div>
    )
}