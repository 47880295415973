import React from "react";
import { Col, Row } from "react-bootstrap";
//import Login from "./Login";
import Storhubsignin from "./components/pages/Storhubsignin"

export default function AccountHandler() {
    return (
        <Row>
            <Storhubsignin />
        </Row >
    );
}
