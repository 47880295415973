import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Actiontopbar from "./Actiontopbar";
import { DataGrid } from "@mui/x-data-grid";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { CSVLink, } from "react-csv";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';

const cookies = new Cookies();


const token = cookies.get("TOKEN");

export default function ActionData() {
    const refreshDate = new Date();
    const [showClaender, setShowClaender] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [enddisplay, setEnddisplay] = useState("Select Days from the Calender for display");

    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [isSending, setIsSending] = useState(false)

    const handleCalender = () => {
        setShowClaender(!showClaender);
    }

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    refreshDate.setHours(0, 0, 0, 0);
    const [data, setData] = useState("");
    //const [output_data, setOutput] = useState("");
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Timestamp', headerName: 'Date', width: 300 },
        { field: 'connecting_from', headerName: 'Connecting From', width: 130 },
        { field: 'connecting_to', headerName: 'Connecting to', width: 130 },
        { field: 'Grid_power_kW', headerName: 'Grid Power', width: 130 },
        { field: 'Gird_status', headerName: 'Grid Status', width: 130 },
        { field: 'Load_status', headerName: 'Load Status', width: 130 },
        { field: 'Load_power_kW', headerName: 'Load Power', width: 130 },
        { field: 'PV_status', headerName: 'PV Status', width: 130 },

        { field: 'Grid rating current(A)', headerName: 'Grid rating current(A)', width: 330 },
        { field: 'Grid rating frequency(Hz)', headerName: 'Grid rating frequency(Hz)', width: 330 },
        { field: 'Grid rating voltage(V)', headerName: 'Grid rating voltage(V)', width: 330 },
        { field: 'Battery rating voltage(A)', headerName: 'Battery rating voltage(V)', width: 330 },
        { field: 'Total Energy(kWh)', headerName: 'Total Energy(kWh)', width: 330 },
        { field: 'Grid voltage R(V)', headerName: 'Grid voltage R(V)', width: 330 },

        { field: 'Grid power R(W)', headerName: 'Grid power R(W)', width: 330 },
        { field: 'Grid Frequency(Hz)', headerName: 'Grid Frequency(Hz)', width: 330 },
        { field: 'Grid Current(A)', headerName: 'Grid Current(A)', width: 330 },
        { field: 'AC output power R(W)', headerName: 'AC output power R(W)', width: 330 },
        { field: 'AC Output Frequency(Hz)', headerName: 'AC Output Frequency(Hz)', width: 330 },
        { field: 'P BUS Voltage(V)', headerName: 'P BUS Voltage(V)', width: 330 },

        { field: 'S BUS Voltage(V)', headerName: 'S BUS Voltage(V)', width: 330 },
        { field: 'P Battery Voltage(V)', headerName: 'P Battery Voltage(V)', width: 330 },
        { field: 'Battery Capacity(%)', headerName: 'Battery Capacity(%)', width: 330 },
        { field: 'Temperature(°C)', headerName: 'Temperature(°C)', width: 330 },
        { field: 'Battery Status', headerName: 'Battery Status', width: 330 },
        { field: 'Inv Energy Flow Direction', headerName: 'Inv Energy Flow Direction', width: 330 },
        { field: 'AC Output Current R(A)', headerName: 'AC Output Current R(A)', width: 330 },

    ];

    const rows = data
    const csvData = data

    useEffect(() => {
        // set configurations for the API call here

        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/action_data_giv_time",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]

            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))

                console.log("apicalled")
                console.log(output_data)
                setData(output_data)
                setDisplay(new Date(output_data[0]["Timestamp"]).toDateString('en-US'))
                setEnddisplay(new Date(output_data[output_data.length - 1]["Timestamp"]).toDateString('en-US'))
            })
            .catch((error) => {
                error = new Error();
            });
    }, [isSending]);

    const viewportHeight = window.innerHeight;




    return (
        <div>
            <Actiontopbar />
            <div className="rawdataaccor2">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            General settings
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{"Selected Dates: " + datedisplay + " to " + enddisplay}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="accinside1">
                            <div className="insidedatapick2">
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className="selectordata">

                                <Typography>
                                    Make Changes and click Update Selection
                                </Typography>
                                <Button>
                                    <CSVLink filename={"action porty data.csv"} data={csvData}>Download data for selection</CSVLink>
                                </Button>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>

            <div className="Datagridpkc">
                <Box sx={{ height: viewportHeight * 0.7, width: '100%', marginTop: 10 }}>
                    <DataGrid
                        getRowId={(row) => row._id}
                        rows={rows}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[17]}
                        checkboxSelection
                    />
                </Box>
            </div>

        </div>
    )
}