import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Topbar from "../pagecomponents/TopBar";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import "./systemcontol_style.css";

const cookies = new Cookies();


const token = cookies.get("TOKEN");

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const rows = [{ id: 1, label: ' Protocol ID', Command_Type: 'no_input', Command_Value: 'P003PI' },
{ id: 2, label: 'Series number', Command_Type: 'no_input', Command_Value: 'P003ID' },
{ id: 3, label: 'Cpu version', Command_Type: 'no_input', Command_Value: 'P004VFW' },
{ id: 4, label: 'Secondary cpu', Command_Type: 'no_input', Command_Value: 'P005VFW2' },
{ id: 5, label: 'Device model', Command_Type: 'no_input', Command_Value: 'P003MD' },
{ id: 6, label: 'Rated information', Command_Type: 'no_input', Command_Value: 'P005PIRI' },
{ id: 7, label: 'General status', Command_Type: 'no_input', Command_Value: 'P003GS' },
{ id: 8, label: 'Power status', Command_Type: 'no_input', Command_Value: 'P003PS' },
{ id: 9, label: 'Working mode', Command_Type: 'no_input', Command_Value: 'P004MOD' },
{ id: 10, label: 'Warning status', Command_Type: 'no_input', Command_Value: 'P003WS' },
{ id: 11, label: 'Enable disable flag status', Command_Type: 'no_input', Command_Value: 'P005FLAG' },
{ id: 12, label: 'Current time', Command_Type: 'no_input', Command_Value: 'P002T' },
{ id: 13, label: 'Total generated Energy', Command_Type: 'no_input', Command_Value: 'P003ET' },
{ id: 14, label: 'Generated energy of year', Command_Type: 'yyyynnn', Command_Value: 'P010EY' },
{ id: 15, label: 'Generated energy of month', Command_Type: 'yyyymmnnn', Command_Value: 'P012EM' },
{ id: 16, label: 'Generated energy of day', Command_Type: 'yyyymmddnnn', Command_Value: 'P014ED' },
{ id: 17, label: 'Generated energy of hour', Command_Type: 'yyyymmddhhnnn', Command_Value: 'P016EH' },
{ id: 18, label: 'AC input voltage acceptable range for feed power', Command_Type: 'no_input', Command_Value: 'P004GOV' },
{ id: 19, label: 'AC input frequency acceptable range for feed power', Command_Type: 'no_input', Command_Value: 'P004GOF' },
{ id: 20, label: 'Maximum output power for feeding grid', Command_Type: 'no_input', Command_Value: 'P005GPMP' },
{ id: 21, label: 'solar input mppt acceptable range', Command_Type: 'no_input', Command_Value: 'P006MPPTV' },
{ id: 22, label: 'solar input voltage acceptable range', Command_Type: 'no_input', Command_Value: 'P003SV' },
{ id: 23, label: 'lcd sleep wait time', Command_Type: 'no_input', Command_Value: 'P004LST' },
{ id: 24, label: 'default value of changable parameter', Command_Type: 'no_input', Command_Value: 'P003DI' },
{ id: 25, label: 'battery_setting', Command_Type: 'no_input', Command_Value: 'P005BATS' },
{ id: 26, label: 'machine_model', Command_Type: 'no_input', Command_Value: 'P003DM' },
{ id: 27, label: 'machine_adjustable_range', Command_Type: 'no_input', Command_Value: 'P004MAR' },
{ id: 28, label: 'current_fault_status', Command_Type: 'no_input', Command_Value: 'P004CFS' },
{ id: 29, label: 'history_fault_parameter', Command_Type: 'nn', Command_Value: 'P006HFS' },
{ id: 30, label: 'energy_control_status', Command_Type: 'no_input', Command_Value: 'P005HECS' },
{ id: 31, label: 'ac_input_long_time_highest_average_voltage', Command_Type: 'no_input', Command_Value: 'P006GLTHV' },
{ id: 32, label: 'first_generated_energy_saved_time', Command_Type: 'no_input', Command_Value: 'P004FET' },
{ id: 33, label: 'wait_time_for_feed_power', Command_Type: 'no_input', Command_Value: 'P003FT' },
{ id: 34, label: 'ac_charge_time_bucket', Command_Type: 'no_input', Command_Value: 'P005ACCT' },
{ id: 35, label: 'ac_supply_load_time_bucket', Command_Type: 'no_input', Command_Value: 'P005ACLT' },
{ id: 36, label: 'feeding_grid_power_calibration', Command_Type: 'no_input', Command_Value: 'P006FPADJ' },
{ id: 37, label: 'feed_in_power_factor', Command_Type: 'no_input', Command_Value: 'P006FPPF' },
{ id: 38, label: 'auto_adjust_pf_with_power_info', Command_Type: 'no_input', Command_Value: 'P005AAPF' },
{ id: 39, label: 'feed_in_grid_reactive_power', Command_Type: 'no_input', Command_Value: 'P005FPRA' },
{ id: 40, label: 'enable_disable_machine_supply_power_to_loads', Command_Type: 'n', Command_Value: 'S005LON' },
{ id: 41, label: 'set_enable_disable_status', Command_Type: 'mn', Command_Value: 'S004P' },
{ id: 42, label: 'set_date_time', Command_Type: 'yymmddhhffss', Command_Value: 'S016DAT' },
{ id: 43, label: 'set_ac_input_highest_voltage_for_feeding_power', Command_Type: 'nnnn', Command_Value: 'S009GOHV' },
{ id: 44, label: 'set_ac_input_lowest_voltage_for_fedding_power', Command_Type: 'nnnn', Command_Value: 'S009GOLV' },
{ id: 45, label: 'set_ac_input_highest_frequency_for_feeding_power', Command_Type: 'nnnn', Command_Value: 'S009GOHF' },
{ id: 46, label: 'set_ac_input_lowest_frequency_for_fedding_power', Command_Type: 'nnnn', Command_Value: 'S009GOLF' },
{ id: 47, label: 'set_max_power_for_feeding_grid', Command_Type: 'nnnnnn', Command_Value: 'S011GPMP' },
{ id: 48, label: 'set_solar_input_highest_voltage', Command_Type: 'nnnn', Command_Value: 'S009SIHV' },
{ id: 49, label: 'set_solar_input_lowest_voltage', Command_Type: 'nnnn', Command_Value: 'S009SILV' },
{ id: 50, label: 'set_solar_input_highest_mppt_voltage', Command_Type: 'nnnn', Command_Value: 'S011MPPTHV' },
{ id: 51, label: 'set_solar_input_lowest_mppt_voltage', Command_Type: 'nnnn', Command_Value: 'S011MPPTLV' },
{ id: 52, label: 'set_LCD_sleep_wait_time', Command_Type: 'nn', Command_Value: 'S006LST' },
{ id: 53, label: 'set_battery_max_charge_current', Command_Type: 'nnnn', Command_Value: 'S010MCHGC' },
{ id: 54, label: 'set_battery_max_charge_voltage', Command_Type: 'mmmm,nnnn', Command_Value: 'S015MCHGV' },
{ id: 55, label: 'set_ac_input_long_time_highest_average_voltage', Command_Type: 'nnnn', Command_Value: 'S010GLTHV' },
{ id: 56, label: 'set_battery_discharge_voltage', Command_Type: 'aaaa,bbbb,cccc,dddd', Command_Value: 'S025BATDV' },
{ id: 57, label: 'set_solar_energy_distribution_of_priority', Command_Type: 'nn', Command_Value: 'S006SEP' },
{ id: 58, label: 'set_energy_distribution', Command_Type: 'mn', Command_Value: 'S005ED' },
{ id: 59, label: 'set_battery_charger_application_in_floating_charging', Command_Type: 'aaaa,bbb,cccc', Command_Value: 'S017BCA' },
{ id: 60, label: 'set_machine_model', Command_Type: 'nnn', Command_Value: 'S006DM' },
{ id: 61, label: 'set_changable_parameter_restore_to_default_value', Command_Type: 'no_input', Command_Value: 'S003PF' },
{ id: 62, label: 'set_ac_output_frequency_to_be_50_hz', Command_Type: 'no_input', Command_Value: 'S004F50' },
{ id: 63, label: 'set_ac_output_frequency_to_be_60_hz', Command_Type: 'no_input', Command_Value: 'S004F60' },
{ id: 64, label: 'set_ac_output_rated_voltage', Command_Type: 'nnnn', Command_Value: 'S006V' },
{ id: 65, label: 'set_wait_time_for_feed_power', Command_Type: 'nnn', Command_Value: 'S006FT' },
{ id: 66, label: 'set_ac_charge_time_bucket', Command_Type: 'aaaa,bbbb,cccc,dddd', Command_Value: 'S014ACCT' },
{ id: 67, label: 'set_ac_supply_load_time_bucket', Command_Type: 'aaaa,bbbb', Command_Value: 'S014ACLT' },
{ id: 68, label: 'set_battery_type', Command_Type: 'n', Command_Value: 'S004BT' },
{ id: 69, label: 'set_battery_install_time', Command_Type: 'yymmddhhffss', Command_Value: 'S016BIT' },
{ id: 70, label: 'li_fe_battery_self_test_by_charged_at_a_time', Command_Type: 'no_input', Command_Value: 'S009BST' },
{ id: 71, label: 'ac_charge_keep_battery_voltage_setting', Command_Type: 'a,bbbb', Command_Value: 'S016ACCB' },
{ id: 72, label: 'battery_temprature_sensor_compensation', Command_Type: 'nnn', Command_Value: 'S007BTS' },
{ id: 73, label: 'max_ac_charging_current_from_ac', Command_Type: 'nnnn', Command_Value: 'S011MUCHGC' },
{ id: 74, abel: 'feeding_grid_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S012FPADJ' },
{ id: 75, label: 'battery_discharge_max_current_in_hybrid_mode', Command_Type: 'nnnn', Command_Value: 'S009BDCM' },
{ id: 76, label: 'set_feedin_power_factor', Command_Type: 'nnn', Command_Value: 'S008FPPF' },
{ id: 77, label: 'enable_disable_parellel_for_output', Command_Type: 'n', Command_Value: 'S006PALE' },
{ id: 78, label: 'r_phass_feeding_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S013FPRADJ' },
{ id: 79, label: 's_phass_feeding_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S013FPSADJ' },
{ id: 80, label: 't_phass_feeding_power_calibration', Command_Type: 'm,nnnn', Command_Value: 'S013FPTADJ' },
{ id: 81, label: 'auto_adjust_pf_with_power', Command_Type: 'a,bbb,ccc', Command_Value: 'S014AAPF' },
{ id: 82, label: 'set_feed_in_reactive_power', Command_Type: 'nnnn', Command_Value: 'S010FPRA' },
];

const columns = [
    { field: 'id', headerName: 'ID', width: 20 },
    { field: 'label', headerName: 'Command', width: 430 },
    { field: 'Command_Type', headerName: 'Command Type', width: 230 },
    { field: 'Command_Value', headerName: 'Commmand to send', width: 230 },
];







export default function SystemControl() {

    const [accesstoken, setaccesstoken] = React.useState("");
    const [output, setOutput] = useState("");
    const [commandtosend, setCommandtosend] = useState("");
    const [plugno, setPlugno] = useState('');
    const [operation, setOperation] = useState('');
    const [remoteout, setRemoteout] = useState("");
    const [inverterdata, setInverterdata] = useState("");
    const [disamt, setDisamt] = useState("");
    const [chrgamt, setChrgamt] = useState("");
    const [chrout, setChrout] = useState("");
    const [setdisout, setDisout] = useState("");
    const [setidleout, setIdle] = useState("");





    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };




    useEffect(() => {
        const configuration = {
            method: "post",
            url: "https://pkc-tower-backend.herokuapp.com/api/auth/login",
            //url: "http://localhost:3000/chartdata",
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "email": "labunehewa@gmail.com",
                "password": "storTera12!"
            }
        };
        axios(configuration)
            .then((result) => {
                setaccesstoken(result.data.access_token)
            })
            .catch((error) => {
                error = new Error();
            });
    }, []);


    const sendremoteloadcommand = () => {
        const configuration = {
            method: "post",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/plugcommand",
            //url: "http://localhost:3000/chartdata",
            headers: {
                "access_token": accesstoken,
                'Content-Type': 'application/json',
            },
            data: {
                "plug": plugno,
                "opera": operation,
            }
        };
        axios(configuration)
            .then((result) => {
                console.log(result)
                setRemoteout(result.data.response)
            })
            .catch((error) => {
                error = new Error();
            });


    }


    const sendcommand = () => {
        const configuration = {
            method: "post",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/sendcommand",
            //url: "http://localhost:3000/chartdata",
            headers: {
                "access_token": accesstoken,
                'Content-Type': 'application/json',
            },
            data: {
                "command": commandtosend
            }
        };
        axios(configuration)
            .then((result) => {
                console.log(result.data.status)
                setOutput(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const getinverterdata = () => {
        const configuration = {
            method: "get",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/inverterdata",
            //url: "http://localhost:3000/chartdata",
            headers: {
                "access_token": accesstoken,
                'Content-Type': 'application/json',
            },
        };
        axios(configuration)
            .then((result) => {
                console.log(result.data)
                setInverterdata(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const sendchargecommand = () => {
        const configuration = {
            method: "post",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/startcharge",
            //url: "http://localhost:3000/chartdata",
            headers: {
                "access_token": accesstoken,
                'Content-Type': 'application/json',
            },
            data: {
                "chargeamount": chrgamt,
            }
        };
        axios(configuration)
            .then((result) => {
                console.log(result.data)
                setChrout(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const senddischargecommand = () => {
        const configuration = {
            method: "post",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/startdischarge",
            //url: "http://localhost:3000/chartdata",
            headers: {
                "access_token": accesstoken,
                'Content-Type': 'application/json',
            },
            data: {
                "dischargeamount": disamt,
            }
        };
        axios(configuration)
            .then((result) => {
                console.log(result.data)
                setDisout(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }

    const sendidle = () => {
        const configuration = {
            method: "get",
            url: "https://pkc-tower-backend.herokuapp.com/api/users/settoidle",
            //url: "http://localhost:3000/chartdata",
            headers: {
                "access_token": accesstoken,
                'Content-Type': 'application/json',
            },
        };
        axios(configuration)
            .then((result) => {
                console.log(result.data)
                setIdle(result.data)
            })
            .catch((error) => {
                error = new Error();
            });
    }




    return (
        <div>
            <Topbar />
            <div className="topmanualcontroltabs">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                            <Tab label="Get Data from Inverter" {...a11yProps(0)} />
                            <Tab label="Get More Inv. Data" {...a11yProps(1)} />
                            <Tab label="Send Inverter Commands" {...a11yProps(2)} />

                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                        <Typography>This Will return Current StorTower Data Directly from the StorTower.</Typography>
                        <Button sx={{ m: 2 }} onClick={() => getinverterdata()}>get inverter data</Button>
                        <div style={{ height: 600, width: '100%' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Data Name</TableCell>
                                            <TableCell >Value</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>AC input active power R 'unit': 'W'</TableCell>
                                            <TableCell >{inverterdata["AC input active power R"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>AC input active power S 'unit': 'W'</TableCell>
                                            <TableCell >{inverterdata["AC input active power S"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>AC input active power T 'unit': 'W'</TableCell>
                                            <TableCell >{inverterdata["AC input active power T"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>AC input total active power 'unit': 'W'</TableCell>
                                            <TableCell >{inverterdata["AC_input_total_active_power_kW"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>AC output total active power 'unit': 'W'</TableCell>
                                            <TableCell >{inverterdata["AC_output_active_power_R_kW"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Battery Voltage 'unit': '0.1V'</TableCell>
                                            <TableCell >{inverterdata["Battery_Voltage_V"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Battery Capacity 'unit': '%'</TableCell>
                                            <TableCell >{inverterdata["Battery_Capacity_Percent"]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Battery Current 'unit': '0.1A'</TableCell>
                                            <TableCell >{inverterdata["Battery_Current_A"]}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className="explainersys">
                            <Typography>To send Commands select a command from the table given and enter it into the textfield given below.
                                If the command has no input send the command by itself. If there is an input given under the 'command type' enter the
                                corresponding data required without any spaces in between to send the command (eg: for the command 'Generated energy of year'
                                with command type yyyynnn yyyy standing for year and nnn standing for the sum of the character string send it as 'P010EY2020010').
                                If the command was successful you will get a return with '\r'.
                                Else you will get a returh with 'ack'.
                            </Typography>
                        </div>
                        <div className="syscontrolmaintop">
                            <Typography>Find Command from the Table to send command</Typography>
                            <TextField sx={{ m: 2 }} id="filled-basic" label="Set Command" variant="filled" value={commandtosend}
                                onChange={(commandtosend) => setCommandtosend(commandtosend.target.value)}
                            />
                            <Button onClick={() => sendcommand()}>Send Command</Button>
                        </div>
                        <div className="outputareafordata">
                            <span>{JSON.stringify(output)}</span>
                        </div>
                        <div className="tablesyscontrol">
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={20}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Command</TableCell>
                                        <TableCell >Data to send</TableCell>
                                        <TableCell >Button</TableCell>
                                        <TableCell >Output</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Set Discharge Amount here between 0000-9999 in 0.1A </TableCell>
                                        <TableCell ><TextField sx={{ m: 2 }} id="filled-basic" label="Set Discharge Amount" variant="filled" value={disamt}
                                            onChange={(disamt) => setDisamt(disamt.target.value)}
                                        /></TableCell>
                                        <TableCell><Button sx={{ m: 2 }} onClick={() => senddischargecommand()}>send Discharge command</Button></TableCell>
                                        <TableCell >{JSON.stringify(setdisout)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Set Charge Amount here between 0000-9999 in 0.1A</TableCell>
                                        <TableCell><TextField sx={{ m: 2 }} id="filled-basic" label="set Charge Amout" variant="filled" value={chrgamt}
                                            onChange={(chrgamt) => setChrgamt(chrgamt.target.value)}
                                        /></TableCell>
                                        <TableCell><Button sx={{ m: 2 }} onClick={() => sendchargecommand()}>send Charge Command</Button></TableCell>
                                        <TableCell >{JSON.stringify(chrout)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>send Idle Command to stop Charge and Discharge</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><Button sx={{ m: 2 }} onClick={() => sendidle()}>send Idle Command</Button></TableCell>
                                        <TableCell >{JSON.stringify(setidleout)}</TableCell>
                                    </TableRow>

                                </TableHead>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </Box>
            </div>

        </div>
    )
}