import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import ResidentailTopbar from "../pagecomponents/ResidentialTopbar";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

const cookies = new Cookies();


const token = cookies.get("TOKEN");

const columns = [
    { id: 'name', label: 'Date', minWidth: 170 },
    { id: 'code', label: 'Code', minWidth: 100 },
    {
        id: 'population',
        label: 'heading1',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'heading2',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'heading3',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('dataplace1', '01', 1324171354, 3287263),
    createData('dataplace2', '01', 1403500365, 9596961),
    createData('dataplace3', '01', 60483973, 301340),
    createData('dataplace4', '01', 327167434, 9833520),
    createData('dataplace5', '01', 37602103, 9984670),
    createData('dataplace6', '01', 25475400, 7692024),
    createData('dataplace7', '01', 83019200, 357578),
    createData('dataplace8', '01', 4857000, 70273),
    createData('dataplace9', '01', 126577691, 1972550),
    createData('dataplace10', '01', 126317000, 377973),
    createData('dataplace11', '01', 67022000, 640679),
    createData('dataplace12', '01', 67545757, 242495),
    createData('dataplace13', '01', 146793744, 17098246),
    createData('dataplace14', '01', 200962417, 923768),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
    createData('dataplace15', '01', 210147125, 8515767),
];


export default function ResidentialData() {
    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            <ResidentailTopbar />
            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 2, }}>
                <Box >
                    <Card sx={{ minWidth: 300 }}>
                        <DateRangePicker
                            color='#f7fef7'
                            onChange={item => setState([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={state}
                            minDate={new Date("April 1, 2022 00:00:00")}
                            maxDate={addDays(new Date(), 1)}
                            direction="horizontal"
                        />
                    </Card>
                </Box>
                <Box >
                    <Card sx={{ minWidth: 300 }}>

                    </Card>
                </Box>
            </Box>
            <Paper sx={{ width: '80%', overflow: 'hidden', marginLeft: 15 }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}