import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ResidentailTopbar from "../pagecomponents/ResidentialTopbar";
import Solar from "../animation/animationImages/new_icons/Solar.svg";
import Grid from "../animation/animationImages/new_icons/Grid.svg";
import Loads from "../animation/animationImages/new_icons/Loads.svg";
import Battery from "../animation/animationImages/new_icons/Battery_System.svg";
import triacon from "../animation/animationImages/new_icons/TRIACON_1.svg";
import Xarrow, { useXarrow, Xwrapper } from "../../../node_modules/react-xarrows"
import "./residentail.css";

const cookies = new Cookies();


const token = cookies.get("TOKEN");

function createData(name, calories) {
    return { name, calories };
}

const rows = [
    createData('val1', 159),
    createData('val2', 237),
    createData('val3', 262),
    createData('val4', 305),
    createData('val5', 356),
    createData('val6', 356),
    createData('val7', 356),
    createData('val8', 356),
    createData('val9', 356),
    createData('val10', 356),
];

export default function Residential() {


    return (
        <div>
            <ResidentailTopbar />
            <div className="topresidentailcontainer">
                <div className="secondaryresdientialcontainer">
                    <div className="tablecontainer">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Data heading</TableCell>
                                        <TableCell align="right">value</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.calories}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                    <div className="animationcontainer">
                        <div>last update Time</div>
                        <div className="solarcontainer">
                            <div className="imgcont">
                                <img src={Solar} width="100px" height="100px" />
                                <span>solar</span>
                            </div>
                            <div className="namecont">
                                <span>solar</span>
                            </div>
                        </div>
                        <div className="grdcontainer">
                            <div className="imgcont">
                                <img src={Grid} width="100px" height="100px" />
                                <span>Grid</span>
                            </div>
                            <div className="namecont">
                                <span>grid</span>
                            </div>
                        </div>
                        <div className="loadcontainer">
                            <div className="imgcont">
                                <img src={Loads} width="100px" height="100px" />
                                <span>Loads</span>
                            </div>
                            <div className="namecont">
                                <span>
                                    loads
                                </span>
                            </div>
                        </div>
                        <div className="batterycont">
                            <div className="imgcont">
                                <img src={Battery} width="100px" height="100px" />
                                <span>Battery</span>
                            </div>
                            <div className="namecont">
                                <span>battery</span>
                            </div>
                        </div>
                        <div className="triacontain" >
                            <div className="imgcont">
                                <img src={triacon} width="100px" height="100px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}