import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Mainappbar from "../pagecomponents/Mainappbar";
import './adminerrors.css'

const cookies = new Cookies();


const token = cookies.get("TOKEN");

export default function Adminerrors() {

    return (
        <div>
            <Mainappbar />


        </div>
    )
}