import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Bec_topappbar from "./bec_topappbar";
import Xarrow, { Xwrapper } from "react-xarrows";
import Grid from "../animation/animationImages/new_icons/Grid.svg";
import Stortower from "../animation/animationImages/new_icons/Battery_System.svg";
import Solar from "../animation/animationImages/new_icons/Solar.svg"
import { addDays } from 'date-fns';
import Building from "../animation/animationImages/new_icons/building_pfc.svg";
import { DateRangePicker } from 'react-date-range';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSVLink } from "react-csv";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import flowbattery from "../animation/animationImages/flowbattery.png"
import BoltIcon from '@mui/icons-material/Bolt';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BusinessIcon from '@mui/icons-material/Business';
import CircleIcon from '@mui/icons-material/Circle';
import Carousel from 'react-material-ui-carousel'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    AreaChart,
    Area,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';
import './bec_home.css'


const cookies = new Cookies();


const token = cookies.get("TOKEN");

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Bec_home() {
    const [data2, setData] = useState([]);
    const [showClaender, setShowClaender] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [datedisplay, setDisplay] = useState("Select Days from the Calender for display");
    const [enddisplay, setEnddisplay] = useState("Select Days from the Calender for display");
    const [selectedValue, setSelectedValue] = React.useState('Localtime');
    const [batteryvoltage, setBatteryvoltage] = useState('');
    const [batterycapacity, setBatterycapacity] = useState('');
    const [batteryupdate, setBatteryupdate] = useState('');
    const [batterycurrent, setBatterycurrent] = useState('');
    const [batteryfrequency, setBatteryfrequency] = useState('');
    const [batterytemp, setBatterytemp] = useState('');
    const [batterymode, setBatterymode] = useState('');
    const [batteryinputv, setBatteryinputv] = useState('');
    const [phase1, setPhase1] = useState('');
    const [phase2, setPhase2] = useState('');
    const [phase3, setPhase3] = useState('');
    const [phasetotal, setPhasetotal] = useState('');
    const [batterystat, setBatterystat] = useState('');
    const [efrtime, setEfrtime] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [batterytoout, setBatterytoout] = useState({ start: 'stortower', end: 'building', startAnchor: 'left', endAnchor: 'right', animation: 0, showHead: false, color: '#E0E0E0' });
    const [batterytoout2, setBatterytoout2] = useState({ start: 'gridpower', end: 'building', startAnchor: 'right', endAnchor: 'left', animation: 0, showHead: false, color: '#E0E0E0' });
    const [batterylstch, setBatterylsth] = useState('');
    const [batterylstch2, setBatterylsth2] = useState('');
    const [solardt, setSolardt] = useState('');
    const [strotowerstatus, setStortowerstatus] = useState('');

    //inputvoltage
    const [SettingsInputS, setSettingsInputS] = useState('');
    const [SettingsInputR, setSettingsInputR] = useState('');
    const [SettingsInputT, setSettingsInputT] = useState('');
    const [SettingsInputcurrnetT, setSettingsInputcurrentT] = useState('');
    const [SettingsInputcurrnetR, setSettingsInputcurrentR] = useState('');
    const [SettingsInputcurrnetS, setSettingsInputcurrentS] = useState('');
    const [SettingsInputactivepR, setSettingsInputactivepR] = useState('');
    const [SettingsInputactivepS, setSettingsInputactivepS] = useState('');
    const [SettingsInputactivepT, setSettingsInputactivepT] = useState('');
    const [SettingsInputactiveptotal, setSettingsInputactiveptotal] = useState('');
    const [email, setEmail] = useState('labunehewa@gmail.com')
    const [alert, setAlert] = useState(false)
    const [alerttext, setAlerttext] = useState('')

    const [value, setValue] = React.useState(0);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleCalender = () => {
        setShowClaender(!showClaender);
    }

    const handleChange1 = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (event) => {
        setSelectedValue(event.target.value);
    };

    const savedate = () => {
        setIsSending(!isSending)
    }

    const refreshDate = new Date();
    refreshDate.setHours(0, 0, 0, 0);

    const [state, setState] = useState([
        {
            startDate: refreshDate,
            endDate: addDays(refreshDate, 1),
            key: 'selection'
        }
    ]);


    const sendRequest = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + "repowering_get_report",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"],
                "email": email,

            }
        };
        axios(configuration).then((result) => {
            console.log(result)
            setAlert(true)

        }

        )
    }

    useEffect(() => {
        bec_efr_data()
        efrlatest()
        battery_status()
        battery_last_act()
    }, [isSending]);

    const bec_efr_data = () => {
        const configuration = {
            method: "post",
            url: "https://backend-pfc.herokuapp.com/" + "bec_comb_data",
            //url: "http://localhost:3000/chartdata",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "startdate": state[0]["startDate"],
                "enddate": state[0]["endDate"]
            }
        };

        // make the API call
        axios(configuration)
            .then((result) => {
                const output_data = result.data
                console.log(output_data)
                //setOutput(output_data)
                //const time_data = Date(result.data["date/time"].toLocaleTimeString('it-IT'))
                for (let i = 0; i < output_data.length; i++) {
                    output_data[i]['power_phase_1'] = output_data[i]['power_phase_1'] / 1000
                    output_data[i]['power_phase_2'] = output_data[i]['power_phase_2'] / 1000
                    output_data[i]['power_phase_3'] = output_data[i]['power_phase_3'] / 1000
                    output_data[i]['Total_power'] = output_data[i]['Total_power'] / 1000
                    output_data[i]['Total AC power (kW)'] = ((output_data[i]['Battery Current(A)'] * output_data[i]['Battery Voltage(V)']) / 1000).toFixed(2)
                }
                console.log("apicalled")
                console.log(output_data)
                setData(output_data)
                console.log('data', typeof (obj));

            })
            .catch((error) => {
                error = new Error();
            });
    }


    const efrlatest = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/efr-new-data",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                const stat = response.data[0].status;
                console.log(response.data);
                const out = response.data;
                console.log('new data', out)
                setPhase1((out[0]['power_phase_1'] / 1000).toFixed(2))
                setPhase2((out[0]['power_phase_2'] / 1000).toFixed(2))
                setPhase3((out[0]['power_phase_3'] / 1000).toFixed(2))
                setPhasetotal((out[0]['Total_power'] / 1000).toFixed(2))
                setEfrtime(out[0]['Timestamp'])
                setSettingsInputS(out[0]['AC Input Voltage S(V)'])
                setSettingsInputR(out[0]['AC input voltage R(V)'])
                setSettingsInputT(out[0]['AC Input Voltage T(V)'])
                setSettingsInputcurrentT(out[0]['AC Input Current T(A)'])
                setSettingsInputcurrentS(out[0]['AC Input Current S(A)'])
                setSettingsInputcurrentR(out[0]['AC input current R(A)'])
                setSettingsInputactivepR(out[0]['AC input active power R(kW)'])
                setSettingsInputactivepS(out[0]['AC input active power S(kW)'])
                setSettingsInputactivepT(out[0]['AC input active powerT(kW)'])
                setSettingsInputactiveptotal(out[0]['AC input total active power(kW)'])
                setBatteryvoltage(out[0]['Battery Voltage(V)'])
                setBatterycapacity(out[0]['Battery Capacity(%)'])
                setBatterycurrent(out[0]['Battery Current(A)'])
                setBatteryfrequency(out[0]['AC output frequency(Hz)'])
                setBatterytemp(out[0]['Temperature Interne(°C)'])
                setBatterymode(out[0]['Operating Mode'])
                setBatteryinputv(out[0]['Total Energy(kWh)'])

                setBatteryupdate(new Date(out[0]['Timestamp']).toLocaleDateString('en-GB') + " " + new Date(out[0]['Timestamp']).toLocaleTimeString('en-GB'))
                if (out[0]['Battery Current(A)'] > 9) {
                    setBatterytoout({ start: 'building', end: 'stortower', startAnchor: 'right', endAnchor: 'left', animation: 1, showHead: true, color: '#0CA904' })
                    setStortowerstatus('Charging')
                }
                else if (out[0]['Battery Current(A)'] < 0) {
                    setBatterytoout({ start: 'stortower', end: 'building', startAnchor: 'left', endAnchor: 'right', animation: 1, showHead: true, color: '#0CA904' })
                    setStortowerstatus('Discharge')
                }
                else {
                    setBatterytoout({ start: 'stortower', end: 'building', startAnchor: 'left', endAnchor: 'right', animation: 0, showHead: false, color: '#E0E0E0' })
                    setStortowerstatus('Idle')
                }
                if (out[0]['Total_power'] > 0) {
                    setBatterytoout2({ start: 'building', end: 'gridpower', startAnchor: 'left', endAnchor: 'right', animation: 1, showHead: true, color: '#0CA904' })
                    setSolardt("Site Power Export: " + String(Math.abs((out[0]['Total_power'] / 1000)).toFixed(2)))
                }
                else if (out[0]['Total_power'] < 0) {
                    setSolardt("Site Power Import: " + String(Math.abs((out[0]['Total_power'] / 1000)).toFixed(2)))
                    setBatterytoout2({ start: 'gridpower', end: 'building', startAnchor: 'right', endAnchor: 'left', animation: 1, showHead: true, color: '#0CA904' })
                }
                else {
                    setBatterytoout2({ start: 'gridpower', end: 'building', startAnchor: 'right', endAnchor: 'left', animation: 0, showHead: true, color: '#E0E0E0' })
                    setSolardt("Site Power Idle: " + String(Math.abs((out[0]['Total_power'] / 1000)).toFixed(2)))
                }
            })
            .catch((error) => {
                error = new Error();
            });
    }

    function truncateString(inputString, maxLength) {
        if (inputString.length > maxLength) {
            return inputString.slice(0, maxLength);
        }
        return inputString;
    }

    const battery_last_act = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/battery-stats",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                console.log(response.data);
                const out = response.data;
                console.log('here', out);

                for (let i = 0; i < out.length; i++) {
                    out[i]['amount'] = (out[i]['amount']).toFixed(2)
                    out[i]['Time_interval'] = truncateString(out[i]['Time_interval'], 17);
                }

                setBatterylsth(out[0]);
                setBatterylsth2(out[1]);

            })
            .catch((error) => {
                error = new Error();
            });
    }

    const battery_status = () => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/battery_ch_state",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                console.log(response.data);
                const out = response.data;
                setBatterystat(out[0]['BEC status'])


            })
            .catch((error) => {
                error = new Error();
            });
    }
    const csvData = data2


    return (
        <div>
            <Bec_topappbar />
            <Xwrapper>
                <div className="animetop">
                    <div className="animationcontainer22">
                        <div className="imageanima" id={'gridpower'}>
                            <img className="animationbuilding" src={Grid} width="50px" height="70px" />
                            <span><b>Grid</b></span>
                        </div>
                        <div className="imageanima" id={'building'}>
                            <img className="animationbuilding" src={Building} width="50px" height="70px" />
                            <span><b>{solardt}kW</b></span>
                        </div>
                        <div className="imageanima" id={'stortower'}>
                            <img className="animationbuilding" src={Stortower} width="50px" height="70px" />
                            <span><b>StorTower</b></span>
                            <span><b>Status :{strotowerstatus}</b></span>
                            <span><b>{((batterycurrent * batteryvoltage) / 1000).toFixed(2)}kW</b></span>
                        </div>
                    </div>
                </div>
                <Xarrow start={batterytoout2.start} end={batterytoout2.end} path={"grid"} startAnchor={batterytoout2.startAnchor} endAnchor={batterytoout2.endAnchor} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout2.animation }} showHead={batterytoout2.showHead} color={batterytoout2.color} />
                <Xarrow start={batterytoout.start} end={batterytoout.end} path={"grid"} startAnchor={batterytoout.startAnchor} endAnchor={batterytoout.endAnchor} dashness={{ strokeLen: 10, nonStrokeLen: 10, animation: batterytoout.animation }} showHead={batterytoout.showHead} color={batterytoout.color} />
            </ Xwrapper>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="Tabs data" centered>
                        <Tab label="Stortower Data" {...a11yProps(0)} />
                        <Tab label="Performance Data" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Carousel
                        navButtonsAlwaysVisible={true}
                        animation={"slide"}
                        autoPlay={false}
                        NextIcon={<ChevronRightIcon />}
                        PrevIcon={<ChevronLeftIcon />}
                    // OR
                    >
                        <div className="timeholder">
                            <h5 className="heading"><span><b>Last Update Time: {batteryupdate}</b></span></h5>
                            <div className="beccards">
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Stortower Data : AC
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="Voltage L1" />
                                                <Typography>
                                                    {":  "} <b>{SettingsInputR}V</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-AC-ACTIVE" primary="Voltage L2" />
                                                <Typography>
                                                    {":  "}<b>{SettingsInputS}V</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Voltage L3" />
                                                <Typography>
                                                    {":  "} <b>{SettingsInputT}V</b>
                                                </Typography>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Frequency" />
                                                <Typography>
                                                    {":  "}<b>{batteryfrequency}Hz</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>
                                    </CardActions>
                                </Card>
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            StorTower Data :DC
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="DC Voltage:" />
                                                <Typography>
                                                    <b>{batteryvoltage}V</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-AC-ACTIVE" primary="DC Current:" />
                                                <Typography>
                                                    <b>{batterycurrent}A</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Battery SOC:" />
                                                <Typography>
                                                    <b>{batterycapacity}%</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Battery Status:" />
                                                <Typography>
                                                    <b>{strotowerstatus}</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Building Consumption/Export
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BusinessIcon />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="Power L1: " />
                                                <Typography>
                                                    <b>{phase1}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BusinessIcon />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Power L2: " />
                                                <Typography>
                                                    <b>{phase2}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BusinessIcon />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="Power L3: " />
                                                <Typography>
                                                    <b>{phase3}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BusinessIcon />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Power Total: " />
                                                <Typography>
                                                    <b>{phasetotal}kW</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>
                            </div>
                        </div>

                        <div className="timeholder">
                            <h5 className="heading"><span><b>Last Update Time: {batteryupdate}</b></span></h5>
                            <div className="beccards">
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Stortower Data : Power
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="AC Power L1:" />
                                                <Typography>
                                                    <b>{SettingsInputactivepR}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-battery-percent" primary="AC Power L2:" />
                                                <Typography>
                                                    <b>{SettingsInputactivepS}kW</b>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="KMCC-AC-ACTIVE" primary="AC Power L3:" />
                                                <Typography>
                                                    <b>{SettingsInputactivepT}kW</b>
                                                </Typography>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="AC power Total:" />
                                                <Typography>
                                                    <b>{SettingsInputactiveptotal}kW</b>
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>
                                <Card sx={{ minWidth: 300 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Stortower Data: General
                                        </Typography>
                                        <List
                                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <DeviceThermostatIcon />
                                                </ListItemIcon>
                                                <ListItemText id="Townhall-battery-percent" primary="Stortower Temp:" />
                                                <Typography>
                                                    <b>{batterytemp}°C</b>
                                                </Typography>
                                            </ListItem>

                                        </List>
                                    </CardContent>
                                    <CardActions>

                                    </CardActions>
                                </Card>
                            </div>
                        </div>

                    </Carousel>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className="timeholder">
                        <h5 className="heading"><span><b>Charge and Discharge Data</b></span></h5>
                        <div className="beccards">

                            <Card sx={{ minWidth: 600 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Amount to Discharge {batterylstch.Date}
                                    </Typography>
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    >
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon />
                                            </ListItemIcon>
                                            <ListItemText id="KMCC-battery-percent" primary="Time Interval" />
                                            <Typography>
                                                {batterylstch.Time_interval}
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BoltIcon />
                                            </ListItemIcon>
                                            <ListItemText id="KMCC-AC-ACTIVE" primary="Amount to Discharge:" />
                                            <Typography>
                                                {batterylstch.amount}kWh
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 600 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        Amount Charged {batterylstch.Date}
                                    </Typography>
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    >
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircleIcon />
                                            </ListItemIcon>
                                            <ListItemText id="KMCC-battery-percent" primary="Time Interval" />
                                            <Typography>
                                                {batterylstch2.Time_interval}
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BoltIcon />
                                            </ListItemIcon>
                                            <ListItemText id="KMCC-AC-ACTIVE" primary="Amount Charged:" />
                                            <Typography>
                                                {batterylstch2.amount}kWh
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>
                        </div>
                    </div>
                </CustomTabPanel>

            </Box>
            <Collapse in={alert}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    Report should arrive at {email} shortly
                </Alert>
            </Collapse>
            <div className="accordianholder">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            General settings
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{"Selected Dates: " + datedisplay + " to " + enddisplay}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="accinside1">
                            <div className="insidedatapick2">
                                <DateRangePicker
                                    className='rangedDatepick'
                                    color='#f7fef7'
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    minDate={new Date("July 13, 2022 00:00:00")}
                                    maxDate={addDays(new Date(), 1)}
                                    direction="horizontal"
                                    showDateDisplay="false"
                                />
                            </div>
                            <div className="selectordata">

                                <Typography>
                                    Make Changes and click Update Selection
                                </Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Email</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={email}
                                        label="Email"
                                        onChange={handleEmail}
                                    >
                                        <MenuItem value={'mihika@stortera.com'}>mihika@stortera.com</MenuItem>
                                        <MenuItem value={'labunehewa@gmail.com'}>labunehewa@gmail.com</MenuItem>
                                        <MenuItem value={'monitoring@stortera.com'}>monitoring@stortera.com</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button onClick={sendRequest}>Get report for selected days</Button>
                                <Button>
                                    <CSVLink filename={"Repowering_data.csv"} data={csvData}>Download data for selection</CSVLink>
                                </Button>
                                <div>
                                    <Button onClick={savedate}>Update selection</Button>
                                </div>
                            </div>
                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="efrdatachart">
                <h5 className="topheading">DATA</h5>
                <ResponsiveContainer width="100%" aspect={4 / 2} >
                    <LineChart width={500} height={300} data={data2}>
                        <XAxis dataKey="Timestamp" tick={{ fontSize: 15 }} label={{ value: 'Format in YYYY/MM/DD HH:MM:SS', position: 'insideBottomLeft', dy: 10, dx: -1, fontFamily: 'Roboto, sans-serif' }} />
                        <Tooltip />
                        <YAxis domain={[-20, 30]} label={{ value: 'Building & StorTower Total Power(kW)', angle: -90, position: 'insideLeft', dy: 30, dx: 10, fontFamily: 'Roboto, sans-serif' }} />

                        <CartesianGrid strokeDasharray="5 5" />
                        {/*<Line name="Power Phase 1 (kW)" type="monotone" dataKey="power_phase_1" stroke="#8884d8" dot={false} />*/}
                        {/*<Line name="Power Phase 2 (kW)" type="monotone" dataKey="power_phase_2" stroke="#222AD5" dot={false} />*/}
                        {/*<Line name="Power Phase 3 (kW)" type="monotone" dataKey="power_phase_3" stroke="#22D525" dot={false} />*/}
                        <Line name="(Building) Total Power (kW)" type="monotone" dataKey="Total_power" stroke="#DA1F1F" dot={false} />
                        <Line name="(StorTower) Total AC power (kW)" type="monotone" dataKey="Total AC power (kW)" stroke="#E508F3" dot={false} />
                        {/*<Line name="Battery Voltage(V)" type="monotone" dataKey="Battery Voltage(V)" stroke="#202D21" dot={false} />*/}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}