import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NotificationsNone, Settings, Logout } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Storteralogo from '../../assets/icons/storteralogo.svg';
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DataArrayIcon from '@mui/icons-material/DataArray';
import TuneIcon from '@mui/icons-material/Tune';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import Fade from '@mui/material/Fade';
import flowbattery from "../animation/animationImages/flowbattery.png"
import Cookies from "universal-cookie";
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import InsightsIcon from '@mui/icons-material/Insights';
import "./topappbar.css";

const drawerWidth = 240;

const cookies = new Cookies();

const token = cookies.get("TOKEN");

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const homepage = () => {
    window.location.href = "/storhubs"

}

const datapage = () => {
    window.location.href = "/storhubdata"

}

const controlpage = () => {
    window.location.href = "/storhubcontrol"

}

const analyticspage = () => {
    window.location.href = "/Admincontrol"

}

const errorspage = () => {
    window.location.href = "/Adminerrors"

}

const mainpage = () => {
    window.location.href = "/admin-home"

}

export default function Mainappbar() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [device, setdevice] = React.useState("KMCC");
    const [status, setStatus] = React.useState("");
    const openselector = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handledevice = (name) => {
        switch (name) {
            case 1:
                setdevice("KMCC")
                break;
            case 2:
                setdevice("Townhall")
                break;
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logout = () => {
        // destroy the cookie
        cookies.remove("TOKEN", { path: "/PFC" });
        // redirect user to the landing page
        window.location.href = "/PFC";
    }

    React.useEffect(() => {
        const configuration = {
            method: "get",
            url: "https://backend-pfc.herokuapp.com/storhubstatus",
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios(configuration)
            .then((response) => {
                setStatus(response.data[0].status);
                console.log(response.data);
            })
            .catch((error) => {
                error = new Error();
            });
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{
                    backgroundColor: 'white',
                    color: 'Black',
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        <img src={Storteralogo} alt="storteralogo" className="logon" width="100px" height="50px" onClick={() => mainpage()} />
                    </Typography>

                    <img className="animationbuilding" src={flowbattery} width="30px" height="30px" />

                    <IconButton aria-label="Notifications" onClick={() => errorspage()}>
                        <NotificationsNone type="submit" width="20px" height="20px">
                        </NotificationsNone>
                    </IconButton>
                    <IconButton aria-label="Settings" onClick={() => controlpage()}>
                        < Settings type="submit" width="20px" height="20px" >
                        </Settings>
                    </IconButton>
                    <IconButton aria-label="User" onClick={() => logout()} >
                        < Logout type="submit" width="20px" height="20px" >
                        </Logout>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem onClick={() => mainpage()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Home
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => analyticspage()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <InsightsIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Project Controls
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem onClick={() => errorspage()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <ErrorIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Errors
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box>
    );
}