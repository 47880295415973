import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import ResidentailTopbar from "../pagecomponents/ResidentialTopbar";
import Copyright from "../pagecomponents/copyright";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateRangePicker } from 'react-date-range';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import "./residentialpredicitons.css"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    AreaChart,
    Area,
    //linearGradient,
} from 'recharts';

const cookies = new Cookies();


const token = cookies.get("TOKEN");

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export default function Residentialpredictions() {

    return (
        <div>
            <ResidentailTopbar />
            <Box component="div" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Charge Time
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {'preddate'}
                            </Typography>
                            <Typography variant="body2">
                                {'amounts' + ' kW'}
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Predicted
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {'preddate'}
                            </Typography>
                            <Typography variant="body2">
                                0 kW
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                    <Card sx={{ minWidth: 300 }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Actual
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {'preddate'}
                            </Typography>
                            <Typography variant="body2">
                                0 kW
                            </Typography>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>

                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignContent: 'center', marginBottom: 1, marginTop: 2, width: '100%' }}>
                </Box>
                <div className="chartholder">
                    <h3>Predicted</h3>
                    <ResponsiveContainer width="100%" aspect={4 / 1}>
                        <AreaChart data={data}
                            margin={{ top: 20, right: 60, left: 10, bottom: 10 }}>
                            <XAxis dataKey="name" interval={1} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottom', dy: 10, dx: -1, fontFamily: 'Roboto' }} />
                            <YAxis label={{ value: 'Generation in kWh', angle: -90, dy: 50, dx: -10, position: 'insideLeft', fontFamily: 'Roboto' }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="predictions_kWh" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className="chartholder">
                    <h3>Actual</h3>
                    <ResponsiveContainer width="100%" aspect={4 / 1}>
                        <AreaChart data={data}
                            margin={{ top: 20, right: 60, left: 10, bottom: 10 }}>
                            <XAxis dataKey="name" interval={1} label={{ value: 'Format in MM/DD/YY HH:MM:SS', position: 'insideBottom', dy: 10, dx: -1, fontFamily: 'Roboto' }} />
                            <YAxis label={{ value: 'Generation in kWh', angle: -90, dy: 50, dx: -10, position: 'insideLeft', fontFamily: 'Roboto' }} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="predictions_kWh" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Box>

            <Copyright sx={{ mt: 1, mb: 1 }} />
        </div>
    )
}